import { ReplaySubject } from "rxjs";
import { getFromStore, setToStore } from "./utils";

const AssessmentTypeStore$ = new ReplaySubject();
AssessmentTypeStore$.next(getFromStore("AssessmentType"));

export const assessmentTypePublisher = (data) => {
    setToStore("AssessmentType", data);
    AssessmentTypeStore$.next(data);
};

export default AssessmentTypeStore$;