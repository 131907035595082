export const getAddressbBarDiff = () => {
  if (window.innerWidth > window.innerHeight) {
    return window.outerWidth - window.innerWidth;
  } else {
    return window.outerHeight - window.innerHeight;
  }
};

export const getScaleFactor = () => {
  const addressBarHeight = getAddressbBarDiff();
  if (window.innerWidth > window.innerHeight) {
    return window.innerHeight - addressBarHeight;
  } else {
    return window.innerWidth - addressBarHeight;
  }
};

/**
 * return VIN no from text
 * @param string
 * @returns string or null
 */
export const getVinNo = (text) => {
  if (text) {
    const validateRegex = /^[A-Z0-9]{17,22}$/g;
    const vinText = text
      .split("\n")
      .join(" ")
      .split(" ")
      .filter((e) => validateRegex.test(e));
    console.log("VINNO", vinText);
    if (vinText && vinText.length) {
      return vinText[0];
    } else return null;
  } else return null;
};

export const isSACompany = (otp) => {
  const sACompanyList = [
    9994, 2575, 8681, 3997, 9187, 3835, 3979, 5122, 4617, 2233, 9999, 9193,
    9095, 9294,
  ];
  return sACompanyList.some((e) => e === +otp);
};

/** extract registratin number from OCR data
 * @param {string[]} captureText
 * @returns {string}
 */
export const extractRegistrationNumber = (captureText) => {
  /**
   * The below RegExp will match the following alphanumeric registration number patterns:
   * AAAAAAA AA
   * AAA AAA AA
   * AAAAAA AA
   * AAAAAA A
   */
  // const validationRegex = /^[a-z0-9\s]{7,13}$/gi;
  const validateRegex = /^[a-z0-9]{8,10}$/gi;
  const extractedData = captureText.filter((substr) =>
    validateRegex.test(substr)
  )[0];
  console.log(
    "REGNO",
    captureText.filter((substr) => validateRegex.test(substr))
  );
  return extractedData
    ? extractedData.split(" ").join("")
    : captureText.join("");
};

/**
 * validate registration number
 * @param {string} regNo
 * @returns {boolean}
 */
export const validateRegistrationNumber = (regNo) => {
  // const validationRegex = /^[a-z0-9]{8}$/gi;
  const validationRegex = /^[A-Z0-9]{7,16}$/gi;
  return validationRegex.test(regNo.trim());
};

export const TWO_WHEELER_JOURNEY_OTPs = ["9999"];

export const retakeJourneyObjectCreator = (values) => {
  return {
    type: values.type,
    sub: values.sub,
    step: values.step,
    overlayImage: values.overlayImage,
    overlayImageSize: values.overlayImageSize,
    overlayImagePosition: values.overlayImagePosition,
    overlayImagePositionLeft: values.overlayImagePositionLeft,
    side: values.side,
    label: values.label,
    area: values.area,
    subArea: values.subArea,
    skipBBOX: values.skipBBOX,
    skipCount: values.skipCount,
    imageId: values.imageId,
  };
};

var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

function preventDefault(e) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
  window.addEventListener(
    "test",
    null,
    Object.defineProperty({}, "passive", {
      get: function () {
        supportsPassive = true;
      },
    })
  );
} catch (e) {}

var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent =
  "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

// call this to Disable
export function disableScroll() {
  window.addEventListener("DOMMouseScroll", preventDefault, false); // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  window.addEventListener("touchmove", preventDefault, wheelOpt); // mobile
  window.addEventListener("keydown", preventDefaultForScrollKeys, false);
}

// call this to Enable
export function enableScroll() {
  window.removeEventListener("DOMMouseScroll", preventDefault, false);
  window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
  window.removeEventListener("touchmove", preventDefault, wheelOpt);
  window.removeEventListener("keydown", preventDefaultForScrollKeys, false);
}

export const preRenderImageArray = () => [
  "/images/ftue/infoOne.svg",
  "/images/ftue/infoTwo.svg",
  "/images/ftue/infoThree.svg",
  "/images/ftue/infoFour.svg",
  "/images/upnext/frontnext.svg",
  "/images/upnext/frontdrivernext.svg",
  "/images/upnext/frontdriverhalfnext.svg",
  "/images/upnext/reardriverhalfnext.svg",
  "/images/upnext/reardrivernext.svg",
  "/images/upnext/rearnext.svg",
  "/images/upnext/rearpassengernext.svg",
  "/images/upnext/rearpassengerhalfnext.svg",
  "/images/upnext/frontpassengerhalfnext.svg",
  "/images/upnext/frontpassengernext.svg",
];
