let baseURL =
  process.env.REACT_APP_CARDATA_API ||
  "https://cardata.devInternal.carscan.ai​​";

export const UpdateImageFileMetaDataArea$ = (
  otp,
  assessmentId,
  deviceId,
  imageRequestId,
  gps,
  originalImageResolution,
  currentImageResolution,
  tags,
  uploadDone
) =>
  fetch(
    `${baseURL}/cardata/external/companies/${otp}/assessments/${assessmentId}/files/${imageRequestId}`,
    {
      method: "PUT",
      body: JSON.stringify({
        gps: gps,
        original_image_resolution: originalImageResolution,
        current_image_resolution:currentImageResolution,
        tags: tags,
        upload_done: uploadDone,
      }),
      headers: { "device-id": deviceId, "Content-Type": "application/json" },
    }
  );
