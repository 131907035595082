import { useState, useEffect } from "react";

const useOrientation = () => {
  const initOrientation = window.innerWidth > window.innerHeight ? 90 : 0;
 
  const [orientation, setOrientation] = useState(initOrientation);

  useEffect(() => {
    window.onorientationchange = function (event) {
      try {
        setOrientation(event.target.screen.orientation.angle);
      } catch (e) {

        if(orientation === 90) {
          setOrientation(0)
        } else {
          setOrientation(90)
        }
      }
    };
  }, [orientation]);

  return orientation;
};

export default useOrientation;
