import React from "react";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { Grid, Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useSubject from "../../../../hooks/useSubject";
import LinearProgress from "@material-ui/core/LinearProgress";
import CompanyStore from "../../../../Stores/CompanyStore";
import AssessmentTypeStore$ from "../../../../Stores/AssessmentTypeStore";
import { useState, useEffect } from "react";
import RoundedButton from "../../../RoundedButton";
import { Element } from "../../Offboard/Questionnaire/Element";
import { questionStorePublisher } from "../../../../Stores/QuestionStore";
import QuestionStore$ from "../../../../Stores/QuestionStore";
import { getFromStore } from "../../../../Stores/utils";
import ErrorMessage from "../../../ErrorMessage";
import { Helmet } from "react-helmet";
import Logo from "../../../Logos";
import DeviceOrientationWrapper from "../../../DeviceOrientationWrapper";
import {
  SKIP_WHATSAPP_REPORT_QUESTION,
  SKIP_WHATSAPP,
} from "../../../../mockconfig/configs";
import { countryCode$ } from "../../../../APIConfig/OverlaysApi";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { useStyles } from "./style";

const Whatsappreport = (props) => {
  const { nextAction } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const companyDetails = getFromStore("CompanyStore");
  const [numberRequired, setNumberRequired] = useState(false);
  const [numberRequiredAndValid, setNumberRequiredAndValid] = useState(false);
  const [errors, setErrors] = useState([]);
  const [formElements, setFormElements] = useState(null);
  const assessmentType = useSubject(AssessmentTypeStore$);
  const [ctrackExtra, setCtrackExtra] = useState(false);
  let questions = useSubject(QuestionStore$);
  const isPortrait = companyDetails.is_gcc_journey;
  const contactnumber = localStorage.getItem("ContactNumber");
  const [countryCodes, setCountryCodes] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  if (questions == null) {
    questions = getFromStore("QuestionStore");
  }

  useEffect(() => {
    let time = setTimeout(() => {
      console.log("assessmentType", assessmentType);
      if (assessmentType && assessmentType.questions) {
        let newFormElements = [];
        assessmentType.questions.forEach((question) => {
          if (question.panel === "report-whatsApp") {
            newFormElements.push(question);
          }
        });
        setFormElements(newFormElements);
        if (companyDetails?.otp === "8610") {
          setNumberRequired(true);
          setNumberRequiredAndValid(true);
        }
      } else {
        if (nextAction) {
          nextAction();
        }
      }
    }, 100);

    return () => {
      clearTimeout(time);
    };
  }, [assessmentType, nextAction]);

  useEffect(() => {
    let assessmentType = JSON.parse(sessionStorage.getItem("AssessmentType"));
    const questionsArr = assessmentType.questions;
    const panelname = "report-whatsApp";
    const indexOfQuestion = questionsArr.findIndex(
      (question) => question.panel === panelname
    );
    countryCode$()
      .then((response) => setCountryCodes(response))
      .catch((error) =>
        console.error("Error while fetching country code", error)
      );
    if (indexOfQuestion < 0) {
      if (nextAction) {
        nextAction();
      }
    }
  }, []);

  useEffect(() => {
    if (SKIP_WHATSAPP_REPORT_QUESTION.includes(companyDetails.otp)) {
      let new_question_value = [];
      const obj = [
        {
          answers: ["Yes"],
          question_index: "3",
        },
        {
          answers: [SKIP_WHATSAPP[companyDetails.otp]],
          question_index: "4",
        },
      ];
      if (questions) {
        new_question_value = [...obj, ...questions?.question_value];
      } else {
        new_question_value = [...obj];
      }
      questionStorePublisher({ question_value: new_question_value });
      if (props.nextAction) {
        props.nextAction();
      }
    }
  }, []);
  const handleCtrackToggle = (event) => {
    if (event.target.innerText === "No") {
      const newElements = [...formElements];
      newElements.forEach((element) => {
        if (element.data_type === "WHATSAPP") {
          element["question_value"] = undefined;
        }
      });
      setFormElements(newElements);
    }
    setSelectedCountryCode("");
    setCtrackExtra((prevState) => {
      return !prevState;
    });

    // setEmailRequiredAndValid(true);
  };
  const handleCountryCodeChange = (event) => {
    setSelectedCountryCode(event.target.value);
  };
  const handleToggle = (event) => {
    if (event.target.innerText === "No") {
      const newElements = [...formElements];
      newElements.forEach((element) => {
        if (element.data_type === "WHATSAPP") {
          element["question_value"] = undefined;
        }
      });
      setFormElements(newElements);
    }
    setSelectedCountryCode("");
    setNumberRequired((prevState) => {
      return !prevState;
    });

    if (companyDetails?.otp != "2575") setNumberRequiredAndValid(true);
  };

  const handleChange = (id, type, event) => {
    const newElements = [...formElements];
    newElements.forEach((element) => {
      const { data_type, question_id } = element;
      if (id === question_id) {
        if (data_type === "WHATSAPP" || data_type === "INTEGER") {
          element["question_value"] = event.target.value;
        }

        switch (type) {
          case "whatsapp": {
            const regExp = new RegExp("^(?:(?<Numbers>[0-9]{1})){10,}$");
            const isValid = regExp.test(event.target.value);
            const inputLength = event.target.value.length;
            if (element["question_value"] === "") {
              element["field_error_message"] = "*This is mandatory field";
              setNumberRequiredAndValid(true);
              return;
            } else if (!isValid) {
              element["field_error_message"] = "Enter valid whatsapp number";
              setNumberRequiredAndValid(true);
              return;
            } else if (inputLength > 10) {
              element["field_error_message"] = "Enter valid whatsapp number";
              setNumberRequiredAndValid(true);
              return;
            } else {
              element["field_error_message"] = null;
              setNumberRequiredAndValid(false);
              break;
            }
          }
          default:
            break;
        }
      }
    });
    setFormElements(newElements);
  };

  const handleSubmit = () => {
    // event.preventDefault();
    if (numberRequired && numberRequiredAndValid) {
      setErrors(["Please enter valid number"]);
      return;
    }
    if (numberRequired) {
      if (!selectedCountryCode) {
        setErrors(["Please select a country code"]);
        return;
      }
    }
    let question_array = [];
    formElements.forEach((elem) => {
      let obj = {};
      if (elem.type === "CHECKBOX" && elem.data_type === "STRING") {
        if (numberRequired) {
          obj.answers = ["Yes"];
        } else {
          obj.answers = ["No"];
        }
      } else {
        obj.answers = [`${selectedCountryCode}${elem.question_value}`];
      }

      obj.question_index = elem.question_id;
      if (
        companyDetails.otp === "2278" &&
        elem.panel === "report-whatsApp" &&
        elem.type === "CHECKBOX"
      ) {
        if (ctrackExtra) {
          obj.answers = ["Yes"];
        } else {
          obj.answers = ["No"];
        }
      }
      question_array.push(obj);
    });
    let new_question_value = [];
    if (questions) {
      console.log("test111", questions, question_array);
      new_question_value = [...question_array, ...questions?.question_value];
    } else {
      console.log("test222", questions, question_array);
      new_question_value = [...question_array];
    }
    console.log("new_question_value", new_question_value);
    questionStorePublisher({ question_value: new_question_value });
    if (props.nextAction) {
      props.nextAction();
    }
  };

  return (
    <DeviceOrientationWrapper isLandscape={!isPortrait}>
      <Helmet>
        <title>Whatsapp Report</title>
      </Helmet>
      {!companyDetails && (
        <Box style={{ overflowY: "auto" }}>
          <LinearProgress />
          <Typography>{t("Loading...")}</Typography>
        </Box>
      )}
      {companyDetails && (
        <Box
          style={{
            overflow: "scroll",
            height: "73vh",
            marginTop: isPortrait ? 30 : 0,
          }}
          className="scrollerEmail"
        >
          <Box
            textAlign="center"
            pt={2}
            mb={2}
            className={classes.headingHeight}
          >
            <Logo
              // type={type}
              imageURL={companyDetails.logo_file_path}
              className={classes.headingHeight}
            ></Logo>
          </Box>
          <Box style={{ display: "block", margin: "5px auto" }}>
            {formElements
              ? formElements.map((field, i) =>
                  field.type === "CHECKBOX" && field.data_type === "STRING" ? (
                    <Grid
                      key={field.question_id}
                      item
                      xs={12}
                      className={classes.headerText}
                    >
                      <Typography variant="body1" className="text">
                        {t(field.question)}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid container key={field.question_id}>
                      {field.panel === "report-whatsApp-extra" ? (
                        <Grid item xs={12}>
                          <Box
                            style={{
                              border: "1px solid",
                              width: "80%",
                              borderRadius: "6px",
                              margin: "1px auto",
                              marginTop: "5px",
                              padding: "0px",
                            }}
                          >
                            <ToggleButtonGroup
                              color="secondary"
                              value={ctrackExtra}
                              exclusive={true}
                              style={{ width: "100%" }}
                              onChange={handleCtrackToggle}
                            >
                              <ToggleButton
                                color="secondary"
                                value={true}
                                style={{
                                  height: "35px",
                                  color: "black",
                                  fontSize: "16px",
                                }}
                              >
                                {t("Yes")}
                              </ToggleButton>
                              <ToggleButton
                                value={false}
                                style={{
                                  height: "35px",
                                  color: "black",
                                  fontSize: "16px",
                                }}
                              >
                                {t("No")}
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                          {ctrackExtra && (
                            <Box
                              style={{
                                width: "95%",
                                paddingTop: "10px",
                                paddingLeft: "10px",
                                marginTop: "3vh",
                              }}
                            >
                              <FormControl
                                sx={{ minWidth: "14vw" }}
                                variant="standard"
                              >
                                {/* <InputLabel>Country Code</InputLabel> */}
                                <Select
                                  value={selectedCountryCode}
                                  onChange={handleCountryCodeChange}
                                >
                                  {countryCodes.map((countryCode) => (
                                    <MenuItem
                                      key={countryCode.id}
                                      value={countryCode.id}
                                    >
                                      {countryCode.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText className="inputInfo">
                                  Country Code
                                </FormHelperText>
                              </FormControl>
                              <Element
                                question_id={field.question_id}
                                data_type={field.data_type}
                                question={field.question}
                                place_holder_label={field.place_holder_label}
                                question_value={field.question_value}
                                option={field.option}
                                handleChange={handleChange}
                                error={field.field_error_message}
                              />
                            </Box>
                          )}
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          <Box
                            style={{
                              border: "1px solid",
                              width: "80%",
                              borderRadius: "6px",
                              margin: "1px auto",
                              marginTop: "5px",
                              padding: "0px",
                            }}
                          >
                            <ToggleButtonGroup
                              disabled={companyDetails?.otp === "8610"}
                              color="secondary"
                              value={numberRequired}
                              exclusive={true}
                              style={{ width: "100%" }}
                              onChange={handleToggle}
                            >
                              <ToggleButton
                                disabled={companyDetails?.otp === "8610"}
                                color="secondary"
                                value={true}
                                style={{
                                  height: "35px",
                                  color: "black",
                                  fontSize: "16px",
                                }}
                              >
                                {t("Yes")}
                              </ToggleButton>
                              <ToggleButton
                                disabled={companyDetails?.otp === "8610"}
                                value={false}
                                style={{
                                  height: "35px",
                                  color: "black",
                                  fontSize: "16px",
                                }}
                              >
                                {t("No")}
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                          {numberRequired && (
                            <Box
                              style={{
                                width: "100%",
                                marginTop: "1vh",
                                textAlign: "center",
                              }}
                            >
                              {console.log("contactno", contactnumber)}
                              <FormControl
                                sx={{ minWidth: "14vw" }}
                                variant="standard"
                              >
                                {/* <InputLabel>Country Code</InputLabel> */}
                                <Select
                                  value={selectedCountryCode}
                                  onChange={handleCountryCodeChange}
                                >
                                  {countryCodes.map((countryCode) => (
                                    <MenuItem
                                      key={countryCode.id}
                                      value={countryCode.name}
                                    >
                                      {countryCode.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText className={classes.inputInfo}>
                                  Country Code
                                </FormHelperText>
                              </FormControl>
                              <Element
                                question_id={field.question_id}
                                data_type={field.data_type}
                                question={field.question}
                                place_holder_label={field.place_holder_label}
                                question_value={field.question_value}
                                defaultValue={
                                  companyDetails?.otp == "2575" && contactnumber
                                    ? contactnumber
                                    : ""
                                }
                                option={field.option}
                                handleChange={handleChange}
                                error={field.field_error_message}
                              />
                            </Box>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  )
                )
              : null}
            <Grid
              item
              xs={12}
              style={{ paddingTop: !numberRequired ? "70px" : "8px" }}
            >
              <Box className="middleCenter">
                <RoundedButton
                  label={t("Next")}
                  disabled={numberRequired && numberRequiredAndValid}
                  size="medium"
                  width="30vw"
                  onClick={handleSubmit}
                  fontWeight="800"
                ></RoundedButton>
              </Box>
            </Grid>
            {errors.length !== 0 && (
              <ErrorMessage
                errors={errors}
                onClick={() => setErrors([])}
              ></ErrorMessage>
            )}
          </Box>
        </Box>
      )}
    </DeviceOrientationWrapper>
  );
};

Whatsappreport.defaultProps = {
  nextAction: () => {},
  prevAction: () => {},
};
export default Whatsappreport;
