const bikePreInspection = (journey) => {
  if (journey === "BIKE") {
    return [
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-front",
        overlayImage: "bike/Bikefrontside.svg",
        overlayImageSize: "94%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "0",
        side: "FRONT",
        label: "Front Side",
        area: "FRONT",
        subArea: "FRONT",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-left",
        overlayImage: "bike/Bikeleftside.svg",
        overlayImageSize: "94%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "0",
        side: "LEFT SIDE",
        label: "Left Side",
        area: "LEFT",
        subArea: "LEFT",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-rear",
        overlayImage: "bike/Bikerearside.svg",
        overlayImageSize: "94%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "0",
        side: "REAR",
        label: "Rear Side",
        area: "REAR",
        subArea: "REAR",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-right",
        overlayImage: "bike/Bikerightside.svg",
        overlayImageSize: "94%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "0",
        side: "RIGHT SIDE",
        label: "Right Side",
        area: "RIGHT",
        subArea: "RIGHT",
        skipBBOX: true,
        skipCount: false,
      },
    ];
  } else if (journey === "SCOOTER") {
    return [
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-front",
        overlayImage: "scooter/Scooterfrontside.svg",
        overlayImageSize: "94%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "0",
        side: "FRONT",
        label: "Front Side",
        area: "FRONT",
        subArea: "FRONT",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-left",
        overlayImage: "scooter/Scooterleftside.svg",
        overlayImageSize: "94%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "0",
        side: "LEFT SIDE",
        label: "Left Side",
        area: "LEFT",
        subArea: "LEFT",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-rear",
        overlayImage: "scooter/Scooterrearside.svg",
        overlayImageSize: "94%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "0",
        side: "REAR",
        label: "Rear Side",
        area: "REAR",
        subArea: "REAR",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-right",
        overlayImage: "scooter/Scooterrightside.svg",
        overlayImageSize: "94%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "0",
        side: "RIGHT SIDE",
        label: "Right Side",
        area: "RIGHT",
        subArea: "RIGHT",
        skipBBOX: true,
        skipCount: false,
      },
    ];
  }
};

export default bikePreInspection;
