const exteriorTyreClaimInspection = [
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-tyre-left-wheel",
    overlayImage: "tyres/Tyre_Wheel.svg",
    overlayImageSize: "70%",
    overlayImagePosition: "15%",
    overlayImagePositionLeft: "5%",
    side: "REAR_TYRE_LEFT_WHEEL",
    label: "Rear Tyre Left Wheel",
    area: "REAR",
    subArea: "PASSENGER_SIDE_REAR_WHEEL",
  },
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-tyre-left-rim",
    overlayImage: "tyres/Tyre_rim.svg",
    overlayImageSize: "70%",
    overlayImagePosition: "15%",
    overlayImagePositionLeft: "5%",
    side: "REAR_TYRE_LEFT_RIM",
    label: "Rear Tyre Left Rim",
    area: "TYRE",
    subArea: "PASSENGER_SIDE_REAR_RIM",
  },
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-tyre-left-depth",
    overlayImage: "tyres/Tyre_Depth.svg",
    overlayImageSize: "70%",
    overlayImagePosition: "15%",
    overlayImagePositionLeft: "5%",
    side: "REAR_TYRE_LEFT_DEPTH",
    label: "Rear Tyre Left Tread Depth",
    area: "REAR",
    subArea: "PASSENGER_SIDE_REAR_DEPTH",
    skipBBOX: true,
  },
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-tyre-right-wheel",
    overlayImage: "tyres/Tyre_Wheel.svg",
    overlayImageSize: "70%",
    overlayImagePosition: "15%",
    overlayImagePositionLeft: "5%",
    side: "REAR_TYRE_RIGHT_WHEEL",
    label: "Rear Tyre Right Wheel",
    area: "REAR",
    subArea: "DRIVE_SIDE_REAR_WHEEL",
  },
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-tyre-right-rim",
    overlayImage: "tyres/Tyre_rim.svg",
    overlayImageSize: "70%",
    overlayImagePosition: "15%",
    overlayImagePositionLeft: "5%",
    side: "REAR_TYRE_RIGHT_RIM",
    label: "Rear Tyre Right Rim",
    area: "TYRE",
    subArea: "DRIVER_SIDE_REAR_RIM",
  },
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-tyre-right-depth",
    overlayImage: "tyres/Tyre_Depth.svg",
    overlayImageSize: "70%",
    overlayImagePosition: "15%",
    overlayImagePositionLeft: "5%",
    side: "REAR_TYRE_RIGHT_DEPTH",
    label: "Rear Tyre Right Tread Depth",
    area: "REAR",
    subArea: "DRIVER_SIDE_REAR_DEPTH",
    skipBBOX: true,
  },
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-tyre-left-wheel",
    overlayImage: "tyres/Tyre_Wheel.svg",
    overlayImageSize: "70%",
    overlayImagePosition: "15%",
    overlayImagePositionLeft: "5%",
    side: "FRONT_TYRE_LEFT_WHEEL",
    label: "Front Tyre Left Wheel",
    area: "FRONT",
    subArea: "PASSENGER_SIDE_FRONT_WHEEL",
  },
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-tyre-left-rim",
    overlayImage: "tyres/Tyre_rim.svg",
    overlayImageSize: "70%",
    overlayImagePosition: "15%",
    overlayImagePositionLeft: "5%",
    side: "FRONT_TYRE_LEFT_RIM",
    label: "Front Tyre Left Rim",
    area: "TYRE",
    subArea: "PASSENGER_SIDE_FRONT_RIM",
  },
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-tyre-left-depth",
    overlayImage: "tyres/Tyre_Depth.svg",
    overlayImageSize: "70%",
    overlayImagePosition: "15%",
    overlayImagePositionLeft: "5%",
    side: "FRONT_TYRE_LEFT_DEPTH",
    label: "Front Tyre Left Tread Depth",
    area: "FRONT",
    subArea: "PASSENGER_SIDE_FRONT_DEPTH",
    skipBBOX: true,
  },
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-tyre-right-wheel",
    overlayImage: "tyres/Tyre_Wheel.svg",
    overlayImageSize: "70%",
    overlayImagePosition: "15%",
    overlayImagePositionLeft: "5%",
    side: "FRONT_TYRE_RIGHT_WHEEL",
    label: "Front Tyre Right Wheel",
    area: "FRONT",
    subArea: "DRIVE_SIDE_FRONT_WHEEL",
  },
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-tyre-right-rim",
    overlayImage: "tyres/Tyre_rim.svg",
    overlayImageSize: "70%",
    overlayImagePosition: "15%",
    overlayImagePositionLeft: "5%",
    side: "FRONT_TYRE_RIGHT_RIM",
    label: "Front Tyre Right Rim",
    area: "TYRE",
    subArea: "DRIVER_SIDE_FRONT_RIM",
  },
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-tyre-right-depth",
    overlayImage: "tyres/Tyre_Depth.svg",
    overlayImageSize: "70%",
    overlayImagePosition: "15%",
    overlayImagePositionLeft: "5%",
    side: "FRONT_TYRE_RIGHT_DEPTH",
    label: "Front Tyre Right Tread Depth",
    area: "FRONT",
    subArea: "DRIVE_SIDE_FRONT_DEPTH",
    skipBBOX: true,
  },
];
export default exteriorTyreClaimInspection;
