import React from "react";
import { NON_INTERACTIVE_ORIENTATION_SCREEN } from "../../mockconfig/configs";
import { getFromStore } from "../../Stores/utils";
import Interactive from "./Interactive";
import Noninteractive from "./Noninteractive";

const OrientationAnimation = ({ device, isPortrait}) => {
  const companyDetails = getFromStore("CompanyStore");
  const nonInteractive = NON_INTERACTIVE_ORIENTATION_SCREEN.includes(companyDetails.otp);
  return (
    <>
      {nonInteractive ? <Noninteractive isPortrait={isPortrait} /> : <Interactive device={device} isPortrait={isPortrait} />}
    </>
  );
};

export default OrientationAnimation;