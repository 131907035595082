export const VIEVA_PREINSPECTION = [
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front",
    overlayImage: "/images/overlays/car_exterior/vin.svg",
    overlayImageSize: "140%",
    overlayImagePosition: "-15%",
    overlayImagePositionLeft: "3%",
    side: "VIN",
    label: "VIN Number",
    area: "VIN",
    subArea: "VIN",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/frontnext.svg",
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front",
    overlayImage: "/images/overlays/car_exterior/Frontside.svg",
    overlayImageSize: "140%",
    overlayImagePosition: "-15%",
    overlayImagePositionLeft: "3%",
    side: "FRONT",
    label: "Front Side",
    area: "FRONT",
    subArea: "FRONT",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/frontnext.svg",
  },
  // // Front Passenger Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-passenger-side",
    overlayImage: "/images/overlays/car_exterior/Passengersidefront_front.svg",
    overlayImageSize: "125%",
    overlayImagePosition: "-8%",
    overlayImagePositionLeft: "0%",
    side: "FRONT_PASSENGER_SIDE",
    label: "Front Passenger Side",
    area: "FRONT_PASSENGER_SIDE",
    subArea: "FRONT_PASSENGER_SIDE",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/frontdrivernext.svg",
  },
  // // Passenger Side front half
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-passenger-side-front-half",
    overlayImage: "/images/overlays/car_exterior/Passengerside_front.svg",
    overlayImageSize: "135%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "0",
    side: "PASSENGER_SIDE_FRONT_HALF",
    label: "Passenger Side Front Half",
    area: "PASSENGER_SIDE_FRONT_HALF",
    subArea: "PASSENGER_SIDE_FRONT_HALF",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/frontdriverhalfnext.svg",
  },
  // // Passenger Side rear half
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-passenger-side-rear-half",
    overlayImage: "/images/overlays/car_exterior/Passengerside_rear.svg",
    overlayImageSize: "135%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "PASSENGER_SIDE_REAR_HALF",
    label: "Passenger Side Rear Half",
    area: "PASSENGER_SIDE_REAR_HALF",
    subArea: "PASSENGER_SIDE_REAR_HALF",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/reardriverhalfnext.svg",
  },
  // // Rear Passenger Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-rear-passenger-side",
    overlayImage: "/images/overlays/car_exterior/Passengerrearside_rear.svg",
    overlayImageSize: "140%",
    overlayImagePosition: "-10%",
    overlayImagePositionLeft: "5%",
    side: "REAR_PASSENGER_SIDE",
    label: "Rear Passenger Side",
    area: "REAR_PASSENGER_SIDE",
    subArea: "REAR_PASSENGER_SIDE",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/reardrivernext.svg",
  },
  // // Rear Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-rear",
    overlayImage: "/images/overlays/car_exterior/Rearside.svg",
    overlayImageSize: "150%",
    overlayImagePosition: "-20%",
    overlayImagePositionLeft: "3%",
    side: "REAR",
    label: "Rear Side",
    area: "REAR",
    subArea: "REAR",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/rearnext.svg",
  },
  // // Rear Driver Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-rear-driver-side",
    overlayImage: "/images/overlays/car_exterior/Driverrearside_rear.svg",
    overlayImageSize: "140%",
    overlayImagePosition: "-10%",
    overlayImagePositionLeft: "3%",
    side: "REAR_DRIVER_SIDE",
    label: "Rear Driver Side",
    area: "REAR_DRIVER_SIDE",
    subArea: "REAR_DRIVER_SIDE",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/rearpassengernext.svg",
  },
  // // Driver Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-driver-side-rear-half",
    overlayImage: "/images/overlays/car_exterior/Driverside_rear.svg",
    overlayImageSize: "140%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "DRIVER_SIDE_REAR_HALF",
    label: "Driver Side Rear Half",
    area: "DRIVER_SIDE_REAR_HALF",
    subArea: "DRIVER_SIDE_REAR_HALF",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/rearpassengerhalfnext.svg",
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-driver-side-front-half",
    overlayImage: "/images/overlays/car_exterior/Driverside_front.svg",
    overlayImageSize: "140%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "3%",
    side: "DRIVER_SIDE_FRONT_HALF",
    label: "Driver Side Front Half",
    area: "DRIVER_SIDE_FRONT_HALF",
    subArea: "DRIVER_SIDE_FRONT_HALF",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/frontpassengerhalfnext.svg",
  },
  // // Front Driver Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-driver-side",
    overlayImage: "/images/overlays/car_exterior/Driverfrontside_front.svg",
    overlayImageSize: "125%",
    overlayImagePosition: "-5%",
    overlayImagePositionLeft: "0",
    side: "FRONT_DRIVER_SIDE",
    label: "Front Driver Side",
    area: "FRONT_DRIVER_SIDE",
    subArea: "FRONT_DRIVER_SIDE",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/frontpassengernext.svg",
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-driver-side",
    overlayImage: "/images/overlays/car_exterior/odometer.svg",
    overlayImageSize: "125%",
    overlayImagePosition: "-5%",
    overlayImagePositionLeft: "0",
    side: "ODOMETER",
    label: "Odometer",
    area: "ODOMETER",
    subArea: "ODOMETER",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/frontpassengernext.svg",
  },
];

export const TELESURE_CLAIM_OVERLAYS = [
  //   "reference data",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-license",
    overlayImage: "/images/overlays/License_Disc.png",
    overlayImageSize: "140%",
    overlayImagePosition: "-15%",
    overlayImagePositionLeft: "3%",
    side: "LICENSE",
    label: "License Disc",
    area: "LICENSE",
    subArea: "LICENSE",
  },
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-driver-side",
    overlayImage: "/images/overlays/car_exterior/odometer.svg",
    overlayImageSize: "125%",
    overlayImagePosition: "-5%",
    overlayImagePositionLeft: "0",
    side: "ODOMETER",
    label: "Odometer",
    area: "ODOMETER",
    subArea: "ODOMETER",
  },
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-full",
    overlayImage: "/images/overlays/car_exterior/Frontside.svg", // FrontSide
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "FRONT_SIDE",
    label: "Front View",
    area: "FRONT",
    subArea: "FRONT",
  },
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-full",
    overlayImage: "/images/overlays/car_exterior/Rearside.svg", // FrontSide
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "REAR_SIDE",
    label: "Rear View",
    area: "REAR",
    subArea: "REAR",
  },
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-passenger-side-full",
    overlayImage: "/images/overlays/car_exterior/Passengerside_full.svg", // FrontSide
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "PASSENGER_SIDE",
    label: "Passenger Side",
    area: "PASSENGER_SIDE",
    subArea: "PASSENGER_SIDE",
  },
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-driver-side-full",
    overlayImage: "/images/overlays/car_exterior/Driverside_full.svg", // FrontSide
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "DRIVER_SIDE",
    label: "Driver Side",
    area: "DRIVER_SIDE",
    subArea: "DRIVER_SIDE",
  },
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-bonnet",
    overlayImage: "/images/overlays/front_side/bonnet.png", // FrontSide
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "FRONT_BONNET",
    label: "Front Bonnet",
    area: "FRONT",
    subArea: "BONNET",
  },
  // "frontrightheadlight",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-passenger-side-head-light",
    overlayImage: "/images/overlays/front_side/Headlight_PassengerSide.png", // Passengersidefront_front
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "FRONT_PASSENGER_SIDE_HEAD_LIGHT",
    label: "Front Passenger Side Head Light",
    area: "FRONT",
    subArea: "PASSENGER_SIDE_HEAD_LIGHT",
  },
  // "frontrightbumper",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-passenger-side-bumper",
    overlayImage: "/images/overlays/front_side/Bumper_PassengerSide.png", //
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "FRONT_PASSENGER_SIDE_BUMPER",
    label: "Front Passenger Side Bumper",
    area: "FRONT",
    subArea: "PASSENGER_SIDE_BUMPER",
  },
  // "frontleftheadlight",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-driver-side-head-light",
    overlayImage: "/images/overlays/front_side/Headlight_DriverSide.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "0%",
    side: "FRONT_DRIVER_SIDE_HEAD_LIGHT",
    label: "Front Driver Side Head Light",
    area: "FRONT",
    subArea: "DRIVER_SIDE_HEAD_LIGHT",
  },
  // "frontleftbumper",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-driver-side-bumper",
    overlayImage: "/images/overlays/front_side/Bumper_DriverSide.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "FRONT_DRIVER_SIDE_BUMPER",
    label: "Front Driver Side Bumper",
    area: "FRONT",
    subArea: "DRIVER_SIDE_BUMPER",
  },
  // "passengersidefrontrim",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-passenger-side-front-rim",
    overlayImage: "/images/overlays/wheels/PassengerSide_FrontRim.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "PASSENGER_SIDE_FRONT_RIM",
    label: "Passenger Side Front Rim",
    area: "TYRE",
    subArea: "PASSENGER_SIDE_FRONT_RIM",
  },
  // "passengersidefronttyre",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-passenger-side-wheel",
    overlayImage: "/images/overlays/passenger_side/Front-Wheel.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "FRONT_PASSENGER_SIDE_WHEEL",
    label: "Passenger Side Front Wheel",
    area: "PASSENGER_SIDE",
    subArea: "FRONT_WHEEL",
  },
  // "passengersidefender",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-passenger-side-fender",
    overlayImage: "/images/overlays/passenger_side/Fender.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "PASSENGER_SIDE_FENDER",
    label: "Passenger Side Fender",
    area: "PASSENGER_SIDE",
    subArea: "FENDER",
  },
  // "passengersidefrontdoor",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-passenger-side-front-door",
    overlayImage: "/images/overlays/passenger_side/FrontDoor.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "PASSENGER_SIDE_FRONT_DOOR",
    label: "Passenger Side Front Door",
    area: "PASSENGER_SIDE",
    subArea: "FRONT_DOOR",
  },
  // "passengersidefrontwindow",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-glass-front-passenger-window",
    overlayImage:
      "/images/overlays/glass_and_wheels/FrontWindow_PassengerSide.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "GLASS_FRONT_PASSENGER_WINDOW",
    label: "Glass Front Passenger Window",
    area: "PASSENGER_SIDE",
    subArea: "FRONT_WINDOWPANEL",
  },
  // "passengersidereardoor",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-passenger-side-rear-door",
    overlayImage: "/images/overlays/passenger_side/RearDoor.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "PASSENGER_SIDE_REAR_DOOR",
    label: "Passenger Side Rear Door",
    area: "PASSENGER_SIDE",
    subArea: "REAR_DOOR",
  },
  // "passengersiderearwindow"
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-glass-rear-passenger-window",
    overlayImage:
      "/images/overlays/glass_and_wheels/RearWindow_PassengerSide.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "GLASS_REAR_PASSENGER_WINDOW",
    label: "Glass Rear Passenger Window",
    area: "PASSENGER_SIDE",
    subArea: "REAR_WINDOWPANEL",
  },
  // "passengersiderockerpanel",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-passenger-side-rocker-panel",
    overlayImage: "/images/overlays/passenger_side/RocketPanel.png",
    overlayImageSize: "96%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "PASSENGER_SIDE_ROCKER_PANEL",
    label: "Passenger Side Rocker Panel",
    area: "PASSENGER_SIDE",
    subArea: "ROCKER_PANEL",
  },
  // "passengersidequarter",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-passenger-side-quater",
    overlayImage: "/images/overlays/passenger_side/Quarter.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "PASSENGER_SIDE_QUATER",
    label: "Passenger Side Quarter",
    area: "PASSENGER_SIDE",
    subArea: "QUATER",
  },
  // "passengersidereartyre",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-passenger-side-rear-wheel",
    overlayImage: "/images/overlays/passenger_side/RearWheel.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "PASSENGER_SIDE_REAR_WHEEL",
    label: "Passenger Side Rear WHEEL",
    area: "PASSENGER_SIDE",
    subArea: "REAR_WHEEL",
  },
  // "passengersiderearrim",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-passenger-side-rear-rim",
    overlayImage: "/images/overlays/wheels/PassengerSide_RearRim.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "PASSENGER_SIDE_REAR_RIM",
    label: "Passenger Side Rear Rim",
    area: "TYRE",
    subArea: "PASSENGER_SIDE_REAR_RIM",
  },
  // "driversiderearrim",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-driver-side-rear-rim",
    overlayImage: "/images/overlays/wheels/DriverSide_RearRim.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "DRIVER_SIDE_REAR_RIM",
    label: "Driver Side Rear Rim",
    area: "TYRE",
    subArea: "DRIVER_SIDE_REAR_RIM",
  },
  // "driversidereartyre",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-driver-side-rear-wheel",
    overlayImage: "/images/overlays/driver_side/RearWheel.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "DRIVER_SIDE_REAR_WHEEL",
    label: "Driver Side Rear Wheel",
    area: "DRIVER_SIDE",
    subArea: "REAR_WHEEL",
  },
  // "driversidequarter",
  //TODO:
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-driver-side-quater",
    overlayImage: "/images/overlays/driver_side/Quarter.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "DRIVER_SIDE_QUATER",
    label: "Driver Side Quarter",
    area: "DRIVER_SIDE",
    subArea: "QUATER",
  },
  // "driversidereardoor",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-driver-side-rear-door",
    overlayImage: "/images/overlays/driver_side/RearDoor.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "DRIVER_SIDE_REAR_DOOR",
    label: "Driver Side Rear Door",
    area: "DRIVER_SIDE",
    subArea: "REAR_DOOR",
  },
  // "driversiderearwindow",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-glass-rear-driver-window",
    overlayImage: "/images/overlays/glass_and_wheels/RearWindow_DriverSide.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "GLASS_REAR_DRIVER_WINDOW",
    label: "Glass Rear Driver Window",
    area: "DRIVER_SIDE",
    subArea: "REAR_WINDOWPANEL",
  },
  // "driversidefrontdoor",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-driver-side-front-door",
    overlayImage: "/images/overlays/driver_side/FrontDoor.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "DRIVER_SIDE_FRONT_DOOR",
    label: "Driver Side Front Door",
    area: "DRIVER_SIDE",
    subArea: "FRONT_DOOR",
  },
  // "driversidefrontwindow",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-glass-front-driver-window",
    overlayImage:
      "/images/overlays/glass_and_wheels/FrontWindow_DriverSide.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "GLASS_FRONT_DRIVER_WINDOW",
    label: "Glass Front Driver Window",
    area: "DRIVER_SIDE",
    subArea: "FRONT_WINDOWPANEL",
  },
  // "driversiderockerpanel",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-driver-side-rocker-panel",
    overlayImage: "/images/overlays/driver_side/RockerPanel.png",
    overlayImageSize: "96%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "DRIVER_SIDE_ROCKER_PANEL",
    label: "Driver Side Rocker Panel",
    area: "DRIVER_SIDE",
    subArea: "ROCKER_PANEL",
  },
  // "driversidefender",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-driver-side-fender",
    overlayImage: "/images/overlays/driver_side/Fender.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "DRIVER_SIDE_FENDER",
    label: "Driver Side Fender",
    area: "DRIVER_SIDE",
    subArea: "FENDER",
  },
  // "driversidefronttyre",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-driver-side-front-wheel",
    overlayImage: "/images/overlays/driver_side/FrontWheel.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "8%",
    side: "DRIVER_SIDE_FRONT_WHEEL",
    label: "Driver Side Front WHEEL",
    area: "DRIVER_SIDE",
    subArea: "FRONT_WHEEL",
  },
  // "driversidefrontrim",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-driver-side-front-rim",
    overlayImage: "/images/overlays/wheels/DriverSide_FrontRim.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "8%",
    side: "DRIVER_SIDE_FRONT_RIM",
    label: "Driver Side Front Rim",
    area: "TYRE",
    subArea: "DRIVER_SIDE_FRONT_RIM",
  },
  // "windshield",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-wind-screen",
    overlayImage: "/images/overlays/glass_and_wheels/FrontWindshield.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "FRONT_WIND_SCREEN",
    label: "Front Wind Screen",
    area: "FRONT",
    subArea: "WIND_SCREEN",
  },
  // "frontroof",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-roof",
    overlayImage: "/images/overlays/roof/front_roof.svg",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "10%",
    side: "FRONT_ROOF",
    label: "Front Roof",
    area: "FRONT_ROOF",
    subArea: "FRONT_ROOF",
  },
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-back-roof",
    overlayImage: "/images/overlays/roof/back_roof.svg",
    overlayImageSize: "80%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "BACK_ROOF",
    label: "Back Roof",
    area: "BACK_ROOF",
    subArea: "BACK_ROOF",
  },
  // "rearrightheadlight",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-passenger-side-tail-light",
    overlayImage: "/images/overlays/rear_side/TailLight_PassengerSide.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "REAR_PASSENGER_SIDE_TAIL_LIGHT",
    label: "Rear Passenger Side Tail Light",
    area: "REAR",
    subArea: "PASSENGER_SIDE_TAIL_LIGHT",
  },
  // "rearrightbumper",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-passenger-side-bumper",
    overlayImage: "/images/overlays/rear_side/RearBumper_PassengerSide.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "REAR_PASSENGER_SIDE_BUMPER",
    label: "Rear Passenger Side Bumper",
    area: "REAR",
    subArea: "PASSENGER_SIDE_BUMPER",
  },
  // "rearleftheadlight",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-driver-side-tail-light",
    overlayImage: "/images/overlays/rear_side/TailLight_DriverSide.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "REAR_DRIVER_SIDE_TAIL_LIGHT",
    label: "Rear Driver Side Tail Light",
    area: "REAR",
    subArea: "DRIVER_SIDE_TAIL_LIGHT",
  },
  // "rearleftbumper",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-driver-side-bumper",
    overlayImage: "/images/overlays/rear_side/RearBumper_DriverSide.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "REAR_DRIVER_SIDE_BUMPER",
    label: "Rear Driver Side Bumper",
    area: "REAR",
    subArea: "DRIVER_SIDE_BUMPER",
  },
  // "boot",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-boot",
    overlayImage: "/images/overlays/rear_side/Boot.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "REAR_BOOT",
    label: "Rear Boot",
    area: "REAR",
    subArea: "BOOT",
  },
  // "rearscreen"
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-wind-screen",
    overlayImage: "/images/overlays/glass_and_wheels/BackWindshield.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "REAR_WIND_SCREEN",
    label: "Rear Screen",
    area: "REAR",
    subArea: "WIND_SCREEN",
  },

  //INTERIOR OVERLAYS
  // {
  //   type: "Inspection",
  //   sub: "claim",
  //   step: "interior-roof",
  //   overlayImage: "/images/overlays/interior/Dashboard.png",
  //   overlayImageSize: "90%",
  //   overlayImagePosition: "-2px",
  //   overlayImagePositionLeft: "2%",
  //   side: "INTERIOR_ROOF",
  //   label: "Roof",
  //   area: "INTERIOR",
  //   subArea: "INTERIOR_ROOF",
  //   skipCount: false,
  // },
  // "INTERIOR_DASHBOARD",
  {
    type: "Inspection",
    sub: "claim",
    step: "interior-dashboard",
    overlayImage: "/images/overlays/interior/DashboardFull.png",
    overlayImageSize: "90%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_DASHBOARD",
    label: "Dashboard",
    area: "INTERIOR",
    subArea: "DASHBOARD",
    skipCount: false,
  },
  // "INTERIOR_REAR_DASHBOARD",
  {
    type: "Inspection",
    sub: "claim",
    step: "interior-rear-dashboard",
    overlayImage: "/images/overlays/interior/Reardashboard.png",
    overlayImageSize: "90%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_REAR_DASHBOARD",
    label: "Rear Dashboard",
    area: "INTERIOR",
    subArea: "REAR_DASHBOARD",
    skipCount: false,
  },
  // "INTERIOR_DRIVER_SIDE_FRONT_DOOR",
  {
    type: "Inspection",
    sub: "claim",
    step: "interior-driver-side-front-door",
    overlayImage: "/images/overlays/interior/FrontDoor_DriverSide.png",
    overlayImageSize: "95%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_DRIVER_SIDE_FRONT_DOOR",
    label: "Driver Side Front Door",
    area: "INTERIOR",
    subArea: "DRIVER_SIDE_FRONT_DOOR",
    skipCount: false,
  },
  // "INTERIOR_DRIVER_SIDE_FRONT_SEAT",
  {
    type: "Inspection",
    sub: "claim",
    step: "interior-driver-side-front-seat",
    overlayImage: "/images/overlays/interior/FrontSeat_DriverSide.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_DRIVER_SIDE_FRONT_SEAT",
    label: "Driver side Front Seat",
    area: "INTERIOR",
    subArea: "DRIVER_SIDE_FRONT_SEAT",
    skipCount: false,
  },
  // "INTERIOR_DRIVER_SIDE_REAR_DOOR",
  {
    type: "Inspection",
    sub: "claim",
    step: "interior-driver-side-rear-door",
    overlayImage: "/images/overlays/interior/RearDoor_DriverSide.png",
    overlayImageSize: "94%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_DRIVER_SIDE_REAR_DOOR",
    label: "Driver Side Rear Door",
    area: "INTERIOR",
    subArea: "DRIVER_SIDE_REAR_DOOR",
    skipCount: false,
  },
  // "INTERIOR_DRIVER_SIDE_REAR_SEAT",
  {
    type: "Inspection",
    sub: "claim",
    step: "interior-driver-side-rear-seat",
    overlayImage: "/images/overlays/interior/RearSeat_DriverSide.png",
    overlayImageSize: "90%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_DRIVER_SIDE_REAR_SEAT",
    label: "Driver Side Rear Seat",
    area: "INTERIOR",
    subArea: "DRIVER_SIDE_REAR_SEAT",
    skipCount: false,
  },
  // "INTERIOR_GEAR_AREA",
  {
    type: "Inspection",
    sub: "claim",
    step: "interior-gear-area",
    overlayImage: "/images/overlays/interior/GearArea.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_GEAR_AREA",
    label: "Gear Area",
    area: "INTERIOR",
    subArea: "GEAR_AREA",
    skipCount: false,
  },
  // "INTERIOR_REAR_MIDDLE_SEAT",
  {
    type: "Inspection",
    sub: "claim",
    step: "interior-rear-middle-seat",
    overlayImage: "/images/overlays/interior/MiddleSeat.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_REAR_MIDDLE_SEAT",
    label: "Rear Middle Seat",
    area: "INTERIOR",
    subArea: "REAR_MIDDLE_SEAT",
    skipCount: false,
  },
  // "INTERIOR_PASSENGER_SIDE_FRONT_DOOR",
  {
    type: "Inspection",
    sub: "claim",
    step: "interior-passenger-side-front-door",
    overlayImage: "/images/overlays/interior/FrontDoor_PassengerSide.png",
    overlayImageSize: "95%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_PASSENGER_SIDE_FRONT_DOOR",
    label: "Passenger Side Front Door",
    area: "INTERIOR",
    subArea: "PASSENGER_SIDE_FRONT_DOOR",
    skipCount: false,
  },
  // "INTERIOR_PASSENGER_SIDE_FRONT_SEAT",
  {
    type: "Inspection",
    sub: "claim",
    step: "interior-passenger-side-front-seat",
    overlayImage: "/images/overlays/interior/FrontSeat_PassengerSide.png",
    overlayImageSize: "90%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_PASSENGER_SIDE_FRONT_SEAT",
    label: "Passenger Side Front Seat",
    area: "INTERIOR",
    subArea: "PASSENGER_SIDE_FRONT_SEAT",
    skipCount: false,
  },
  // "INTERIOR_PASSENGER_SIDE_REAR_DOOR",
  {
    type: "Inspection",
    sub: "claim",
    step: "interior-passenger-side-rear-door",
    overlayImage: "/images/overlays/interior/RearDoor_PassengerSide.png",
    overlayImageSize: "94%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_PASSENGER_SIDE_REAR_DOOR",
    label: "Passenger Side Rear Door",
    area: "INTERIOR",
    subArea: "PASSENGER_SIDE_REAR_DOOR",
    skipCount: false,
  },
  // "INTERIOR_PASSENGER_SIDE_REAR_SEAT",
  {
    type: "Inspection",
    sub: "claim",
    step: "interior-passenger-side-rear-seat",
    overlayImage: "/images/overlays/interior/RearSeat_PassengerSide.png",
    overlayImageSize: "90%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_PASSENGER_SIDE_REAR_SEAT",
    label: "Passenger Side Rear Seat",
    area: "INTERIOR",
    subArea: "PASSENGER_SIDE_REAR_SEAT",
    skipCount: false,
  },
  // "INTERIOR_STEERING",
  {
    type: "Inspection",
    sub: "claim",
    step: "interior-steering",
    overlayImage: "/images/overlays/interior/Steering.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_STEERING",
    label: "Steering",
    area: "INTERIOR",
    subArea: "STEERING",
    skipCount: false,
  },

];

export const PRE_INSPECTION = [
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-license",
    overlayImage: "/images/overlays/License_Disc.png",
    overlayImageSize: "140%",
    overlayImagePosition: "-15%",
    overlayImagePositionLeft: "3%",
    side: "LICENSE",
    label: "License Disc",
    area: "LICENSE",
    subArea: "LICENSE",
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-driver-side",
    overlayImage: "/images/overlays/car_exterior/odometer.svg",
    overlayImageSize: "125%",
    overlayImagePosition: "-5%",
    overlayImagePositionLeft: "0",
    side: "ODOMETER",
    label: "Odometer",
    area: "ODOMETER",
    subArea: "ODOMETER",
  },
  // Exterior
  // // Front
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front",
    overlayImage: "/images/overlays/car_exterior/Frontside.svg",
    overlayImageSize: "105%",
    overlayImagePosition: "-5%",
    overlayImagePositionLeft: "3%",
    side: "FRONT",
    label: "Front",
    area: "FRONT",
    subArea: "FRONT",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/frontnext.svg",
  },
  // // Front Passenger Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-passenger-side",
    overlayImage: "/images/overlays/car_exterior/Passengersidefront_front.svg",
    overlayImageSize: "95%",
    overlayImagePosition: "-0%",
    overlayImagePositionLeft: "0%",
    side: "PASSENGER_SIDE",
    label: "Front Passenger Side",
    area: "FRONT_PASSENGER_SIDE",
    subArea: "FRONT_PASSENGER_SIDE",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/frontdrivernext.svg",
  },
  // // Passenger Side front half
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-passenger-side-front-half",
    overlayImage: "/images/overlays/car_exterior/Passengerside_front.svg",
    overlayImageSize: "100%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "0",
    side: "PASSENGER_SIDE_FRONT_HALF",
    label: "Passenger Side Front Half",
    area: "PASSENGER_SIDE_FRONT_HALF",
    subArea: "PASSENGER_SIDE_FRONT_HALF",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/frontdriverhalfnext.svg",
  },
  // // Passenger Side rear half
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-passenger-side-rear-half",
    overlayImage: "/images/overlays/car_exterior/Passengerside_rear.svg",
    overlayImageSize: "100%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "PASSENGER_SIDE_REAR_HALF",
    label: "Passenger Side Rear Half",
    area: "PASSENGER_SIDE_REAR_HALF",
    subArea: "PASSENGER_SIDE_REAR_HALF",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/reardriverhalfnext.svg",
  },
  // // Rear Passenger Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-rear-passenger-side",
    overlayImage: "/images/overlays/car_exterior/Passengerrearside_rear.svg",
    overlayImageSize: "105%",
    overlayImagePosition: "-5%",
    overlayImagePositionLeft: "5%",
    side: "PASSENGER_SIDE",
    label: "Rear Passenger Side",
    area: "REAR_PASSENGER_SIDE",
    subArea: "REAR_PASSENGER_SIDE",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/reardrivernext.svg",
  },

  // // Rear Driver Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-rear-driver-side",
    overlayImage: "/images/overlays/car_exterior/Driverrearside_rear.svg",
    overlayImageSize: "105%",
    overlayImagePosition: "-5%",
    overlayImagePositionLeft: "3%",
    side: "DRIVER_SIDE",
    label: "Rear Driver Side",
    area: "REAR_DRIVER_SIDE",
    subArea: "REAR_DRIVER_SIDE",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/rearpassengernext.svg",
  },
  // // Driver Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-driver-side-rear-half",
    overlayImage: "/images/overlays/car_exterior/Driverside_rear.svg",
    overlayImageSize: "100%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "DRIVER_SIDE_FRONT_HALF",
    label: "Driver Side Rear Half",
    area: "DRIVER_SIDE_REAR_HALF",
    subArea: "DRIVER_SIDE_REAR_HALF",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/rearpassengerhalfnext.svg",
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-driver-side-front-half",
    overlayImage: "/images/overlays/car_exterior/Driverside_front.svg",
    overlayImageSize: "105%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "3%",
    side: "DRIVER_SIDE_FRONT_HALF",
    label: "Driver Side Front Half",
    area: "DRIVER_SIDE_FRONT_HALF",
    subArea: "DRIVER_SIDE_FRONT_HALF",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/frontpassengerhalfnext.svg",
  },
  // // Front Driver Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-driver-side",
    overlayImage: "/images/overlays/car_exterior/Driverfrontside_front.svg",
    overlayImageSize: "95%",
    overlayImagePosition: "-0%",
    overlayImagePositionLeft: "0",
    side: "FRONT_DRIVER_SIDE",
    label: "Front Driver Side",
    area: "FRONT_DRIVER_SIDE",
    subArea: "FRONT_DRIVER_SIDE",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/frontpassengernext.svg",
  },
  // // Rear Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-rear",
    overlayImage: "/images/overlays/car_exterior/Rearside.svg",
    overlayImageSize: "110%",
    overlayImagePosition: "-10%",
    overlayImagePositionLeft: "3%",
    side: "REAR_SIDE",
    label: "Rear Side",
    area: "REAR",
    subArea: "REAR",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/rearnext.svg",
  }, //18
  //windshield
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-windshield",
    overlayImage: "/images/overlays/exterior/v3/windshield/wind_shield_front.svg",
    overlayImageSize: "170%",
    overlayImagePosition: "-60%",
    overlayImagePositionLeft: "0",
    side: "FRONT_WINDSHIELD",
    label: "Front Wind Shield",
    area: "FRONT_WINDSHIELD",
    subArea: "FRONT_WINDSHIELD",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-windshield-front-right",
    overlayImage: "/images/overlays/exterior/v3/windshield/wind_shield_front_right.svg",
    overlayImageSize: "230%",
    overlayImagePosition: "-120%",
    overlayImagePositionLeft: "25%",
    side: "FRONT_WINDSHIELD_RIGHT",
    label: "Front Wind Shield Front Right",
    area: "FRONT_WINDSHIELD_FRONT_RIGHT",
    subArea: "FRONT_WINDSHIELD_FRONT_RIGHT",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-windshield-front-left",
    overlayImage: "/images/overlays/exterior/v3/windshield/wind_shield_front_left.svg",
    overlayImageSize: "230%",
    overlayImagePosition: "-120%",
    overlayImagePositionLeft: "20%",
    side: "FRONT_WINDSHIELD_LEFT",
    label: "Front Wind Shield Front Left",
    area: "FRONT_WINDSHIELD_FRONT_LEFT",
    subArea: "FRONT_WINDSHIELD_FRONT_LEFT",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-roof",
    overlayImage: "/images/overlays/roof/front_roof.svg",
    overlayImageSize: "80%",
    overlayImagePosition: "3%",
    overlayImagePositionLeft: "0",
    side: "FRONT_ROOF",
    label: "Front Roof",
    area: "FRONT_ROOF",
    subArea: "FRONT_ROOF",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-back-roof",
    overlayImage: "/images/overlays/roof/back_roof.svg",
    overlayImageSize: "80%",
    overlayImagePosition: "3%",
    overlayImagePositionLeft: "0",
    side: "BACK_ROOF",
    label: "Back Roof",
    area: "BACK_ROOF",
    subArea: "BACK_ROOF",
    skipBBOX: true,
    skipCount: false,
  },

  //PORTRAIT JOURNEY OVERLAYS
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-right",
    overlayImage: "/images/overlays/car_exterior/portrait/Frontside_left.svg",
    overlayImageSize: "70%",
    overlayImagePosition: "22%",
    overlayImagePositionLeft: "0%",
    side: "FRONT_RIGHT",
    label: "Front Right",
    area: "FRONT_RIGHT",
    subArea: "FRONT_RIGHT",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-left",
    overlayImage: "/images/overlays/car_exterior/portrait/Frontside_right.svg",
    overlayImageSize: "70%",
    overlayImagePosition: "22%",
    overlayImagePositionLeft: "8%",
    side: "FRONT_LEFT",
    label: "Front Left",
    area: "FRONT_LEFT",
    subArea: "FRONT_LEFT",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-left-corner",
    overlayImage: "/images/overlays/car_exterior/portrait/wind_shield_front_right.svg",
    overlayImageSize: "55%",
    overlayImagePosition: "30%",
    overlayImagePositionLeft: "-8%",
    side: "FRONT_LEFT_CORNER",
    label: "Front left corner",
    area: "FRONT_LEFT_CORNER",
    subArea: "FRONT_LEFT_CORNER",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-right-corner",
    overlayImage: "/images/overlays/car_exterior/portrait/wind_shield_front_left.svg",
    overlayImageSize: "60%",
    overlayImagePosition: "25%",
    overlayImagePositionLeft: "4%",
    side: "FRONT_RIGHT_CORNER",
    label: "Front right corner",
    area: "FRONT_RIGHT_CORNER",
    subArea: "FRONT_RIGHT_CORNER",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-passenger-side-portrait",
    overlayImage: "/images/overlays/car_exterior/portrait/passenger_side_front.svg",
    overlayImageSize: "30%",
    overlayImagePosition: "40%",
    overlayImagePositionLeft: "4%",
    side: "PASSENGER_SIDE",
    label: "Passenger side front half ",
    area: "FRONT_PASSENGER_SIDE_PORTRAIT",
    subArea: "FRONT_PASSENGER_SIDE_PORTRAIT",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-passenger-side-front-door",
    overlayImage: "/images/overlays/car_exterior/portrait/passenger_side_front_door.svg",
    overlayImageSize: "45%",
    overlayImagePosition: "40%",
    overlayImagePositionLeft: "4%",
    side: "PASSENGER_SIDE_FRONT_DOOR",
    label: "Passenger Side Front Door",
    area: "FRONT_PASSENGER_SIDE_FRONT_DOOR",
    subArea: "FRONT_PASSENGER_SIDE_FRONT_DOOR",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-passenger-side-rear-door",
    overlayImage: "/images/overlays/car_exterior/portrait/passenger_side_rear_door.svg",
    overlayImageSize: "50%",
    overlayImagePosition: "30%",
    overlayImagePositionLeft: "4%",
    side: "PASSENGER_SIDE_REAR_DOOR",
    label: "Passenger Side Rear Door",
    area: "FRONT_PASSENGER_SIDE_REAR_DOOR",
    subArea: "FRONT_PASSENGER_SIDE_REAR_DOOR",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-passenger-side-rear-half-portrait",
    overlayImage: "/images/overlays/car_exterior/portrait/passenger_side_rear.svg",
    overlayImageSize: "40%",
    overlayImagePosition: "40%",
    overlayImagePositionLeft: "4%",
    side: "PASSENGER_SIDE_REAR_HALF_PORTRAIT",
    label: "Passenger Side Rear Half",
    area: "PASSENGER_SIDE_REAR_HALF_PORTRAIT",
    subArea: "PASSENGER_SIDE_REAR_HALF_PORTRAIT",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-rear-left-corner",
    overlayImage: "/images/overlays/car_exterior/Passengerrearside_rear.svg",
    overlayImageSize: "60%",
    overlayImagePosition: "25%",
    overlayImagePositionLeft: "10%",
    side: "REAR_LEFT_CORNER",
    label: "Rear left corner",
    area: "REAR_LEFT_CORNER",
    subArea: "REAR_LEFT_CORNER",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-rear-left",
    overlayImage: "/images/overlays/car_exterior/portrait/Rearside_left.svg",
    overlayImageSize: "70%",
    overlayImagePosition: "23%",
    overlayImagePositionLeft: "5%",
    side: "REAR_LEFT",
    label: "Rear Left",
    area: "REAR_LEFT",
    subArea: "REAR_LEFT",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-rear-right",
    overlayImage: "/images/overlays/car_exterior/portrait/Rearside_right.svg",
    overlayImageSize: "70%",
    overlayImagePosition: "22%",
    overlayImagePositionLeft: "5%",
    side: "REAR_RIGHT",
    label: "Rear right",
    area: "REAR_RIGHT",
    subArea: "REAR_RIGHT",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-rear-right-corner",
    overlayImage: "/images/overlays/car_exterior/Driverrearside_rear.svg",
    overlayImageSize: "60%",
    overlayImagePosition: "25%",
    overlayImagePositionLeft: "3%",
    side: "REAR_RIGHT_CORNER",
    label: "Rear right corner",
    area: "REAR_RIGHT_CORNER",
    subArea: "REAR_RIGHT_CORNER",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-driver-side-rear-half-portrait",
    overlayImage: "/images/overlays/car_exterior/portrait/driver_side_rear_half.svg",
    overlayImageSize: "40%",
    overlayImagePosition: "40%",
    overlayImagePositionLeft: "4%",
    side: "DRIVER_SIDE_REAR_HALF_PORTRAIT",
    label: "Driver side rear half",
    area: "DRIVER_SIDE_REAR_HALF_PORTRAIT",
    subArea: "DRIVER_SIDE_REAR_HALF_PORTRAIT",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-driver-side-rear-door",
    overlayImage: "/images/overlays/car_exterior/portrait/driver_side_rear_door.svg",
    overlayImageSize: "40%",
    overlayImagePosition: "40%",
    overlayImagePositionLeft: "4%",
    side: "DRIVER_SIDE_REAR_DOOR",
    label: "Driver side rear door",
    area: "DRIVER_SIDE_REAR_DOOR",
    subArea: "DRIVER_SIDE_REAR_DOOR",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-driver-side-front-door",
    overlayImage: "/images/overlays/car_exterior/portrait/driver_side_front_door.svg",
    overlayImageSize: "40%",
    overlayImagePosition: "40%",
    overlayImagePositionLeft: "4%",
    side: "DRIVER_SIDE_FRONT_DOOR",
    label: "Driver side front door",
    area: "DRIVER_SIDE_FRONT_DOOR",
    subArea: "DRIVER_SIDE_FRONT_DOOR",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-driver-side-front-half-portrait",
    overlayImage: "/images/overlays/car_exterior/portrait/driver_side_front_half.svg",
    overlayImageSize: "30%",
    overlayImagePosition: "40%",
    overlayImagePositionLeft: "4%",
    side: "DRIVER_SIDE_FRONT_HALF_PORTRAIT",
    label: "Driver side front half",
    area: "DRIVER_SIDE_FRONT_HALF_PORTRAIT",
    subArea: "DRIVER_SIDE_FRONT_HALF_PORTRAIT",
    skipBBOX: true,
    skipCount: false,
  },
  //WindShield
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-windshield-front-left-portrait",
    overlayImage: "/images/overlays/exterior/v3/windshield/wind_shield_front.svg",
    overlayImageSize: "100%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "50%",
    side: "FRONT_WINDSHIELD_LEFT_PORTRAIT",
    label: "Front Wind Shield Front Left",
    area: "FRONT_WINDSHIELD_FRONT_LEFT_PORTRAIT",
    subArea: "FRONT_WINDSHIELD_FRONT_LEFT_PORTRAIT",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-windshield-front-right-portrait",
    overlayImage: "/images/overlays/exterior/v3/windshield/wind_shield_front.svg",
    overlayImageSize: "100%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "-50%",
    side: "FRONT_WINDSHIELD_RIGHT_PORTRAIT",
    label: "Front Wind Shield Front Right",
    area: "FRONT_WINDSHIELD_FRONT_RIGHT_PORTRAIT",
    subArea: "FRONT_WINDSHIELD_FRONT_RIGHT_PORTRAIT",
    skipBBOX: true,
    skipCount: false,
  },
  //Roof
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-roof-left-portrait",
    overlayImage: "/images/overlays/roof/front_roof.svg",
    overlayImageSize: "35%",
    overlayImagePosition: "45%",
    overlayImagePositionLeft: "40%",
    side: "FRONT_ROOF_LEFT_PORTRAIT",
    label: "Front Left Roof",
    area: "FRONT_ROOF_LEFT_PORTRAIT",
    subArea: "FRONT_ROOF_LEFT_PORTRAIT",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-roof-right-portrait",
    overlayImage: "/images/overlays/roof/front_roof.svg",
    overlayImageSize: "35%",
    overlayImagePosition: "45%",
    overlayImagePositionLeft: "-40%",
    side: "FRONT_ROOF_RIGHT_PORTRAIT",
    label: "Front Right Roof",
    area: "FRONT_ROOF_RIGHT_PORTRAIT",
    subArea: "FRONT_ROOF_RIGHT_PORTRAIT",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-back-roof-left-portrait",
    overlayImage: "/images/overlays/roof/back_roof.svg",
    overlayImageSize: "35%",
    overlayImagePosition: "45%",
    overlayImagePositionLeft: "40%",
    side: "BACK_ROOF_LEFT_PORTRAIT",
    label: "Back Left Roof",
    area: "BACK_ROOF_LEFT_PORTRAIT",
    subArea: "BACK_ROOF_LEFT_PORTRAIT",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-back-roof-right-portrait",
    overlayImage: "/images/overlays/roof/back_roof.svg",
    overlayImageSize: "35%",
    overlayImagePosition: "45%",
    overlayImagePositionLeft: "-40%",
    side: "BACK_ROOF_RIGHT_PORTRAIT",
    label: "Back Right Roof",
    area: "BACK_ROOF_RIGHT_PORTRAIT",
    subArea: "BACK_ROOF_RIGHT_PORTRAIT",
    skipBBOX: true,
    skipCount: false,
  },
  // INSPECTION INTERIOR
  // "INTERIOR_DASHBOARD",
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-dashboard",
    overlayImage: "/images/overlays/interior/DashboardFull.png",
    overlayImageSize: "90%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_DASHBOARD",
    label: "Dashboard",
    area: "INTERIOR",
    subArea: "DASHBOARD",
  },
  // "INTERIOR_REAR_DASHBOARD",
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-rear-dashboard",
    overlayImage: "/images/overlays/interior/Reardashboard.png",
    overlayImageSize: "90%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_REAR_DASHBOARD",
    label: "Rear Dashboard",
    area: "INTERIOR",
    subArea: "REAR_DASHBOARD",
  },
  // "INTERIOR_DRIVER_SIDE_FRONT_DOOR",
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-driver-side-front-door",
    overlayImage: "/images/overlays/interior/FrontDoor_DriverSide.png",
    overlayImageSize: "95%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_DRIVER_SIDE_FRONT_DOOR",
    label: "Driver Side Front Door",
    area: "INTERIOR",
    subArea: "DRIVER_SIDE_FRONT_DOOR",
  },
  // "INTERIOR_DRIVER_SIDE_FRONT_SEAT",
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-driver-side-front-seat",
    overlayImage: "/images/overlays/interior/FrontSeat_DriverSide.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_DRIVER_SIDE_FRONT_SEAT",
    label: "Driver side Front Seat",
    area: "INTERIOR",
    subArea: "DRIVER_SIDE_FRONT_SEAT",
  },
  // "INTERIOR_DRIVER_SIDE_REAR_DOOR",
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-driver-side-rear-door",
    overlayImage: "/images/overlays/interior/RearDoor_DriverSide.png",
    overlayImageSize: "94%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_DRIVER_SIDE_REAR_DOOR",
    label: "Driver Side Rear Door",
    area: "INTERIOR",
    subArea: "DRIVER_SIDE_REAR_DOOR",
  },
  // "INTERIOR_DRIVER_SIDE_REAR_SEAT",
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-driver-side-rear-seat",
    overlayImage: "/images/overlays/interior/RearSeat_DriverSide.png",
    overlayImageSize: "90%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_DRIVER_SIDE_REAR_SEAT",
    label: "Driver Side Rear Seat",
    area: "INTERIOR",
    subArea: "DRIVER_SIDE_REAR_SEAT",
  },
  // "INTERIOR_GEAR_AREA",
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-gear-area",
    overlayImage: "/images/overlays/interior/GearArea.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_GEAR_AREA",
    label: "Gear Area",
    area: "INTERIOR",
    subArea: "GEAR_AREA",
  },
  // "INTERIOR_REAR_MIDDLE_SEAT",
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-rear-middle-seat",
    overlayImage: "/images/overlays/interior/MiddleSeat.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_REAR_MIDDLE_SEAT",
    label: "Rear Middle Seat",
    area: "INTERIOR",
    subArea: "REAR_MIDDLE_SEAT",
  },
  // "INTERIOR_PASSENGER_SIDE_FRONT_DOOR",
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-passenger-side-front-door",
    overlayImage: "/images/overlays/interior/FrontDoor_PassengerSide.png",
    overlayImageSize: "95%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_PASSENGER_SIDE_FRONT_DOOR",
    label: "Passenger Side Front Door",
    area: "INTERIOR",
    subArea: "PASSENGER_SIDE_FRONT_DOOR",
  },
  // "INTERIOR_PASSENGER_SIDE_FRONT_SEAT",
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-passenger-side-front-seat",
    overlayImage: "/images/overlays/interior/FrontSeat_PassengerSide.png",
    overlayImageSize: "90%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_PASSENGER_SIDE_FRONT_SEAT",
    label: "Passenger Side Front Seat",
    area: "INTERIOR",
    subArea: "PASSENGER_SIDE_FRONT_SEAT",
  },
  // "INTERIOR_PASSENGER_SIDE_REAR_DOOR",
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-passenger-side-rear-door",
    overlayImage: "/images/overlays/interior/RearDoor_PassengerSide.png",
    overlayImageSize: "94%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_PASSENGER_SIDE_REAR_DOOR",
    label: "Passenger Side Rear Door",
    area: "INTERIOR",
    subArea: "PASSENGER_SIDE_REAR_DOOR",
  },
  // "INTERIOR_PASSENGER_SIDE_REAR_SEAT",
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-passenger-side-rear-seat",
    overlayImage: "/images/overlays/interior/RearSeat_PassengerSide.png",
    overlayImageSize: "90%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_PASSENGER_SIDE_REAR_SEAT",
    label: "Passenger Side Rear Seat",
    area: "INTERIOR",
    subArea: "PASSENGER_SIDE_REAR_SEAT",
  },
  // "INTERIOR_STEERING",
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-steering",
    overlayImage: "/images/overlays/interior/Steering.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_STEERING",
    label: "Steering",
    area: "INTERIOR",
    subArea: "STEERING",
  },

  //Portrait Joureny
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-steering-wheel",
    overlayImage: "/images/overlays/interior/portrait/steering-wheel.svg",
    overlayImageSize: "50%",
    overlayImagePosition: "30%",
    overlayImagePositionLeft: "5%",
    side: "INTERIOR_STEERING_WHEEL",
    label: "Steering wheel",
    area: "INTERIOR",
    subArea: "STEERING_WHEEL",
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-glove-box",
    overlayImage: "/images/overlays/interior/portrait/glove-box.svg",
    overlayImageSize: "55%",
    overlayImagePosition: "30%",
    overlayImagePositionLeft: "5%",
    side: "INTERIOR_GLOVE_BOX",
    label: "Glove box",
    area: "INTERIOR",
    subArea: "GLOVE_BOX",
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-gear-stick",
    overlayImage: "/images/overlays/interior/portrait/gear-stick.svg",
    overlayImageSize: "80%",
    overlayImagePosition: "15%",
    overlayImagePositionLeft: "5%",
    side: "INTERIOR_GEAR_STICK",
    label: "Gear stick",
    area: "INTERIOR",
    subArea: "GEAR_STICK",
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-driver-seat",
    overlayImage: "/images/overlays/interior/portrait/seat.svg",
    overlayImageSize: "80%",
    overlayImagePosition: "15%",
    overlayImagePositionLeft: "4%",
    side: "INTERIOR_DRIVER_SEAT",
    label: "Driver seat",
    area: "INTERIOR",
    subArea: "DRIVER_SEAT",
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-driver-side-front-door-portrait",
    overlayImage: "/images/overlays/interior/FrontDoor_DriverSide.png",
    overlayImageSize: "60%",
    overlayImagePosition: "20%",
    overlayImagePositionLeft: "-5%",
    side: "INTERIOR_DRIVER_SIDE_FRONT_DOOR_PORTRAIT",
    label: "Driver side front door",
    area: "INTERIOR",
    subArea: "DRIVER_SIDE_FRONT_DOOR_PORTRAIT",
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-driver-side-rear-door-portrait",
    overlayImage: "/images/overlays/interior/portrait/interior_door.svg",
    overlayImageSize: "55%",
    overlayImagePosition: "20%",
    overlayImagePositionLeft: "4%",
    side: "INTERIOR_DRIVER_SIDE_REAR_DOOR_PORTRAIT",
    label: "Driver side rear door",
    area: "INTERIOR",
    subArea: "DRIVER_SIDE_REAR_DOOR_PORTRAIT",
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-rear-right-seat",
    overlayImage: "/images/overlays/interior/RearSeat_DriverSide.png",
    overlayImageSize: "60%",
    overlayImagePosition: "20%",
    overlayImagePositionLeft: "45%",
    side: "INTERIOR_REAR_RIGHT_SEAT",
    label: "Rear right seat",
    area: "INTERIOR",
    subArea: "REAR_RIGHT_SEAT",
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-back-trunk",
    overlayImage: "/images/overlays/interior/portrait/trunk.svg",
    overlayImageSize: "40%",
    overlayImagePosition: "30%",
    overlayImagePositionLeft: "4%",
    side: "INTERIOR_BACK_TRUNK",
    label: "Back trunk",
    area: "INTERIOR",
    subArea: "INTERIOR_BACK_TRUNK",
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-passenger-side-rear-door-portrait",
    overlayImage: "/images/overlays/interior/RearDoor_PassengerSide.png",
    overlayImageSize: "50%",
    overlayImagePosition: "25%",
    overlayImagePositionLeft: "5%",
    side: "INTERIOR_PASSENGER_SIDE_REAR_DOOR_PORTRAIT",
    label: "Passenger side rear door",
    area: "INTERIOR",
    subArea: "PASSENGER_SIDE_REAR_DOOR_PORTRAIT",
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-rear-left-seat",
    overlayImage: "/images/overlays/interior/RearSeat_PassengerSide.png",
    overlayImageSize: "60%",
    overlayImagePosition: "25%",
    overlayImagePositionLeft: "-40%",
    side: "INTERIOR_REAR_LEFT_SEAT",
    label: "Rear left seat",
    area: "INTERIOR",
    subArea: "REAR_LEFT_SEAT",
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-passenger-side-front-door-portrait",
    overlayImage: "/images/overlays/interior/FrontDoor_PassengerSide.png",
    overlayImageSize: "60%",
    overlayImagePosition: "25%",
    overlayImagePositionLeft: "4%",
    side: "INTERIOR_PASSENGER_SIDE_FRONT_DOOR_PORTRAIT",
    label: "Passenger side front door",
    area: "INTERIOR",
    subArea: "PASSENGER_SIDE_FRONT_DOOR_PORTRAIT",
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-front-passenger-seat",
    overlayImage: "/images/overlays/interior/FrontSeat_PassengerSide.png",
    overlayImageSize: "60%",
    overlayImagePosition: "25%",
    overlayImagePositionLeft: "0%",
    side: "INTERIOR_FRONT_PASSENGER_SEAT",
    label: "Front passenger seat",
    area: "INTERIOR",
    subArea: "FRONT_PASSENGERT_SEAT",
  },

  //Portrait mode claims
  // "INTERIOR_DASHBOARD",
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-dashboard-left",
    overlayImage: "/images/overlays/interior/DashboardFull.png",
    overlayImageSize: "80%",
    overlayImagePosition: "15%",
    overlayImagePositionLeft: "50%",
    side: "INTERIOR_DASHBOARD_LEFT",
    label: "Dashboard Left",
    area: "INTERIOR",
    subArea: "DASHBOARD_LEFT",
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-dashboard-right",
    overlayImage: "/images/overlays/interior/DashboardFull.png",
    overlayImageSize: "80%",
    overlayImagePosition: "15%",
    overlayImagePositionLeft: "-45%",
    side: "INTERIOR_DASHBOARD_RIGHT",
    label: "Dashboard Right",
    area: "INTERIOR",
    subArea: "DASHBOARD_RIGHT",
  },

  // "INTERIOR_REAR_DASHBOARD",
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-rear-dashboard-left",
    overlayImage: "/images/overlays/interior/Reardashboard.png",
    overlayImageSize: "50%",
    overlayImagePosition: "30%",
    overlayImagePositionLeft: "40%",
    side: "INTERIOR_REAR_DASHBOARD_LEFT",
    label: "Rear Dashboard Left",
    area: "INTERIOR",
    subArea: "REAR_DASHBOARD_LEFT",
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-rear-dashboard-right",
    overlayImage: "/images/overlays/interior/Reardashboard.png",
    overlayImageSize: "50%",
    overlayImagePosition: "30%",
    overlayImagePositionLeft: "-20%",
    side: "INTERIOR_REAR_DASHBOARD_RIGHT",
    label: "Rear Dashboard Right",
    area: "INTERIOR",
    subArea: "REAR_DASHBOARD_RIGHT",
  },

  // "INTERIOR_REAR_MIDDLE_SEAT",
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-rear-middle-seat-portrait",
    overlayImage: "/images/overlays/interior/MiddleSeat.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "-40%",
    side: "INTERIOR_REAR_MIDDLE_SEAT_PORTRAIT",
    label: "Rear Middle Seat",
    area: "INTERIOR",
    subArea: "REAR_MIDDLE_SEAT",
  },
  //3
  {
    type: "Inspection",
    sub: "pre",
    step: "car-key",
    overlayImage: "/images/overlays/car_key.png",
    overlayImageSize: "60%",
    overlayImagePosition: "25%",
    overlayImagePositionLeft: "-8%",
    side: "CAR_KEY",
    label: "Car Key",
    area: "CAR_KEY",
    subArea: "CAR_KEY",
  },
];
