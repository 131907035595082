const interiorLeftHandDrive = (type) => {
    return [
        // "INTERIOR_DASHBOARD",
    {
        type: "Inspection",
        sub: type,
        step: "interior-dashboard-left-hand-drive",
        overlayImage: "interior/DashboardFullLeftHandDrive.png",
        overlayImageSize: "90%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "2%",
        side: "INTERIOR_DASHBOARD_LEFT_HAND_DRIVE",
        label: "Dashboard",
        area: "INTERIOR",
        subArea: "DASHBOARD",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: type,
        step: "interior-rear-dashboard-left-hand-drive",
        overlayImage: "interior/ReardashboardLeftHandDrive.png",
        overlayImageSize: "90%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "2%",
        side: "INTERIOR_REAR_DASHBOARD_LEFT_HAND_DRIVE",
        label: "Rear Dashboard",
        area: "INTERIOR",
        subArea: "REAR_DASHBOARD",
        skipBBOX: type === "pre",
        skipCount: false,
      },
    // "INTERIOR_PASSENGER_SIDE_FRONT_DOOR",
      {
        type: "Inspection",
        sub: type,
        step: "interior-passenger-side-front-door-left-hand-drive",
        overlayImage: "interior/FrontDoor_DriverSide.png",
        overlayImageSize: "95%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "2%",
        side: "INTERIOR_PASSENGER_SIDE_FRONT_DOOR_LEFT_HAND_DRIVE",
        label: "Right Side Front Door",
        area: "INTERIOR",
        subArea: "RIGHT_SIDE_FRONT_DOOR",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      // "INTERIOR_PASSENGER_SIDE_FRONT_SEAT",
    {
        type: "Inspection",
        sub: type,
        step: "interior-passenger-side-front-seat-left-hand-drive",
        overlayImage: "interior/FrontSeat_DriverSide.png",
        overlayImageSize: "90%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "2%",
        side: "INTERIOR_PASSENGER_SIDE_FRONT_SEAT_LEFT_HAND_DRIVE",
        label: "Right side Front Seat",
        area: "INTERIOR",
        subArea: "RIGHT_SIDE_FRONT_SEAT",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: type,
        step: "interior-passenger-side-front-seat-portrait-left-hand-drive",
        overlayImage: "interior/FrontSeat_DriverSide.png",
        overlayImageSize: "50%",
        overlayImagePosition: "30%",
        overlayImagePositionLeft: "0%",
        side: "INTERIOR_PASSENGER_SIDE_FRONT_SEAT_PORTRAIT_LEFT_HAND_DRIVE",
        label: "Right side Front Seat",
        area: "INTERIOR",
        subArea: "RIGHT_SIDE_FRONT_SEAT",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      // "INTERIOR_PASSENGER_SIDE_REAR_DOOR",
    {
        type: "Inspection",
        sub: type,
        step: "interior-passenger-side-rear-door-left-hand-drive",
        overlayImage: "interior/RearDoor_DriverSide.png",
        overlayImageSize: "94%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "2%",
        side: "INTERIOR_PASSENGER_SIDE_REAR_DOOR_LEFT_HAND_DRIVE",
        label: "Right Side Rear Door",
        area: "INTERIOR",
        subArea: "RIGHT_SIDE_REAR_DOOR",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      // "INTERIOR_DRIVER_SIDE_REAR_SEAT",
    {
        type: "Inspection",
        sub: type,
        step: "interior-passenger-side-rear-seat-left-hand-drive",
        overlayImage: "interior/RearSeat_DriverSide.png",
        overlayImageSize: "90%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "2%",
        side: "INTERIOR_PASSENGER_SIDE_REAR_SEAT_LEFT_HAND_DRIVE",
        label: "Right Side Rear Seat",
        area: "INTERIOR",
        subArea: "RIGHT_SIDE_REAR_SEAT",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      // "INTERIOR_DRIVER_SIDE_FRONT_DOOR",
    {
        type: "Inspection",
        sub: type,
        step: "interior-driver-side-front-door-left-hand-drive",
        overlayImage: "interior/FrontDoor_PassengerSide.png",
        overlayImageSize: "95%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "2%",
        side: "INTERIOR_DRIVER_SIDE_FRONT_DOOR_LEFT_HAND_DRIVE",
        label: "Left Side Front Door",
        area: "INTERIOR",
        subArea: "LEFT_SIDE_FRONT_DOOR",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      // "INTERIOR_DRIVER_SIDE_REAR_DOOR",
    {
        type: "Inspection",
        sub: type,
        step: "interior-driver-side-rear-door-left-hand-drive",
        overlayImage: "interior/RearDoor_PassengerSide.png",
        overlayImageSize: "94%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "2%",
        side: "INTERIOR_DRIVER_SIDE_REAR_DOOR_LEFT_HAND_DRIVE",
        label: "Left Side Rear Door",
        area: "INTERIOR",
        subArea: "LEFT_SIDE_REAR_DOOR",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      // "INTERIOR_PASSENGER_SIDE_REAR_SEAT",
    {
        type: "Inspection",
        sub: type,
        step: "interior-driver-side-rear-seat-left-hand-drive",
        overlayImage: "interior/RearSeat_PassengerSide.png",
        overlayImageSize: "90%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "2%",
        side: "INTERIOR_DRIVER_SIDE_REAR_SEAT_LEFT_HAND_DRIVE",
        label: "Left Side Rear Seat",
        area: "INTERIOR",
        subArea: "LEFT_SIDE_REAR_SEAT",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      //Portrait Joureny
    {
        type: "Inspection",
        sub: type,
        step: "interior-steering-wheel-left-hand-drive",
        overlayImage: "interior/portrait/steering-wheel-left-hand-drive.png",
        overlayImageSize: "50%",
        overlayImagePosition: "30%",
        overlayImagePositionLeft: "5%",
        side: "INTERIOR_STEERING_WHEEL_LEFT_HAND_DRIVE",
        label: "Steering wheel",
        area: "INTERIOR",
        subArea: "STEERING_WHEEL",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: type,
        step: "interior-glove-box-left-hand-drive",
        overlayImage: "interior/portrait/glove-box-left-hand-drive.png",
        overlayImageSize: "55%",
        overlayImagePosition: "30%",
        overlayImagePositionLeft: "5%",
        side: "INTERIOR_GLOVE_BOX_LEFT_HAND_DRIVE",
        label: "Glove box",
        area: "INTERIOR",
        subArea: "GLOVE_BOX",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: type,
        step: "interior-passenger-side-front-door-portrait-left-hand-drive",
        overlayImage: "interior/FrontDoor_DriverSide.png",
        overlayImageSize: "60%",
        overlayImagePosition: "25%",
        overlayImagePositionLeft: "-5%",
        side: "INTERIOR_PASSENGER_SIDE_FRONT_DOOR_PORTRAIT_LEFT_HAND_DRIVE",
        label: "Right side front door",
        area: "INTERIOR",
        subArea: "RIGHT_SIDE_FRONT_DOOR_PORTRAIT",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: type,
        step: "interior-back-trunk-left-hand-drive",
        overlayImage: "interior/portrait/trunk-left-hand-drive.png",
        overlayImageSize: "40%",
        overlayImagePosition: "30%",
        overlayImagePositionLeft: "4%",
        side: "INTERIOR_BACK_TRUNK_LEFT_HAND_DRIVE",
        label: "Back trunk",
        area: "INTERIOR",
        subArea: "INTERIOR_BACK_TRUNK",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: type,
        step: "interior-driver-side-rear-door-portrait-left-hand-drive",
        overlayImage: "interior/RearDoor_PassengerSide.png",
        overlayImageSize: "50%",
        overlayImagePosition: "27%",
        overlayImagePositionLeft: "5%",
        side: "INTERIOR_DRIVER_SIDE_REAR_DOOR_PORTRAIT_LEFT_HAND_DRIVE",
        label: "Left side rear door",
        area: "INTERIOR",
        subArea: "LEFT_SIDE_REAR_DOOR_PORTRAIT",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: type,
        step: "interior-driver-side-front-door-portrait-left-hand-drive",
        overlayImage: "interior/FrontDoor_PassengerSide.png",
        overlayImageSize: "60%",
        overlayImagePosition: "25%",
        overlayImagePositionLeft: "4%",
        side: "INTERIOR_DRIVER_SIDE_FRONT_DOOR_PORTRAIT_LEFT_HAND_DRIVE",
        label: "Left side front door",
        area: "INTERIOR",
        subArea: "LEFT_SIDE_FRONT_DOOR_PORTRAIT",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: type,
        step: "interior-front-driver-seat-left-hand-drive",
        overlayImage: "interior/FrontSeat_PassengerSide.png",
        overlayImageSize: "60%",
        overlayImagePosition: "25%",
        overlayImagePositionLeft: "0%",
        side: "INTERIOR_FRONT_DRIVER_SEAT_LEFT_HAND_DRIVE",
        label: "Front Left seat",
        area: "INTERIOR",
        subArea: "FRONT_LEFT_SEAT",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      // "INTERIOR_DASHBOARD",
      // image needs to be flipped
    {
        type: "Inspection",
        sub: type,
        step: "interior-dashboard-left-left-hand-drive",
        overlayImage: "interior/DashboardFullLeftHandDrive.png",
        overlayImageSize: "80%",
        overlayImagePosition: "15%",
        overlayImagePositionLeft: "50%",
        side: "INTERIOR_DASHBOARD_LEFT_LEFT_HAND_DRIVE",
        label: "Dashboard Left",
        area: "INTERIOR",
        subArea: "DASHBOARD_LEFT",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      // image needs to be flipped
      {
        type: "Inspection",
        sub: type,
        step: "interior-dashboard-right-left-hand-drive",
        overlayImage: "interior/DashboardFullLeftHandDrive.png",
        overlayImageSize: "80%",
        overlayImagePosition: "15%",
        overlayImagePositionLeft: "-45%",
        side: "INTERIOR_DASHBOARD_RIGHT_LEFT_HAND_DRIVE",
        label: "Dashboard Right",
        area: "INTERIOR",
        subArea: "DASHBOARD_RIGHT",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      // "INTERIOR_REAR_DASHBOARD",
      // image needs to be flipped
    {
        type: "Inspection",
        sub: type,
        step: "interior-rear-dashboard-left-left-hand-drive",
        overlayImage: "interior/ReardashboardLeftHandDrive.png",
        overlayImageSize: "50%",
        overlayImagePosition: "30%",
        overlayImagePositionLeft: "40%",
        side: "INTERIOR_REAR_DASHBOARD_LEFT_LEFT_HAND_DRIVE",
        label: "Rear Dashboard Left",
        area: "INTERIOR",
        subArea: "REAR_DASHBOARD_LEFT",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      // image needs to be flipped
      {
        type: "Inspection",
        sub: type,
        step: "interior-rear-dashboard-right-left-hand-drive",
        overlayImage: "interior/ReardashboardLeftHandDrive.png",
        overlayImageSize: "50%",
        overlayImagePosition: "30%",
        overlayImagePositionLeft: "-20%",
        side: "INTERIOR_REAR_DASHBOARD_RIGHT_LEFT_HAND_DRIVE",
        label: "Rear Dashboard Right",
        area: "INTERIOR",
        subArea: "REAR_DASHBOARD_RIGHT",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: type,
        step: "interior-gear-area-left-hand-drive",
        overlayImage: "interior/GearArea.png",
        overlayImageSize: "92%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "2%",
        side: "INTERIOR_GEAR_AREA_LEFT_HAND_DRIVE",
        label: "Gear Area",
        area: "INTERIOR",
        subArea: "GEAR_AREA",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: type,
        step: "interior-driver-side-front-seat-left-hand-drive",
        overlayImage: "interior/FrontSeat_PassengerSide.png",
        overlayImageSize: "90%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "2%",
        side: "INTERIOR_DRIVER_SIDE_FRONT_SEAT_LEFT_HAND_DRIVE",
        label: "Left Side Front Seat",
        area: "INTERIOR",
        subArea: "LEFT_SIDE_FRONT_SEAT",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: type,
        step: "interior-gear-stick-left-hand-drive",
        overlayImage: "interior/portrait/gear-stick.svg",
        overlayImageSize: "60%",
        overlayImagePosition: "25%",
        overlayImagePositionLeft: "5%",
        side: "INTERIOR_GEAR_STICK_LEFT_HAND_DRIVE",
        label: "Gear stick",
        area: "INTERIOR",
        subArea: "GEAR_STICK",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: type,
        step: "interior-rear-middle-seat-left-hand-drive",
        overlayImage: "interior/MiddleSeat.png",
        overlayImageSize: "92%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "2%",
        side: "INTERIOR_REAR_MIDDLE_SEAT_LEFT_HAND_DRIVE",
        label: "Rear Middle Seat",
        area: "INTERIOR",
        subArea: "REAR_MIDDLE_SEAT",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: type,
        step: "interior-driver-seat-left-hand-drive",
        overlayImage: "interior/FrontSeat_PassengerSide.png",
        overlayImageSize: "50%",
        overlayImagePosition: "32%",
        overlayImagePositionLeft: "0%",
        side: "INTERIOR_DRIVER_SEAT_LEFT_HAND_DRIVE",
        label: "Left side front seat",
        area: "INTERIOR",
        subArea: "LEFT_SIDE_FRONT_SEAT",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: type,
        step: "interior-rear-right-seat-left-hand-drive",
        overlayImage: "interior/RearSeat_DriverSide.png",
        overlayImageSize: "60%",
        overlayImagePosition: "25%",
        overlayImagePositionLeft: "40%",
        side: "INTERIOR_REAR_RIGHT_SEAT_LEFT_HAND_DRIVE",
        label: "Rear right seat",
        area: "INTERIOR",
        subArea: "REAR_RIGHT_SEAT",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: type,
        step: "interior-rear-left-seat-left-hand-drive",
        overlayImage: "interior/RearSeat_PassengerSide.png",
        overlayImageSize: "60%",
        overlayImagePosition: "25%",
        overlayImagePositionLeft: "-40%",
        side: "INTERIOR_REAR_LEFT_SEAT_LEFT_HAND_DRIVE",
        label: "Rear left seat",
        area: "INTERIOR",
        subArea: "LEFT_SIDE_REAR_SEAT",
        skipBBOX: type === "pre",
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: type,
        step: "interior-passenger-side-rear-door-portrait-left-hand-drive",
        overlayImage: "interior/RearDoor_DriverSide.png",
        overlayImageSize: "50%",
        overlayImagePosition: "25%",
        overlayImagePositionLeft: "0%",
        side: "INTERIOR_PASSENGER_SIDE_REAR_DOOR_PORTRAIT_LEFT_HAND_DRIVE",
        label: "Right Side Rear Door",
        area: "INTERIOR",
        subArea: "RIGHT_SIDE_REAR_DOOR",
        skipBBOX: type === "pre",
        skipCount: false,
      },
    ]
}

export default interiorLeftHandDrive;