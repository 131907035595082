import * as moment from "moment";
let interceptor = [];
let interceptLoglevel;
let consoleLogLevel;
let logIdentifierArgs = {};

export const LOG_LEVEL = {
  DEBUG: 0,
  VERBOSE: 1,
  INFO: 2,
  WARN: 3,
  ERROR: 4,
  FATAL: 5,
};

const LOG_METHODS = {
  [LOG_LEVEL.DEBUG]: "debug",
  [LOG_LEVEL.VERBOSE]: "log",
  [LOG_LEVEL.INFO]: "info",
  [LOG_LEVEL.WARN]: "warn",
  [LOG_LEVEL.ERROR]: "error",
  [LOG_LEVEL.FATAL]: "fatal",
};

export const log = ({ prefix, color, level }, ...args) => {
  const method = LOG_METHODS[level] || LOG_METHODS[LOG_LEVEL.VERBOSE];
  args[0]["src"] = logIdentifierArgs;
  if (interceptor && level >= interceptLoglevel) {
    for (var i = 0; i < interceptor.length; i++)
      interceptor[i]({ level: LOG_METHODS[level], data: args });
  }
  if (!consoleLogLevel || (consoleLogLevel && level >= consoleLogLevel)) {
    console[method](
      `%c[${moment().format("HH:mm:ss:SSS")}]` + `%c ${prefix}: `,
      "background: blue; color: yellow;",
      `background: blue; color: ${color}`,
      JSON.stringify(...args)
    );
  }
};

export const logger = {
  debug(...args) {
    log(
      { prefix: "AM-DEBUG", color: "#ffffff", level: LOG_LEVEL.DEBUG },
      ...args
    );
  },
  log(...args) {
    log(
      { prefix: "AM-VERBOSE", color: "#ffffff", level: LOG_LEVEL.VERBOSE },
      ...args
    );
  },
  info(...args) {
    log(
      { prefix: "AM-INFO", color: "#48AFF0", level: LOG_LEVEL.INFO },
      ...args
    );
  },
  warn(...args) {
    log(
      { prefix: "AM-WARN", color: "#FFC940", level: LOG_LEVEL.WARN },
      ...args
    );
  },
  error(...args) {
    log(
      { prefix: "AM-ERROR", color: "#DB3737", level: LOG_LEVEL.ERROR },
      ...args
    );
  },
  fatal(...args) {
    log(
      { prefix: "AM-FATAL", color: "#DB3737", level: LOG_LEVEL.FATAL },
      ...args
    );
  },
  setIntercept(interceptlevel, callback) {
    interceptLoglevel = interceptlevel;
    interceptor.push(callback);
  },
  addLogIdentifierArgs(newArgs) {
    logIdentifierArgs = Object.assign(logIdentifierArgs, newArgs);
  },
  resetLogIdentifierArgs() {
    logIdentifierArgs = {};
  },
  setLogLevel(level) {
    consoleLogLevel = level;
  },
};
