import { ReplaySubject } from "rxjs";
import { getFromStore,setToStore } from "./utils";

const QuestionStore$ = new ReplaySubject();

export const questionStorePublisher = (data) => {
    setToStore("QuestionStore", data);
    QuestionStore$.next(data);
};

QuestionStore$.next(getFromStore("QuestionStore"));
export default QuestionStore$;