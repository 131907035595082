import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    headerText: {
        height: "12vh",
        textAlign: "center",
        paddingTop: "15px",
        marginBottom: "3px",
    },
    headingHeight: {
        [theme.breakpoints.up("xs")]: {
          height: 48,
          minHeight: 48,
        },
        [theme.breakpoints.up("sm")]: {
          height: 48,
          minHeight: 48,
        },
        [theme.breakpoints.up("md")]: {
          height: 64,
          minHeight: 64,
        },
        [theme.breakpoints.up("lg")]: {
          height: 150,
          minHeight: 150,
        },
        [theme.breakpoints.up("xl")]: {
          height: 150,
          minHeight: 150,
        },
    },
    formControl: {
        maxWidth: 120,
    },
    inputInfo: {
      fontSize: "1rem !important",
      fontWeight: "400 !important",
      color: "rgba(0, 0, 0, 0.87) !important",
      marginTop: "0 !important",
      textAlign: "center !important"
    }
}));
