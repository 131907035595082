import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useStyles } from "./style";
import { Typography } from "@material-ui/core";

const InputElement = (props) => {
  const classes = useStyles();

  const handleChange = (field_id, field_type, event) => {
    props.handleChange(field_id, field_type, event);
  };

  return (
    <Grid item xs={12}>
      <TextField
        id={props.field_id}
        error={props.error}
        className={classes.textField}
        type={props.field_type}
        inputProps={{
          style: { textAlign: "center", fontSize: "1rem", fontWeight: "700" },
        }}
        onChange={(event) =>
          handleChange(props.field_id, props.field_type, event)
        }
        placeholder={props.field_placeholder ? props.field_placeholder : ""}
        defaultValue={props.field_defaultValue}
        value={props.field_value}
        helperText={props.error}
        fullWidth
      />
      <Typography variant="body1" className={classes.label}>
        {props.field_label}
      </Typography>
      {/* <InputLabel className={classes.inputLabel}></InputLabel> */}
    </Grid>
  );
};

export default InputElement;
