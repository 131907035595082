import { Box, Typography } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React from "react";
import { useTranslation } from "react-i18next";
import Input from "../../../Elements/Input";
import InputNumber from "../../../Elements/InputNumber";

export const Element = (props) => {
  const { t } = useTranslation();
  const handleChange = (field_id, field_type, event) => {
    props.handleChange(field_id, field_type, event);
  };

  if (
    props.type === "CHECKBOX" &&
    props.panel !== "report-email" &&
    props.panel !== "report-whatsApp"
  ) {
    return (
      <Box
        style={{
          border: "1px solid",
          width: "80%",
          borderRadius: "6px",
          margin: "1px auto",
          marginTop: "5px",
          padding: "0px",
        }}
      >
        <Typography variant="body1" className="text">
          {props.question}
        </Typography>

        <ToggleButtonGroup
          color="secondary"
          value={
            props.question_value === "No"
              ? false
              : props.question_value === "Yes"
              ? true
              : null
          }
          exclusive={true}
          style={{ width: "100%" }}
          onChange={(e) => handleChange(props.question_id, "checkbox", e)}
          field_id={props.question_id}
        >
          <ToggleButton
            color="secondary"
            value={true}
            style={{
              height: "35px",
              color: "black",
              fontSize: "16px",
            }}
          >
            {t("Yes")}
          </ToggleButton>
          <ToggleButton
            value={false}
            style={{
              height: "35px",
              color: "black",
              fontSize: "16px",
            }}
          >
            {t("No")}
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    );
  }

  switch (props.data_type) {
    case "STRING":
      return (
        <Input
          field_id={props.question_id}
          field_label={props.question}
          error={props.error}
          // field_placeholder={props.place_holder_label}
          field_value={props.question_value}
          field_type="text"
          handleChange={handleChange}
          field_defaultValue={props.defaultValue}
        />
      );

    case "WHATSAPP":
      return (
        <Input
          field_id={props.question_id}
          field_label={props.question}
          error={props.error}
          // field_placeholder={props.place_holder_label}
          field_value={props.question_value}
          field_type="whatsapp"
          handleChange={handleChange}
          field_defaultValue={props.defaultValue}
        />
      );

    case "INTEGER":
      return (
        <InputNumber
          field_id={props.question_id}
          field_label={props.question}
          error={props.error}
          // field_placeholder={props.place_holder_label}
          field_value={props.question_value}
          maskChar={null}
          field_type="number"
          handleChange={handleChange}
          //field_defaultValue={"51254"}
        />
      );

    case "EMAIL":
      return (
        <Input
          field_id={props.question_id}
          field_label={props.question}
          error={props.error}
          // field_placeholder={props.place_holder_label}
          field_value={props.question_value}
          field_type="email"
          //field_defaultValue={"51254"}
          handleChange={handleChange}
        />
      );
    default:
      return (
        <Input
          field_id={props.question_id}
          field_label={props.question}
          error={props.error}
          field_placeholder={props.place_holder_label}
          field_value={props.question_value}
          field_type="text"
          //field_defaultValue={"51254"}
          handleChange={handleChange}
        />
      );
  }
};
