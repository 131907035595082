import exteriorClaimInspection from "./exteriorClaimInspection";
import exteriorPreInspection from "./exteriorPreInspection";
import exteriorPreInspectionForAlphaDirect from "./exteriorPreInspectionForAlphadirect";
import interior from "./interior";
import exteriorVideoInspection from "./exteriorVideoInspection";
import exteriorSUVInspection from "./exteriorSUVInspection";
import exteriorPreInspectionLeftHandDrive from "./exteriorPreInspectionLeftHandDrive";
import interiorLeftHandDrive from "./interiorLeftHandDrive";
import bikePreInspection from "./bikePreInspection";
import truckPreInspection from "./truckPreInspection";
import santovaInspection from "./santovaInspection";
import exteriorTyreClaimInspection from "./exteriorTyreClaimInspection";
import exteriorinspectionmomentum from "./exteriorinspectionmomentum";
import exteriorTaxiPreInspection from "./exteriorTaxiPreInspection";

// input label customization based on country code / name
const label = {
  2278: {
    saidLabel: "Enter your Case Number",
    inputErrorMessage: "Please Enter your valid Case Number",
  },
  5333: {
    saidLabel: "Enter your Policy Number",
    inputErrorMessage: "Please Enter your valid Policy Number",
  },
  4563: {
    saidLabel: "Enter your Claim Number",
    inputErrorMessage: "Please Enter your valid Claim Number",
  },
  4459: {
    saidLabel: "Enter your Stock Code",
    inputErrorMessage: "Please Enter your valid Stock Code",
  },
  6023: {
    saidLabel: "Enter your Username",
    inputErrorMessage: "Please enter your valid Username",
  },
};

export const mappingLabelForCountries = (country, otp) => {
  if (label[otp]) {
    return label[otp].saidLabel;
  }
  let countrySelected = country.toUpperCase();
  switch (countrySelected) {
    case "SOUTH AFRICA":
      return "Enter your South African ID/Passport Number";
    case "NIGERIA":
      return "Enter your Contact Number/Govt ID";
    case "USA":
      return "Enter your Contact Number";
    case "DUBAI":
      return "Enter your Contact Number";
    case "INDIA":
      return "Enter your Contact Number";
    case "KENYA":
      return "Enter your Contact Number/Govt ID";
    case "BOTSWANA":
      return "Enter your Botswana ID Number";
    default:
      return "Enter your Contact Number";
  }
};

const CustomInputsMask = {
  6023: "**********************************",
};
// input masking
export const IdInputMask = (country, otp) => {
  if (CustomInputsMask[otp]) {
    return CustomInputsMask[otp];
  }
  let countrySelected = country.toUpperCase();
  switch (countrySelected) {
    case "SOUTH AFRICA":
      return "**** **** **** ***";
    case "NIGERIA":
      return "**** **** **** ***";
    case "USA":
      return "9999 9999 9999 999";
    case "DUBAI":
      return "9999 9999 9999 999";
    case "INDIA":
      return "9999 9999 9999 999";
    case "KENYA":
      return "**** **** **** ***";
    default:
      return "9999 9999 9999 999";
  }
};

// where we need to use regex
export const CustomInputs = {
  2278: {
    pattern: "/^[0-9]{3,4}-[0-9]{7}$/",
    minimumLength: 11,
    maximumLength: 12,
    allowedChars: "0123456789-",
  },
  5333: {
    pattern: "/./",
    minimumLength: 0,
    maximumLength: 999,
    allowedChars: "0123456789-",
  },
  4563: {
    pattern:"/./",
    minimumLength: 6,
    maximumLength: 21,
    allowedChars: "0123456789-",
  },
  4459: {
    pattern:"/./",
    minimumLength: 0,
    maximumLength: 7,
    allowedChars: "0123456789-",
  },
  4459: {
    pattern: "/./",
    minlength: 0,
    maxlength: 7,
    allowedChars: "0123456789",
  },
  6023: {
    pattern: "/^[a-zA-Z ]+$/",
    minimumLength: 1,
    maximumLength: 30,
    allowedChars: "0123456789",
    allowedSpaces: true,
  },
  7862: {
    pattern: "/^[0-9a-zA-Z]+$/",
    minimumLength: 1,
    maximumLength: 13,
  },
  6294: {
    pattern: "[0-9]+",
    minimumLength: 13,
    maximumLength: 13,
  }
};

// masking length
export const IdInputMaskLength = (country, otp) => {
  if (CustomInputs[otp]) {
    return CustomInputs[otp];
  }
  let countrySelected = country.toUpperCase();
  switch (countrySelected) {
    case "SOUTH AFRICA":
      return { minimumLength: 6, maximumLength: 15 };
    case "NIGERIA":
      return { minimumLength: 6, maximumLength: 15 };
    case "USA":
      return { minimumLength: 10, maximumLength: 10 };
    case "DUBAI":
      return { minimumLength: 10, maximumLength: 10 };
    case "INDIA":
      return { minimumLength: 10, maximumLength: 11 };
    case "KENYA":
      return { minimumLength: 6, maximumLength: 15 };
    case "BOTSWANA":
      return { minimumLength: 6, maximumLength: 9 };
    default:
      return { minimumLength: 6, maximumLength: 15 };
  }
};

export const InputErrorMessage = (country, otp) => {
  if (label[otp]) {
    return label[otp].inputErrorMessage;
  }
  let countrySelected = country.toUpperCase();
  switch (countrySelected) {
    case "SOUTH AFRICA":
      return "Please Enter your valid South African ID/Passport Number";
    case "NIGERIA":
      return "Please Enter your valid Contact Number/Govt ID";
    case "USA":
      return "Please Enter your valid Contact Number";
    case "DUBAI":
      return "Please Enter your valid Contact Number";
    case "INDIA":
      return "Please Enter your valid Contact Number";
    case "KENYA":
      return "Please Enter your valid Contact Number/Govt ID";
    case "BOTSWANA":
      return "Please Enter your valid Botswana Id Number";
    default:
      return "Please Enter Enter your valid Contact Number";
  }
};

export const licenseDiscOrRegistrationNumberSelection = (country) => {
  let countrySelected = country.toUpperCase();
  switch (countrySelected) {
    case "SOUTH AFRICA":
      return "license";
    case "NIGERIA":
      return "license";
    case "USA":
      return "Vin";
    case "DUBAI":
      return "Vin";
    case "INDIA":
      return "Vin";
    case "KENYA":
      return "Vin";
    case "BOTSWANA":
      return "license";
    case "EGYPT":
      return "license";
    case "GHANA":
      return "license";
    default:
      return "Vin";
  }
};

export const hasPassport = [3997, 8681, 5160, 2575, 1547, 1856];

export const nonEditedLicense = [3997, 8681];

export const companyNames = {
  3997: "FlexClub",
  8681: "FlexClub",
};

export const companyJourney = [
  {
    otp: "9994",
    journey: "V3",
  },
  {
    otp: "3997",
    journey: "V3",
  },
  {
    otp: "8681",
    journey: "V3",
  },
  {
    otp: "5160",
    journey: "V3",
  },
  {
    otp: "1547",
    journey: "V3",
  },
  {
    otp: "5103",
    journey: "V3",
  },
  {
    otp: "1865",
    journey: "V3",
  },
  {
    otp: "9104",
    journey: "V3",
  },
  {
    otp: "1888",
    journey: "V3",
  },
  {
    otp: "9191",
    journey: "V3",
  },
  {
    otp: "9999",
    journey: "BIKE",
  },
  {
    otp: "9000",
    journey: "TRUCK",
  },
  {
    otp: "1855",
    journey: "V3",
  },
  {
    otp: "9736",
    journey: "V3",
  },
  {
    otp: "3113",
    journey: "V3",
  },
  {
    otp: "9671",
    journey: "V3",
  },
  {
    otp: "5210",
    journey: "V3",
  },
];

export const threeSixtyJourneyOtps = ["9494"];
//export const CAR_LISTING_JOURNEY_OTPS = ["9998", "9106", "6023", "5798"];

// export const NIGERIAN_COMPANIES = ["2106", "2107"];

export const UPLOAD_FROM_GALLERY_OTPS = ["1547"];

export const BOUNDING_BOX_DELETABLE_COMPANIES = ["2278"];

export const claimType = [
  //Options Available:"GLASS","WIND_SCREEN","WHEEL"
  //WIND_SCREEN needs to be included in glass
  //Example config structure as below:
  // { otp: "9994", exclude: ["GLASS", "WIND_SCREEN", "WHEEL"] },
  // {
  //   otp: "9106",
  //   exclude: ["GLASS", "WIND_SCREEN"],
  // },
];

export const CAR_FOUR_SIDES_CAPTURE_OTPS = [
  "5333",
  "5518",
  "9671",
  "5896",
  "0886",
  "7938",
  "7421",
  "5210",
  "9773",
];
export const CAR_SIX_SIDES_CAPTURE_POTRAIT_OTPS=["0033","2575","9810"];
export const SAMBRA_BACK_JOURNEY = ["8206", "5477", "6023"];
export const SKIP_WHATSAPP_REPORT_QUESTION = ["5025"];
export const SKIP_WHATSAPP = {
  5025: "info@amanleek.com",
};
export const CUSTOM_THANKYOU_MSG = {
  "0033": {
    message:
      "Your claim has now been registered. One of our expert assessors will contact you in the next 5 working days to let you know the outcome of your claim.",
  },
  7862: {
    message:
      "Thank you for completing your online inspection.  One of our GoodBuyCar team members will be in contact with you shortly.",
  },
};

export const REDIRECTION_URLS = ["4200"];

export const AUTO_REDIRECT_URL = ["4200", "5889", "8480"];
export const NIGERIAN_LICENSE_DISK = ["Nigeria", "Ghana"];
export const LICENSE_DISC_CAPTURE = ["2575", "5025", "5889", "9773"];
export const BACK_BTN_OTPS = [];
export const SINGLE_SELECT_DAMAGE_OTP = ["2575"];
export const SKIP_FTUE = ["4200"];
export const SKIP_TERMS_CONDITIONS = ["4200", "6457"];
export const TYRES_RIMS_OTPS = ["9671", "5210"];
export const BEFORE_FTUE_SCREEN = ["0033"];
export const BIDVEST_OTP = ["0033"];
export const NON_INTERACTIVE_ORIENTATION_SCREEN = ["9294"];
export const SETTING_ASSESSMENT_TYPE_TO_STORE_IN_LICENSE_PAGE = [
  "9671",
  "5896",
  "5210",
  "0033",
];
export const WHATSAPP_ICON_HIDE = ["5103"];
//export const CLEAR_INDEXDB_REQUEST=["1670","5477"]
export const DYNAMIC_LINK_CONFIGURED_OTPS = ["9928"];
//This will help to set assessments to store in termsofuse page for dynamic link only
export const SETTING_ASSESSMENTS_TO_STORE_IN_TERMSOFUSE_PAGE = [
  "2575",
  "9671",
  "5896",
  "0886",
  "5210",
  "9773",
];

// for telesure and some other(future) the we need to change the labe of the policy number
export const POLICY_NUMBER_LABELS = ["2575"];
export const NOT_NEED_CURRENT_LOCATION = ["2575"];
export const EXTENDED_SIDE_FOR_POTRAIT = ["8610"];
export const TAXI_OTP=["3676"];
export const PRE_INSPECTION_POLICY_QUESTION_3=["9827","0063"];
export const MANDATORY_FOUR_SIDES_INTERNAL_UPLOAD=["2575","0063"];
export const DEFAULT_CAR_PARTS_ARRAY = ["Front", "Rear", "Driverside", "Passengerside","Passengersidefronthalf","Passengersiderearhalf","Driversidefronthalf","Driversiderearhalf"]
export const JOURNEY = {
  0: { type: "Onboard", sub: "accesscode", contained: true, centered: true }, //0
  1: { type: "Onboard", sub: "termsofuse", contained: true, centered: true }, //1
  2: { type: "Onboard", sub: "login", contained: true, centered: true }, //2
  3: {
    type: "Onboard",
    sub: "inspectiontype",
    contained: true,
    centered: true,
  }, //4
  4: { type: "Onboard", sub: "uploadtype", contained: true, centered: true }, //5
  5: {
    type: "Onboard",
    sub: "license",
    label: "license",
    overlayImage: "License_Disc.png",
    overlayImageSize: "80%",
    overlayImagePosition: "40px",
    contained: true,
    centered: true,
    nextCondition: {
      type: "AssessmentType",
      value: {
        claim: { type: "Onboard", sub: "Damageparts" },
        pre: { type: "Onboard", sub: "Damageclaim" },
        car: { type: "Onboard", sub: "Selecttype" },
      },
    },
  }, //5
  6: { type: "Onboard", sub: "Damageparts" }, //6
  7: { type: "Inspection", sub: "Claim" }, //7
  8: {
    type: "Offboard",
    sub: "Odometer",
    overlayImage: "DriverSideFull.png",
    overlayImageSize: "60%",
    overlayImagePosition: "-2px",
    side: "ODOMETER",
    label: "odometer",
    nextCondition: {
      type: "AssessmentType",
      value: {
        claim: { type: "Summary", sub: "report" },
        pre: { type: "Summary", sub: "Inspectionreport" },
        car: { type: "Summary", sub: "Videoinspectionreport" },
      },
    },
  },
  9: { type: "Summary", sub: "report" },
  10: { type: "Offboard", sub: "policy" },
  11: { type: "Onboard", sub: "emailreport" },
  12: { type: "Onboard", sub: "whatsappreport" },
  13: { type: "Onboard", sub: "checklist" },
  14: { type: "Offboard", sub: "Submitdata" },
  15: {
    type: "Offboard",
    sub: "Thankyou",
    step: "driverside",
    overlayImage: "DriverSideFull.png",
    overlayImageSize: "71%",
    overlayImagePosition: "0px",
    side: "DRIVER_SIDE",
  },
  16: { type: "Onboard", sub: "Damageclaim", contained: true, centered: true }, //12
  17: { type: "Onboard", sub: "Cartype", contained: true, centered: true },
  18: { type: "Inspection", sub: "Pre" }, //13
  19: {
    type: "Offboard",
    sub: "Odometer",
    label: "Odometer",
    overlayImage: "DriverSideFull.png",
    overlayImageSize: "60%",
    overlayImagePosition: "-2px",
    side: "ODOMETER",
  }, //14
  20: { type: "Summary", sub: "inspectionreport" }, //15
  21: { type: "Onboard", sub: "Selecttype" },
  22: { type: "Inspection", sub: "Videoinspection" }, //13
  23: { type: "Inspection", sub: "Hotspot" },
  24: { type: "Summary", sub: "Videoinspectionreport" }, //15
  25: {
    type: "Onboard",
    sub: "Vin",
    label: "Vin",
    overlayImage: "License_Disc.png",
    overlayImageSize: "100%",
    overlayImagePosition: "0px",
    nextCondition: {
      type: "AssessmentType",
      value: {
        claim: { type: "Onboard", sub: "Damageparts" },
        pre: { type: "Onboard", sub: "Damageclaim" },
        car: { type: "Onboard", sub: "Selecttype" },
      },
    },
  },
  26: {
    type: "Onboard",
    sub: "license",
    label: "license",
    overlayImage: "License_Disc.png",
    overlayImageSize: "80%",
    overlayImagePosition: "40px",
    contained: true,
    centered: true,
    nextCondition: {
      type: "AssessmentType",
      value: {
        claim: { type: "Onboard", sub: "Damageparts" },
        pre: { type: "Onboard", sub: "Damageclaim" },
      },
    },
  },
  27: {
    type: "Offboard",
    sub: "Odometer",
    label: "Odometer",
    overlayImage: "DriverSideFull.png",
    overlayImageSize: "60%",
    overlayImagePosition: "-2px",
    side: "ODOMETER",
    nextCondition: {
      type: "AssessmentType",
      value: {
        claim: { type: "Summary", sub: "report" },
        pre: { type: "Summary", sub: "Inspectionreport" },
      },
    },
  },
  28: { type: "Offboard", sub: "Looseitems" },
  29: {
    type: "Onboard",
    sub: "Vin",
    label: "Vin",
    overlayImage: "License_Disc.png",
    overlayImageSize: "100%",
    overlayImagePosition: "0px",
    nextCondition: {
      type: "AssessmentType",
      value: {
        claim: { type: "Onboard", sub: "Damageparts" },
        pre: { type: "Onboard", sub: "Damageclaim" },
      },
    },
  },
  30: {
    type: "Inspection",
    sub: "Front",
    step: "exterior-front",
    overlayImage: "exterior/v3/FrontPanel.png",
    overlayImageSize: "94%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "0",
    side: "FRONT",
    label: "Front",
    area: "FRONT",
    subArea: "FRONT",
    skipBBOX: true,
    skipCount: false,
    stepCount: "1/4",
  },
  31: {
    type: "Inspection",
    sub: "Passengerside",
    step: "exterior-passenger-side",
    overlayImage: "exterior/v3/PassengerSidePanel.png",
    overlayImageSize: "100%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "-30px",
    side: "PASSENGER_SIDE",
    label: "Passenger Side",
    area: "PASSENGER_SIDE",
    subArea: "PASSENGER_SIDE",
    skipBBOX: true,
    skipCount: false,
    stepCount: "2/4",
  },
  32: {
    type: "Inspection",
    sub: "Rear",
    step: "exterior-rear",
    overlayImage: "exterior/v3/RearPanel.png",
    overlayImageSize: "94%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "0",
    side: "REAR_SIDE",
    label: "Rear Side",
    area: "REAR",
    subArea: "REAR",
    skipBBOX: true,
    skipCount: false,
    stepCount: "3/4",
  },
  33: {
    type: "Inspection",
    sub: "Driverside",
    step: "exterior-driver-side",
    overlayImage: "exterior/v3/DriverSidePanel.png",
    overlayImageSize: "97%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "-20px",
    side: "DRIVER_SIDE",
    label: "Driver Side",
    area: "DRIVER_SIDE",
    subArea: "DRIVER_SIDE",
    skipBBOX: true,
    skipCount: false,
    stepCount: "4/4",
  },
  34: [...exteriorClaimInspection],
  35: [...interior("claim")],
  36: [...exteriorPreInspection("V3")],
  37: [...interior("pre")],
  38: [...exteriorVideoInspection("V3")],
  39: [...exteriorSUVInspection()], // order of the above 5 lines is important
  40: [...exteriorPreInspectionLeftHandDrive()],
  41: [...interiorLeftHandDrive("claim")],
  42: [...interiorLeftHandDrive("pre")],
  // 43: [...exteriorPreInspection("V4")],
  44: [...bikePreInspection("BIKE")],
  45: [...truckPreInspection("TRUCK")],
  46: [...exteriorPreInspectionForAlphaDirect],
  47: {
    type: "Offboard",
    sub: "questionnaire",
    contained: true,
    centered: true,
  },
  48: {
    type: "Onboard",
    sub: "inspectiontype",
    contained: true,
    centered: true,
    nextCondition: {
      type: "AssessmentType",
      value: {
        claim: { type: "Onboard", sub: "Damageparts" },
        pre: { type: "Onboard", sub: "Damageclaim" },
      },
    },
  },
  49: {
    type: "Onboard",
    sub: "license",
    label: "license",
    overlayImage: "License_Disc.png",
    overlayImageSize: "100%",
    overlayImagePosition: "0px",
    nextCondition: {
      type: "AssessmentType",
      value: {
        claim: { type: "Onboard", sub: "Damageparts" },
        pre: { type: "Onboard", sub: "Damageclaim" },
      },
    },
  },
  50: [...exteriorinspectionmomentum],
  51: { type: "Offboard", sub: "Portraitaitestingitems" },
  52: [...santovaInspection],
  53: [...exteriorTyreClaimInspection],
  54: { type: "Onboard", sub: "welcome", contained: true, centered: true },
  56: {
    type: "Onboard",
    sub: "beforeyoubegin",
    contained: true,
    centered: true,
  },
  57: { type: "Onboard", sub: "information", contained: true, centered: true },
  58: { type: "Onboard", sub: "uploadquotes", contained: true, centered: true },
  59: {
    type: "Onboard",
    sub: "quickreminder",
    contained: true,
    centered: true,
  },
  60: {
    type: "Inspection",
    sub: "Front",
    step: "exterior-front",
    overlayImage: "exterior/v3/FrontPanel.png",
    overlayImageSize: "65vw",
    overlayImagePosition: "35vh",
    overlayImagePositionLeft: "0",
    side: "FRONT",
    label: "Front",
    area: "FRONT",
    subArea: "FRONT",
    skipBBOX: true,
    skipCount: false,
    stepCount: "1/6",
  }, //1
  61: {
    type: "Inspection",
    sub: "Passengersidefronthalf",
    step: "exterior-passenger-side-left-half",
    overlayImage: "exterior/v3/PassengerSideLeftHalf.png",
    overlayImageSize: "85vw",
    overlayImagePosition: "25vh",
    overlayImagePositionLeft: "-7%",
    side: "PASSENGER_SIDE_LEFT_HALF",
    label: "Passenger Side Front Half",
    area: "PASSENGER_SIDE_LEFT_HALF",
    subArea: "PASSENGER_SIDE_LEFT_HALF",
    skipBBOX: true,
    skipCount: false,
    stepCount: "2/6",
  },
  62:{
    type: "Inspection",
    sub: "Passengersiderearhalf",
    step: "exterior-passenger-side-right-half",
    overlayImage: "exterior/v3/PassengerSideRightHalf.png",
    overlayImageSize: "80vw",
    overlayImagePosition: "25vh",
    overlayImagePositionLeft: "0%",
    side: "PASSENGER_SIDE_RIGHT_HALF",
    label: "Passenger Side Rear Half",
    area: "PASSENGER_SIDE_RIGHT_HALF",
    subArea: "PASSENGER_SIDE_RIGHT_HALF",
    skipBBOX: true,
    skipCount: false,
    stepCount: "3/6",
  },
  63:{
    type: "Inspection",
    sub: "Driversiderearhalf",
    step: "exterior-driver-side-left-half",
    overlayImage: "exterior/v3/DriverSidePanelLeftHalf.png",
    overlayImageSize: "70vw",
    overlayImagePosition: "30vh",
    overlayImagePositionLeft: "-20px",
    side: "DRIVER_SIDE_LEFT_HALF",
    label: "Driver Side Rear Half",
    area: "DRIVER_SIDE_LEFT_HALF",
    subArea: "DRIVER_SIDE_LEFT_HALF",
    skipBBOX: true,
    skipCount: false,
    stepCount: "5/6",
  },
  64:{
    type: "Inspection",
    sub: "Driversidefronthalf",
    step: "exterior-driver-side-right-half",
    overlayImage: "exterior/v3/DriverSidePanelRightHalf.png",
    overlayImageSize: "70vw",
    overlayImagePosition: "30vh",
    overlayImagePositionLeft: "5%",
    side: "DRIVER_SIDE_RIGHT_HALF",
    label: "Driver Side Front Half",
    area: "DRIVER_SIDE_RIGHT_HALF",
    subArea: "DRIVER_SIDE_RIGHT_HALF",
    skipBBOX: true,
    skipCount: false,
    stepCount: "6/6",
  },
  65:{
    type: "Inspection",
    sub: "Rear",
    step: "exterior-rear",
    overlayImage: "exterior/v3/RearPanel.png",
    overlayImageSize: "70vw",
    overlayImagePosition: "30vh",
    overlayImagePositionLeft: "0",
    side: "REAR_SIDE",
    label: "Rear Side",
    area: "REAR",
    subArea: "REAR",
    skipBBOX: true,
    skipCount: false,
    stepCount: "4/6",
  },
  66:{type:"Offboard", sub:"Incidentdetails"},
  67:{ type: "Offboard", sub: "Disclaimer" },
  68:{ type: "Offboard", sub: "Ratings" },
  69: [...bikePreInspection("SCOOTER")],
  70: { type: "Onboard", sub: "Webquestionnaire" },
  71:[...exteriorTaxiPreInspection],
};
