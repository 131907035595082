import React, { useEffect } from "react";

import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useStyles } from "../../Journey/styles";
import WhatsAppFunction from "../WhatsAppFunction";
import OTPStore from "../../Stores/OTPStore";
import { getFromStore } from "../../Stores/utils";

const TheUnlimited = (props) => {
  console.log("whatsapp", props);
  const classes = useStyles();
  const [loaded, setLoaded] = React.useState(false);
  return (
    <Box
      style={{ overflow: "hidden", display: "flex", justifyContent: "center" }}
      className={props.className}
    >
      {(props.type === "Submitdata") |
      (props.type === "FTUE") |
      (props.type === "orientation") ? (
        ""
      ) : (
        <WhatsAppFunction info={props.info} />
      )}
      {getFromStore("OtpInput") === "8610" ? (
        (props.type === "termsofuse") | (props.type === "login") ? (
          <img
            src={props.imageURL}
            alt={props.alt}
            style={{
              margin: "auto",
              display: loaded ? "block" : "none",
              height: "2.5rem",
            }}
            onLoad={() => setLoaded(true)}
          ></img>
        ) : (
          <img
            src={props.imageURL}
            alt={props.alt}
            style={{
              margin: "auto",
              display: loaded ? "block" : "none",
              maxHeight: "100%",
              maxWidth: "100%",
            }}
            onLoad={() => setLoaded(true)}
          ></img>
        )
      ) : (
        <img
          src={props.imageURL}
          alt={props.alt}
          style={{
            margin: "auto",
            display: loaded ? "block" : "none",
            maxHeight: "100%",
            maxWidth: "100%",
          }}
          onLoad={() => setLoaded(true)}
        ></img>
      )}

      <Skeleton
        width={150}
        style={{ margin: "0 auto", display: !loaded ? "block" : "none" }}
        className={classes.headingHeight}
      ></Skeleton>
    </Box>
  );
};

TheUnlimited.defaultProps = {
  imageURL: "/logos/carScan_logo.png",
  alt: "Carscan",
  height: "10vh",
};
export default TheUnlimited;
