import { JOURNEY } from "./configs";

export const common = (journey = "V3") => {
  if (journey === "V3") {
    let commonArray = [
      0, 1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 70, 14, 15, 16, 17, 18, 19, 20,
      11, 12, 13, 14, 15, 21, 22, 23, 19, 22, 70, 14, 15, 25, 34, 35, 36, 37,
      38, 39, 40, 41, 42,
    ];
    let arr = [].concat.apply(
      [],
      commonArray.map((i) => JOURNEY[i])
    );
    return arr;
  } else if (journey === "BIKE" || journey === "SCOOTER") {
    let commonArray = [
      0, 1, 2, 3, 26, 6, 7, 27, 9, 10, 11, 12, 70, 14, 15, 16, 18, 19, 20, 11,
      12, 70, 14, 15,
    ];
    if (journey === "BIKE") {
      commonArray.push(44);
    } else {
      commonArray.push(69);
    }
    let arr = [].concat.apply(
      [],
      commonArray.map((i) => JOURNEY[i])
    );
    return arr;
  } else if (journey === "TRUCK") {
    let commonArray = [
      0, 1, 2, 3, 26, 6, 7, 27, 9, 10, 11, 12, 70, 14, 15, 16, 18, 19, 20, 11,
      12, 70, 14, 15, 45,
    ];
    let arr = [].concat.apply(
      [],
      commonArray.map((i) => JOURNEY[i])
    );
    return arr;
  } else if (journey === "TAXI") {
    let saTaxiArray = [
      0, 1, 2, 3, 26, 6, 7, 27, 9, 11, 14, 15, 16, 17, 18, 19, 20, 11, 14, 15,
      34, 35, 36, 37, 71,
    ];
    let arr = [].concat.apply(
      [],
      saTaxiArray.map((i) => JOURNEY[i])
    );
    return arr;
  } else {
    let commonArray = [
      0, 1, 2, 3, 26, 6, 7, 27, 9, 10, 11, 12, 70, 14, 15, 16, 18, 19, 20, 11,
      12, 70, 14, 15, 34, 35, 37,
    ];
    let arr = [].concat.apply(
      [],
      commonArray.map((i) => JOURNEY[i])
    );
    return arr;
  }
};
