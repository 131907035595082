import styles from "./index.module.css";
import cs from 'classnames';

export const infoOneText = (isPortrait) => (
  <span className={cs(styles.ftueText, { [styles.ftueTextPortrait]: isPortrait })}>
    Car parked outdoor | Properly lit | 1.5 mtr free space around the car
  </span>
);

export const infoTwoText = (isPortrait) => (
  <span className={cs(styles.ftueText, { [styles.ftueTextPortrait]: isPortrait })}>
    Chrome or Safari browser | Mobile with good network coverage | Good quality
    camera | {isPortrait ? "Portrait" : "Landscape"} orientation{" "}
  </span>
);

export const infoThreeText = (isPortrait) => (
  <span className={cs(styles.ftueText, { [styles.ftueTextPortrait]: isPortrait })}>
    Mobile held at 1.5 mtr from car | Camera to be positioned at side mirror
    height | To align car with the overlay{" "}
  </span>
);

export const infoFourText = (isPortrait) => (
  <span className={cs(styles.ftueText, { [styles.ftueTextPortrait]: isPortrait })}>
    Please allow location and camera access
  </span>
);
