import { Typography } from "@material-ui/core";
import React from "react";
import MediaStreamWithDnD from "./MediaStreamWithDnD";
const Demo = () => {
  return (
    <React.Fragment>
      <Typography variant="h6">Demo Page</Typography>
      <MediaStreamWithDnD
        {...{
          type: "Inspection",
          sub: "claim",
          step: "exterior-front-driver-side-head-light",
          overlayImage: "front_side/Headlight_DriverSide.png",
          overlayImageSize: "58%",
          overlayImagePosition: "22px",
          overlayImagePositionLeft: "0%",
          side: "FRONT_DRIVER_SIDE_HEAD_LIGHT",
          label: "Front Driver Side Head Light",
          area: "FRONT",
          subArea: "DRIVER_SIDE_HEAD_LIGHT",
        }}
      />
    </React.Fragment>
  );
};

export default Demo;
