import "./fonts/Avenir/aviner.css";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import SwipeButton from "./components/SwipeButton";
import { Suspense } from "react";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import WaitingStatus from "./Stores/WaitingStatus";
import Box from "@material-ui/core/Box";
import useOrientation from "./hooks/useOrientation";
import useSubject from "./hooks/useSubject";
import Questionnaire from "./components/Questionnaire";
import Demo from "./components/demo";
import Emailreport from "./components/Journey/Onboard/Emailreport";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import TagManager from "react-gtm-module";
import { getFromStore } from "./Stores/utils";
import LoadingComponent from "./components/LoadingComponent";
import PreFetch from "./components/PreFetch";
import Page404 from "./components/Page404";
import CacheBuster from "react-cache-buster";
import { version } from "../package.json";
import Expired from "./components/Expired";
import { logger } from "./utils/logger";
import * as Sentry from "@sentry/react";
import NoNetwork from "./components/NoNetwork";
import SlowNetwork from "./components/SlowNetwork";
import UploadFromGallery from "./components/UploadFromGallery";
import FTUE from "./components/FTUE";
import { preRenderImageArray } from "./utils/helpers";
import Whatsappreport from "./components/Journey/Onboard/Whatsappreport";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#EDA03A",
  },
}));

const Journey = React.lazy(() => import("./Journey"));

const Loader = () => {
  const classes = useStyles();
  const waiting = useSubject(WaitingStatus);
  console.log("waiting", waiting);
  return (
    <Box zIndex={2000} style={{ zIndex: 2000 }}>
      <Backdrop
        className={classes.backdrop}
        open={waiting}
        zIndex={2000}
        style={{ zIndex: 2000 }}
      >
        <CircularProgress
          color="inherit"
          thickness={4}
          size={60}
          zIndex={2000}
          style={{ zIndex: 2000 }}
        />
      </Backdrop>
    </Box>
  );
};
const tagManagerArgs = {
  gtmId: "GTM-NCR4H32",
  dataLayerName: "PageDataLayer",
};
TagManager.initialize(tagManagerArgs);
function App() {
  window.addEventListener("error", function (e) {
    try {
      Sentry.captureException(
        JSON.stringify({
          tag: "ERROR",
          message: e,
        })
      );
    } catch (e) {
      console.log("Error", e);
    }
  });

  window.addEventListener("unhandledrejection", function (e) {
    try {
      Sentry.captureException(
        JSON.stringify({
          tag: "ERROR",
          message: e,
        })
      );
    } catch (e) {
      console.log("Error in unhandledrejection", e);
    }
  });

  const isProduction = process.env.NODE_ENV === "production";

  const gtmParams = {
    id: "GTM-NCR4H32",
    dataLayerName: "webapp",
  };

  const orientation = useOrientation();

  console.log(
    "orient",
    orientation,
    window.outerWidth,
    document.documentElement.clientWidth,
    document.documentElement.clientHeight
  );

  useEffect(() => {
    if (window && typeof window !== undefined) {
      window.addEventListener("touchmove", (e) => {
        e.preventDefault();
      });
    }

    return () => {
      window.removeEventListener("touchmove");
    };
  }, [window]);

  React.useEffect(() => {
    console.log(
      "Effect orient out",
      orientation,
      document.documentElement.clientWidth >
        document.documentElement.clientHeight,
      document.documentElement.clientWidth,
      document.documentElement.clientHeight
    );
    let timeout = setTimeout(() => {
      if (
        document.documentElement.clientWidth >
          document.documentElement.clientHeight &&
        (orientation === 90 || orientation === 270)
      ) {
        console.log(
          "Effect orient",
          orientation,
          document.documentElement.clientWidth >
            document.documentElement.clientHeight,
          document.documentElement.clientWidth,
          document.documentElement.clientHeight
        );

        if (
          getFromStore("innerWidth") == null &&
          getFromStore("innerHeight") == null
        ) {
          sessionStorage.setItem(
            "innerHeight",
            document.documentElement.clientHeight
          );
          sessionStorage.setItem(
            "innerWidth",
            document.documentElement.clientWidth
          );
        } else if (
          getFromStore("innerWidth") > document.documentElement.clientWidth &&
          getFromStore("innerHeight") > document.documentElement.clientHeight
        ) {
          sessionStorage.setItem(
            "innerHeight",
            document.documentElement.clientHeight
          );
          sessionStorage.setItem(
            "innerWidth",
            document.documentElement.clientWidth
          );
        }
      }
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [orientation]);

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<LoadingComponent />} //If not pass, nothing appears at the time of new version check.
    >
      <img
        src="/images/errors/noNetwork.svg"
        alt="chachebus"
        style={{ display: "none" }}
      />
      <I18nextProvider i18n={i18n}>
        <GTMProvider state={gtmParams}>
          {Loader()}
          <Router>
            <Switch>
              <Route exact path="/uploadfromgallery">
                <Suspense fallback={<LoadingComponent />}>
                  <UploadFromGallery />
                </Suspense>
              </Route>
              <Route exact path="/FTUE">
                <Suspense fallback={<LoadingComponent />}>
                  <FTUE />
                </Suspense>
              </Route>
              <Route exact path="/nonetwork">
                <Suspense fallback={<LoadingComponent />}>
                  <NoNetwork />
                </Suspense>
              </Route>
              <Route exact path="/expired">
                <Suspense fallback={<LoadingComponent />}>
                  <Expired />
                </Suspense>
              </Route>
              <Route exact path="/">
                <Suspense fallback={<LoadingComponent />}>
                  <Journey />
                </Suspense>
              </Route>
              <Route exact path="/emailreport">
                <Suspense fallback={<LoadingComponent />}>
                  <Emailreport />
                </Suspense>
              </Route>
              <Route exact path="/whatsappreport">
                <Suspense fallback={<LoadingComponent />}>
                  <Whatsappreport />
                </Suspense>
              </Route>
              <Route exact path="/questionnaire">
                <Suspense fallback={<LoadingComponent />}>
                  <Questionnaire />
                </Suspense>
              </Route>
              <Route exact path="/:myResqueOtp/kenyanId/:kid">
                <Suspense fallback={<LoadingComponent />}>
                  <Journey />
                </Suspense>
              </Route>
              <Route exact path="/demo">
                <Suspense fallback={<LoadingComponent />}>
                  <Demo />
                </Suspense>
              </Route>
              <Route
                exact
                path="/:garageOtp/:guid/:phonenumber/:regno/:reportemail/:odometerreading?"
              >
                <Suspense fallback={<LoadingComponent />}>
                  <Journey />
                </Suspense>
              </Route>
              <Route exact path="/:roadsaveOtp/:fieldThree/:uid/chat">
                <Suspense fallback={<LoadingComponent />}>
                  <Journey />
                </Suspense>
              </Route>
              <Route exact path="/:votp/ClaimNo/:claimNumber">
                <Suspense fallback={<LoadingComponent />}>
                  <Journey />
                </Suspense>
              </Route>
              <Route exact path="/:otp/:uid?">
                <Suspense fallback={<LoadingComponent />}>
                  <Journey />
                </Suspense>
              </Route>
              <Route exact path="/swipe">
                <SwipeButton />
              </Route>
              <Route exact path="/journey/:type/:sub/:step?/:retake?">
                <Suspense fallback={<LoadingComponent />}>
                  <Journey />
                </Suspense>
              </Route>
              <Route exact path="/:totp/policy/:telesurePolicy">
                <Suspense fallback={<LoadingComponent />}>
                  <Journey />
                </Suspense>
              </Route>
              <Route exact path="/:otp/:fieldThree/:saId">
                <Suspense fallback={<LoadingComponent />}>
                  <Journey />
                </Suspense>
              </Route>

              <Route exact path="*">
                <Page404 />
              </Route>
            </Switch>
          </Router>
        </GTMProvider>
      </I18nextProvider>
    </CacheBuster>
  );
}

export default App;
