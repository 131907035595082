const exteriorinspectionmomentum = [
  // Exterior
  // // Front
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front",
    overlayImage: "exterior/v3/FrontPanel.png",
    overlayImageSize: "94%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "0",
    side: "FRONT",
    label: "Front",
    area: "FRONT",
    subArea: "FRONT",
    skipBBOX: true,
    skipCount: false,
  }, //18
  //windshield
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-windshield",
    overlayImage: "exterior/v3/windshield/wind_shield_front.svg",
    overlayImageSize: "170%",
    overlayImagePosition: "-60%",
    overlayImagePositionLeft: "0",
    side: "FRONT_WINDSHIELD",
    label: "Front Wind Shield",
    area: "FRONT_WINDSHIELD",
    subArea: "FRONT_WINDSHIELD",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-windshield-front-right",
    overlayImage: "exterior/v3/windshield/wind_shield_front_right.svg",
    overlayImageSize: "230%",
    overlayImagePosition: "-120%",
    overlayImagePositionLeft: "25%",
    side: "FRONT_WINDSHIELD_RIGHT",
    label: "Front Wind Shield Front Right",
    area: "FRONT_WINDSHIELD_FRONT_RIGHT",
    subArea: "FRONT_WINDSHIELD_FRONT_RIGHT",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-windshield-front-left",
    overlayImage: "exterior/v3/windshield/wind_shield_front_left.svg",
    overlayImageSize: "230%",
    overlayImagePosition: "-120%",
    overlayImagePositionLeft: "20%",
    side: "FRONT_WINDSHIELD_LEFT",
    label: "Front Wind Shield Front Left",
    area: "FRONT_WINDSHIELD_FRONT_LEFT",
    subArea: "FRONT_WINDSHIELD_FRONT_LEFT",
    skipBBOX: true,
    skipCount: false,
  },
  // // Front Passenger Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-passenger-side",
    overlayImage: "exterior/v3/Front_PassengerSide.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "-5px",
    side: "PASSENGER_SIDE",
    label: "Front Passenger Side",
    area: "FRONT_PASSENGER_SIDE",
    subArea: "FRONT_PASSENGER_SIDE",
    skipBBOX: true,
    skipCount: false,
  },
  // // Passenger Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-passenger-side",
    overlayImage: "exterior/v3/PassengerSidePanel.png",
    overlayImageSize: "100%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "-30px",
    side: "PASSENGER_SIDE",
    label: "Passenger Side",
    area: "PASSENGER_SIDE",
    subArea: "PASSENGER_SIDE",
    skipBBOX: true,
    skipCount: false,
  },
  // // Rear Passenger Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-rear-passenger-side",
    overlayImage: "exterior/v3/Rear_PassengerSide.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "0%",
    side: "PASSENGER_SIDE",
    label: "Rear Passenger Side",
    area: "REAR_PASSENGER_SIDE",
    subArea: "REAR_PASSENGER_SIDE",
    skipBBOX: true,
    skipCount: false,
  },
  // // Rear Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-rear",
    overlayImage: "exterior/v3/RearPanel.png",
    overlayImageSize: "94%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "0",
    side: "REAR_SIDE",
    label: "Rear Side",
    area: "REAR",
    subArea: "REAR",
    skipBBOX: true,
    skipCount: false,
  },
  // // Rear Driver Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-rear-driver-side",
    overlayImage: "exterior/v3/Rear_DriverSide.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "0",
    side: "DRIVER_SIDE",
    label: "Rear Driver Side",
    area: "REAR_DRIVER_SIDE",
    subArea: "REAR_DRIVER_SIDE",
    skipBBOX: true,
    skipCount: false,
  },
  // // Driver Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-driver-side",
    overlayImage: "exterior/v3/DriverSidePanel.png",
    overlayImageSize: "97%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "-20px",
    side: "DRIVER_SIDE",
    label: "Driver Side",
    area: "DRIVER_SIDE",
    subArea: "DRIVER_SIDE",
    skipBBOX: true,
    skipCount: false,
  },
  // // Front Driver Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-driver-side",
    overlayImage: "exterior/v3/Front_DriverSide.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "0px",
    side: "DRIVER_SIDE",
    label: "Front Driver Side",
    area: "FRONT_DRIVER_SIDE",
    subArea: "FRONT_DRIVER_SIDE",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-roof",
    overlayImage: "roof/front_roof.svg",
    overlayImageSize: "80%",
    overlayImagePosition: "3%",
    overlayImagePositionLeft: "0",
    side: "FRONT_ROOF",
    label: "Front Roof",
    area: "FRONT_ROOF",
    subArea: "FRONT_ROOF",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-back-roof",
    overlayImage: "roof/back_roof.svg",
    overlayImageSize: "80%",
    overlayImagePosition: "3%",
    overlayImagePositionLeft: "0",
    side: "BACK_ROOF",
    label: "Back Roof",
    area: "BACK_ROOF",
    subArea: "BACK_ROOF",
    skipBBOX: true,
    skipCount: false,
  },
];

export default exteriorinspectionmomentum;
