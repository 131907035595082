const truckPreInspection = (journey) => {
  if (journey === "TRUCK") {
    return [
      {
        // FRONT LEFT
        type: "Inspection",
        sub: "pre",
        step: "exterior-front-left",
        overlayImage: "truck/Truck_Front Left.svg",
        overlayImageSize: "94%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "0",
        side: "FRONT",
        area: "Front_Left",
        subArea: "Front_Left",
        label: "Front Left",
        skipBBOX: true,
        skipCount: false,
      },
      {
        // FRONT RIGHT
        type: "Inspection",
        sub: "pre",
        step: "exterior-front-right",
        overlayImage: "truck/Truck_Front Right.svg",
        overlayImageSize: "94%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "0",
        side: "FRONT",
        area: "Front_Right",
        subArea: "Front_Right",
        label: "Front Right",
        skipBBOX: true,
        skipCount: false,
      },
      {
        // LEFT MIDDLE
        type: "Inspection",
        sub: "pre",
        step: "exterior-left-middle",
        overlayImage: "truck/Truck_Left Middle.svg",
        overlayImageSize: "94%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "0",
        side: "EXTERIOR",
        area: "Left_Middle",
        subArea: "Left_Middle",
        label: "Left Middle",
        skipBBOX: true,
        skipCount: false,
      },
      {
        // LEFT REAR MIDDLE
        type: "Inspection",
        sub: "pre",
        step: "exterior-left-rear-middle",
        overlayImage: "truck/Truck_Left Rear Middle.svg",
        overlayImageSize: "94%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "0",
        side: "EXTERIOR",
        area: "Left_Rear_Middle",
        subArea: "Left_Rear_Middle",
        label: "Left Rear Middle",
        skipBBOX: true,
        skipCount: false,
      },
      {
        // LEFT REAR
        type: "Inspection",
        sub: "pre",
        step: "exterior-left-rear",
        overlayImage: "truck/Truck_Left Rear.svg",
        overlayImageSize: "94%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "0",
        side: "EXTERIOR",
        area: "Left_Rear",
        subArea: "Left_Rear",
        label: "Left Rear",
        skipBBOX: true,
        skipCount: false,
      },
      {
        // RIGHT MIDDLE
        type: "Inspection",
        sub: "pre",
        step: "exterior-right-middle",
        overlayImage: "truck/Truck_Right Middle.svg",
        overlayImageSize: "94%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "0",
        side: "EXTERIOR",
        area: "Right_Middle",
        subArea: "Right_Middle",
        label: "Right Middle",
        skipBBOX: true,
        skipCount: false,
      },
      {
        // RIGHT REAR MIDDLE
        type: "Inspection",
        sub: "pre",
        step: "exterior-right-rear-middle",
        overlayImage: "truck/Truck_Right Rear Middle.svg",
        overlayImageSize: "94%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "0",
        side: "EXTERIOR",
        area: "Right_Rear_Middle",
        subArea: "Right_Rear_Middle",
        label: "Right Rear Middle",
        skipBBOX: true,
        skipCount: false,
      },
      {
        // RIGHT REAR
        type: "Inspection",
        sub: "pre",
        step: "exterior-right-rear",
        overlayImage: "truck/Truck_Right Rear.svg",
        overlayImageSize: "94%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "0",
        side: "EXTERIOR",
        area: "Right_Rear",
        subArea: "Right_Rear",
        label: "Right Rear",
        skipBBOX: true,
        skipCount: false,
      },
      {
        // LEFT CABIN
        type: "Inspection",
        sub: "pre",
        step: "exterior-left-cabin",
        overlayImage: "truck/Truck_Left Cabin.svg",
        overlayImageSize: "94%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "0",
        side: "EXTERIOR",
        area: "Left_Cabin",
        subArea: "Left_Cabin",
        label: "Left Cabin",
        skipBBOX: true,
        skipCount: false,
      },
      {
        // RIGHT CABIN
        type: "Inspection",
        sub: "pre",
        step: "exterior-right-cabin",
        overlayImage: "truck/Truck_Right Cabin.svg",
        overlayImageSize: "94%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "0",
        side: "EXTERIOR",
        area: "Right_Cabin",
        subArea: "Right_Cabin",
        label: "Right Cabin",
        skipBBOX: true,
        skipCount: false,
      },
      {
        // BACK RIGHT
        type: "Inspection",
        sub: "pre",
        step: "exterior-back-right",
        overlayImage: "truck/Truck_Back Right.svg",
        overlayImageSize: "94%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "0",
        side: "REAR",
        area: "Back_Right",
        subArea: "Back_Right",
        label: "Back Right",
        skipBBOX: true,
        skipCount: false,
      },
      {
        // BACK LEFT
        type: "Inspection",
        sub: "pre",
        step: "exterior-back-left",
        overlayImage: "truck/Truck_Back Left.svg",
        overlayImageSize: "94%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "0",
        side: "REAR",
        area: "Back_Left",
        subArea: "Back_Left",
        label: "Back Left",
        skipBBOX: true,
        skipCount: false,
      },
      // { // TAILLIFT
      //   type: "Inspection",
      //   sub: "pre",
      //   step: "exterior-taillift",
      //   overlayImage: "truck/Truck_Front Right.svg",
      //   overlayImageSize: "94%",
      //   overlayImagePosition: "0%",
      //   overlayImagePositionLeft: "0",
      //   side: "Taillift",
      //   area: "Taillift",
      //   subArea: "Taillift",
      //   label: "Taillift",
      //   skipBBOX: true,
      //   skipCount: false,
      // },
      // { // Windshield
      //   type: "Inspection",
      //   sub: "pre",
      //   step: "exterior-winshield",
      //   overlayImage: "truck/Truck_Front Right.svg",
      //   overlayImageSize: "94%",
      //   overlayImagePosition: "0%",
      //   overlayImagePositionLeft: "0",
      //   side: "Windshield",
      //   area: "Windshield",
      //   subArea: "Windshield",
      //   label: "Windshield",
      //   skipBBOX: true,
      //   skipCount: false,
      // },
      // { // FUEL TANK
      //   type: "Inspection",
      //   sub: "pre",
      //   step: "exterior-fuel-tank",
      //   overlayImage: "truck/Truck_Front Right.svg",
      //   overlayImageSize: "94%",
      //   overlayImagePosition: "0%",
      //   overlayImagePositionLeft: "0",
      //   side: "Fuel_tank",
      //   area: "Fuel_tank",
      //   subArea: "Fuel_tank",
      //   label: "Fuel tank",
      //   skipBBOX: true,
      //   skipCount: false,
      // },
      // { // Battery
      //   type: "Inspection",
      //   sub: "pre",
      //   step: "exterior-battery",
      //   overlayImage: "truck/Truck_Front Right.svg",
      //   overlayImageSize: "94%",
      //   overlayImagePosition: "0%",
      //   overlayImagePositionLeft: "0",
      //   side: "Battery",
      //   area: "Battery",
      //   subArea: "Battery",
      //   label: "Battery",
      //   skipBBOX: true,
      //   skipCount: false,
      // },
      // { // Instrument cluster
      //   type: "Inspection",
      //   sub: "pre",
      //   step: "exterior-instrument-cluster",
      //   overlayImage: "truck/Truck_Front Right.svg",
      //   overlayImageSize: "94%",
      //   overlayImagePosition: "0%",
      //   overlayImagePositionLeft: "0",
      //   side: "Instrument_cluster",
      //   area: "Instrument_cluster",
      //   subArea: "Instrument_cluster",
      //   label: "Instrument cluster",
      //   skipBBOX: true,
      //   skipCount: false,
      // },
    ];
  }
};

export default truckPreInspection;
