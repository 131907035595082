import { DndProvider } from "react-dnd";
import { lazy, memo } from "react";

import { TouchBackend } from "react-dnd-touch-backend";
import { LinearProgress } from "@material-ui/core";

const MediaStream = lazy(() => import("../MediaStream"));
function MediaStreamWithDnD(props) {
  if (props.side) {
    return (
      <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
        <MediaStream {...props} />
      </DndProvider>
    );
  } else {
    return <LinearProgress />;
  }
}

MediaStreamWithDnD.defaultProps = {
  nextAction: () => {},
  side: "",
};

export default memo(MediaStreamWithDnD);
