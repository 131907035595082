import React from 'react'
import Input from '../Elements/Input';

const Element = (props) => {
    const handleChange = (field_id,field_type,event) => {
        props.handleChange(field_id,field_type,event)
    }

    switch (props.data_type) {
        case 'STRING':
            return (<Input
                field_id={props.question_id}
                field_label={props.question}
                error={props.error}
                field_placeholder={props.place_holder_label}
                field_value={props.question_value}
                field_type="text"
                handleChange={handleChange}
            />)

        case 'INTEGER':
            return (<Input
                field_id={props.question_id}
                field_label={props.question}
                error={props.error}
                field_placeholder={props.place_holder_label}
                field_value={props.question_value}
                field_type="number"
                handleChange={handleChange}
            />)

        case 'EMAIL':
            return (<Input
                field_id={props.question_id}
                field_label={props.question}
                error={props.error}
                field_placeholder={props.place_holder_label}
                field_value={props.question_value}
                field_type="email"
                handleChange={handleChange}
            />)
        default:
            return (<Input
                field_id={props.question_id}
                field_label={props.question}
                error={props.error}
                field_placeholder={props.place_holder_label}
                field_value={props.question_value}
                field_type="text"
                handleChange={handleChange}
            />)
    }
}

export default Element
