import React from "react";
import { Box } from "@material-ui/core";
import "./loading.scss";

const LoadingComponent = () => {
  return (
    <Box p={0} className="loading-background">
      <Box
        style={{
          textAlign: "center",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          alt="logo"
          src="/images/Icons/Carscan_logo_white.png"
          width="100px"
          className="loading-animation"
        />
        <div className="spinner"></div>
      </Box>
    </Box>
  );
};

export default LoadingComponent;
