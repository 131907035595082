const exteriorPreInspectionForAlphaDirect = [
  // Exterior
  // // Front
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front",
    overlayImage: "car_exterior/Frontside.svg",
    overlayImageSize: "140%",
    overlayImagePosition: "-15%",
    overlayImagePositionLeft: "3%",
    side: "FRONT",
    label: "Front",
    area: "FRONT",
    subArea: "FRONT",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/frontnext.svg",
  }, //18
  //windshield
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-windshield",
    overlayImage: "exterior/v3/windshield/wind_shield_front.svg",
    overlayImageSize: "170%",
    overlayImagePosition: "-60%",
    overlayImagePositionLeft: "0",
    side: "FRONT_WINDSHIELD",
    label: "Front Wind Shield",
    area: "FRONT_WINDSHIELD",
    subArea: "FRONT_WINDSHIELD",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-windshield-front-right",
    overlayImage: "exterior/v3/windshield/wind_shield_front_right.svg",
    overlayImageSize: "230%",
    overlayImagePosition: "-120%",
    overlayImagePositionLeft: "25%",
    side: "FRONT_WINDSHIELD_RIGHT",
    label: "Front Wind Shield Front Right",
    area: "FRONT_WINDSHIELD_FRONT_RIGHT",
    subArea: "FRONT_WINDSHIELD_FRONT_RIGHT",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-windshield-front-left",
    overlayImage: "exterior/v3/windshield/wind_shield_front_left.svg",
    overlayImageSize: "230%",
    overlayImagePosition: "-120%",
    overlayImagePositionLeft: "20%",
    side: "FRONT_WINDSHIELD_LEFT",
    label: "Front Wind Shield Front Left",
    area: "FRONT_WINDSHIELD_FRONT_LEFT",
    subArea: "FRONT_WINDSHIELD_FRONT_LEFT",
    skipBBOX: true,
    skipCount: false,
  },

  // // Front Passenger Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-passenger-side",
    overlayImage: "car_exterior/Passengersidefront_front.svg",
    overlayImageSize: "125%",
    overlayImagePosition: "-8%",
    overlayImagePositionLeft: "0%",
    side: "PASSENGER_SIDE",
    label: "Front Passenger Side",
    area: "FRONT_PASSENGER_SIDE",
    subArea: "FRONT_PASSENGER_SIDE",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/frontdrivernext.svg",
  },
  // // Passenger Side front half
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-passenger-side-front-half",
    overlayImage: "car_exterior/Passengerside_front.svg",
    overlayImageSize: "135%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "0",
    side: "PASSENGER_SIDE_FRONT_HALF",
    label: "Passenger Side Front Half",
    area: "PASSENGER_SIDE_FRONT_HALF",
    subArea: "PASSENGER_SIDE_FRONT_HALF",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/frontdriverhalfnext.svg",
  },
  // // Passenger Side rear half
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-passenger-side-rear-half",
    overlayImage: "car_exterior/Passengerside_rear.svg",
    overlayImageSize: "135%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "PASSENGER_SIDE_REAR_HALF",
    label: "Passenger Side Rear Half",
    area: "PASSENGER_SIDE_REAR_HALF",
    subArea: "PASSENGER_SIDE_REAR_HALF",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/reardriverhalfnext.svg",
  },
  // // Rear Passenger Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-rear-passenger-side",
    overlayImage: "car_exterior/Passengerrearside_rear.svg",
    overlayImageSize: "140%",
    overlayImagePosition: "-10%",
    overlayImagePositionLeft: "5%",
    side: "PASSENGER_SIDE",
    label: "Rear Passenger Side",
    area: "REAR_PASSENGER_SIDE",
    subArea: "REAR_PASSENGER_SIDE",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/reardrivernext.svg",
  },
  // // Rear Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-rear",
    overlayImage: "car_exterior/Rearside.svg",
    overlayImageSize: "150%",
    overlayImagePosition: "-20%",
    overlayImagePositionLeft: "3%",
    side: "REAR_SIDE",
    label: "Rear Side",
    area: "REAR",
    subArea: "REAR",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/rearnext.svg",
  },
  // // Rear Driver Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-rear-driver-side",
    overlayImage: "car_exterior/Driverrearside_rear.svg",
    overlayImageSize: "140%",
    overlayImagePosition: "-10%",
    overlayImagePositionLeft: "3%",
    side: "DRIVER_SIDE",
    label: "Rear Driver Side",
    area: "REAR_DRIVER_SIDE",
    subArea: "REAR_DRIVER_SIDE",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/rearpassengernext.svg",
  },
  // // Driver Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-driver-side-rear-half",
    overlayImage: "car_exterior/Driverside_rear.svg",
    overlayImageSize: "140%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "DRIVER_SIDE_FRONT_HALF",
    label: "Driver Side Rear Half",
    area: "DRIVER_SIDE_REAR_HALF",
    subArea: "DRIVER_SIDE_REAR_HALF",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/rearpassengerhalfnext.svg",
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-driver-side-front-half",
    overlayImage: "car_exterior/Driverside_front.svg",
    overlayImageSize: "140%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "3%",
    side: "DRIVER_SIDE_FRONT_HALF",
    label: "Driver Side Front Half",
    area: "DRIVER_SIDE_FRONT_HALF",
    subArea: "DRIVER_SIDE_FRONT_HALF",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/frontpassengerhalfnext.svg",
  },
  // // Front Driver Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-driver-side",
    overlayImage: "car_exterior/Driverfrontside_front.svg",
    overlayImageSize: "125%",
    overlayImagePosition: "-5%",
    overlayImagePositionLeft: "0",
    side: "FRONT_DRIVER_SIDE",
    label: "Front Driver Side",
    area: "FRONT_DRIVER_SIDE",
    subArea: "FRONT_DRIVER_SIDE",
    skipBBOX: true,
    skipCount: false,
    upNext: "/images/upnext/frontpassengernext.svg",
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "interior-roof",
    overlayImage: "interior/Dashboard.png",
    overlayImageSize: "90%",
    overlayImagePosition: "-2px",
    overlayImagePositionLeft: "2%",
    side: "INTERIOR_ROOF",
    label: "Roof",
    area: "INTERIOR",
    subArea: "INTERIOR_ROOF",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-roof",
    overlayImage: "roof/front_roof.svg",
    overlayImageSize: "80%",
    overlayImagePosition: "3%",
    overlayImagePositionLeft: "0",
    side: "FRONT_ROOF",
    label: "Front Roof",
    area: "FRONT_ROOF",
    subArea: "FRONT_ROOF",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-back-roof",
    overlayImage: "roof/back_roof.svg",
    overlayImageSize: "80%",
    overlayImagePosition: "3%",
    overlayImagePositionLeft: "0",
    side: "BACK_ROOF",
    label: "Back Roof",
    area: "BACK_ROOF",
    subArea: "BACK_ROOF",
    skipBBOX: true,
    skipCount: false,
  },
];

export default exteriorPreInspectionForAlphaDirect;
