import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Brightness1 } from "@material-ui/icons";
const ErrorMessage = (props) => {
  return (
    <Dialog open={props.errors.length} maxWidth="lg">
      <DialogTitle className="errorTitle">Error</DialogTitle>

      {/* <DialogTitle><Error Error!!</DialogTitle> */}
      <DialogContent style={{ overflow: "hidden", minWidth: "40vw" }}>
        <List>
          {props.errors.map((error, i) => (
            <ListItem key={i}>
              <ListItemIcon style={{ minWidth: "16px", visibility: "hidden" }}>
                <Brightness1 style={{ fontSize: ".625rem" }} />
              </ListItemIcon>
              <ListItemText>{error}</ListItemText>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "16px",
          borderTop: "solid 1px rgb(0,0,0,.1)",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.onClick([])}
          className="errorButton"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ErrorMessage.defaultProps = {
  errors: [],
};

export default ErrorMessage;
