
const exteriorPreInspectionLeftHandDrive = () => {
  return [
      // front driver side
      {
          type: "Inspection",
          sub: "pre",
          step: "exterior-front-left-side-left-hand-drive",
          overlayImage: "car_exterior/Passengersidefront_front.svg",
          overlayImageSize: "95%",
          overlayImagePosition: "-0%",
          overlayImagePositionLeft: "0%",
          side: "EXTERIOR_FRONT_LEFT_SIDE_LEFT_HAND_DRIVE",
          label: "Front Left Side",
          area: "FRONT_LEFT_SIDE",
          subArea: "FRONT_LEFT_SIDE",
          skipBBOX: true,
          skipCount: false,
          upNext: "/images/upnext/frontdrivernext.svg",
        },
        // Driver side front half
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-left-side-front-half-left-hand-drive",
          overlayImage: "car_exterior/Passengerside_front.svg",
          overlayImageSize: "100%",
          overlayImagePosition: "0%",
          overlayImagePositionLeft: "0",
          side: "EXTERIOR_LEFT_SIDE_FRONT_HALF_LEFT_HAND_DRIVE",
          label: "Left Side Front Half",
          area: "LEFT_SIDE_FRONT_HALF",
          subArea: "LEFT_SIDE_FRONT_HALF",
          skipBBOX: true,
          skipCount: false,
          upNext: "/images/upnext/frontdriverhalfnext.svg",
        },
        // Driver side rear half
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-left-side-rear-half-left-hand-drive",
          overlayImage: "car_exterior/Passengerside_rear.svg",
          overlayImageSize: "100%",
          overlayImagePosition: "0%",
          overlayImagePositionLeft: "5%",
          side: "EXTERIOR_LEFT_SIDE_REAR_HALF_LEFT_HAND_DRIVE",
          label: "Left Side Rear Half",
          area: "LEFT_SIDE_REAR_HALF",
          subArea: "LEFT_SIDE_REAR_HALF",
          skipBBOX: true,
          skipCount: false,
          upNext: "/images/upnext/reardriverhalfnext.svg",
        },
        // Rear driver side
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-rear-left-corner-left-hand-drive",
          overlayImage: "car_exterior/Passengerrearside_rear.svg",
          overlayImageSize: "105%",
          overlayImagePosition: "-5%",
          overlayImagePositionLeft: "5%",
          side: "EXTERIOR_REAR_LEFT_CORNER_LEFT_HAND_DRIVE",
          label: "Rear Left Side",
          area: "REAR_LEFT_SIDE",
          subArea: "REAR_LEFT_SIDE",
          skipBBOX: true,
          skipCount: false,
          upNext: "/images/upnext/reardrivernext.svg",
        },
        // Rear Passenger side
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-rear-right-side-left-hand-drive",
          overlayImage: "car_exterior/Driverrearside_rear.svg",
          overlayImageSize: "105%",
          overlayImagePosition: "-5%",
          overlayImagePositionLeft: "3%",
          side: "EXTERIOR_REAR_RIGHT_SIDE_LEFT_HAND_DRIVE",
          label: "Rear Right Side",
          area: "REAR_RIGHT_SIDE",
          subArea: "REAR_RIGHT_SIDE",
          skipBBOX: true,
          skipCount: false,
          upNext: "/images/upnext/rearpassengernext.svg",
        },
        //passenger side rear half
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-right-side-rear-half-left-hand-drive",
          overlayImage: "car_exterior/Driverside_rear.svg",
          overlayImageSize: "100%",
          overlayImagePosition: "0%",
          overlayImagePositionLeft: "5%",
          side: "EXTERIOR_RIGHT_SIDE_REAR_HALF_LEFT_HAND_DRIVE",
          label: "Right Side Rear Half",
          area: "RIGHT_SIDE_REAR_HALF",
          subArea: "RIGHT_SIDE_REAR_HALF",
          skipBBOX: true,
          skipCount: false,
          upNext: "/images/upnext/rearpassengerhalfnext.svg",
        },
        // passenger side front half
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-right-side-front-half-left-hand-drive",
          overlayImage: "car_exterior/Driverside_front.svg",
          overlayImageSize: "105%",
          overlayImagePosition: "0%",
          overlayImagePositionLeft: "3%",
          side: "EXTERIOR_RIGHT_SIDE_FRONT_HALF_LEFT_HAND_DRIVE",
          label: "Right Side Front Half",
          area: "RIGHT_SIDE_FRONT_HALF",
          subArea: "RIGHT_SIDE_FRONT_HALF",
          skipBBOX: true,
          skipCount: false,
          upNext: "/images/upnext/frontpassengerhalfnext.svg",
        },
        //Passenger side front
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-front-right-side-left-hand-drive",
          overlayImage: "car_exterior/Driverfrontside_front.svg",
          overlayImageSize: "95%",
          overlayImagePosition: "-0%",
          overlayImagePositionLeft: "0",
          side: "EXTERIOR_FRONT_RIGHT_SIDE_LEFT_HAND_DRIVE",
          label: "Front Right Side",
          area: "FRONT_RIGHT_SIDE",
          subArea: "FRONT_RIGHT_SIDE",
          skipBBOX: true,
          skipCount: false,
          upNext: "/images/upnext/frontpassengernext.svg",
        },
        // PORTRAIT JOURNEY OVERLAYS
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-front-left-side-portrait-left-hand-drive",
          overlayImage: "car_exterior/portrait/passenger_side_front.svg",
          overlayImageSize: "30%",
          overlayImagePosition: "40%",
          overlayImagePositionLeft: "4%",
          side: "EXTERIOR_FRONT_LEFT_SIDE_PORTRAIT_LEFT_HAND_DRIVE",
          label: "Left side front half ",
          area: "FRONT_LEFT_SIDE_PORTRAIT",
          subArea: "FRONT_LEFT_SIDE_PORTRAIT",
          skipBBOX: true,
          skipCount: false,
        },
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-front-left-side-front-door-left-hand-drive",
          overlayImage: "car_exterior/portrait/passenger_side_front_door.svg",
          overlayImageSize: "45%",
          overlayImagePosition: "40%",
          overlayImagePositionLeft: "4%",
          side: "EXTERIOR_FRONT_LEFT_SIDE_FRONT_DOOR_LEFT_HAND_DRIVE",
          label: "Left Side Front Door",
          area: "FRONT_LEFT_SIDE_FRONT_DOOR",
          subArea: "FRONT_LEFT_SIDE_FRONT_DOOR",
          skipBBOX: true,
          skipCount: false,
        },
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-front-left-side-rear-door-left-hand-drive",
          overlayImage: "car_exterior/portrait/passenger_side_rear_door.svg",
          overlayImageSize: "50%",
          overlayImagePosition: "30%",
          overlayImagePositionLeft: "4%",
          side: "EXTERIOR_FRONT_LEFT_SIDE_REAR_DOOR_LEFT_HAND_DRIVE",
          label: "Left Side Rear Door",
          area: "FRONT_LEFT_SIDE_REAR_DOOR",
          subArea: "FRONT_LEFT_SIDE_REAR_DOOR",
          skipBBOX: true,
          skipCount: false,
        },
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-left-side-rear-half-portrait-left-hand-drive",
          overlayImage: "car_exterior/portrait/passenger_side_rear.svg",
          overlayImageSize: "40%",
          overlayImagePosition: "40%",
          overlayImagePositionLeft: "4%",
          side: "EXTERIOR_LEFT_SIDE_REAR_HALF_PORTRAIT_LEFT_HAND_DRIVE",
          label: "Left Side Rear Half",
          area: "LEFT_SIDE_REAR_HALF_PORTRAIT",
          subArea: "LEFT_SIDE_REAR_HALF_PORTRAIT",
          skipBBOX: true,
          skipCount: false,
        },
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-right-side-rear-half-portrait-left-hand-drive",
          overlayImage: "car_exterior/portrait/driver_side_rear_half.svg",
          overlayImageSize: "40%",
          overlayImagePosition: "40%",
          overlayImagePositionLeft: "4%",
          side: "EXTERIOR_RIGHT_SIDE_REAR_HALF_PORTRAIT_LEFT_HAND_DRIVE",
          label: "Right side rear half",
          area: "RIGHT_SIDE_REAR_HALF_PORTRAIT",
          subArea: "RIGHT_SIDE_REAR_HALF_PORTRAIT",
          skipBBOX: true,
          skipCount: false,
        },
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-right-side-rear-door-left-hand-drive",
          overlayImage: "car_exterior/portrait/driver_side_rear_door.svg",
          overlayImageSize: "40%",
          overlayImagePosition: "40%",
          overlayImagePositionLeft: "4%",
          side: "EXTERIOR_RIGHT_SIDE_REAR_DOOR_LEFT_HAND_DRIVE",
          label: "Right side rear door",
          area: "RIGHT_SIDE_REAR_DOOR",
          subArea: "RIGHT_SIDE_REAR_DOOR",
          skipBBOX: true,
          skipCount: false,
        },
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-right-side-front-door-left-hand-drive",
          overlayImage: "car_exterior/portrait/driver_side_front_door.svg",
          overlayImageSize: "40%",
          overlayImagePosition: "40%",
          overlayImagePositionLeft: "4%",
          side: "EXTERIOR_RIGHT_SIDE_FRONT_DOOR_LEFT_HAND_DRIVE",
          label: "Right side front door",
          area: "RIGHT_SIDE_FRONT_DOOR",
          subArea: "RIGHT_SIDE_FRONT_DOOR",
          skipBBOX: true,
          skipCount: false,
        },
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-right-side-front-half-portrait-left-hand-drive",
          overlayImage: "car_exterior/portrait/driver_side_front_half.svg",
          overlayImageSize: "30%",
          overlayImagePosition: "40%",
          overlayImagePositionLeft: "4%",
          side: "EXTERIOR_RIGHT_SIDE_FRONT_HALF_PORTRAIT_LEFT_HAND_DRIVE",
          label: "Right side front half",
          area: "RIGHT_SIDE_FRONT_HALF_PORTRAIT",
          subArea: "RIGHT_SIDE_FRONT_HALF_PORTRAIT",
          skipBBOX: true,
          skipCount: false,
        },
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-front-left-hand-drive",
          overlayImage: "car_exterior/Frontside.svg",
          overlayImageSize: "105%",
          overlayImagePosition: "-5%",
          overlayImagePositionLeft: "3%",
          side: "EXTERIOR_FRONT_LEFT_HAND_DRIVE",
          label: "Front",
          area: "FRONT",
          subArea: "FRONT",
          skipBBOX: true,
          skipCount: false,
          upNext: "/images/upnext/frontnext.svg",
        },
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-rear-left-hand-drive",
          overlayImage: "car_exterior/Rearside.svg",
          overlayImageSize: "110%",
          overlayImagePosition: "-10%",
          overlayImagePositionLeft: "3%",
          side: "EXTERIOR_REAR_LEFT_HAND_DRIVE",
          label: "Rear Side",
          area: "REAR",
          subArea: "REAR",
          skipBBOX: true,
          skipCount: false,
          upNext: "/images/upnext/rearnext.svg",
        },
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-front-right-left-hand-drive",
          overlayImage: "car_exterior/portrait/Frontside_left.svg",
          overlayImageSize: "65%",
          overlayImagePosition: "25%",
          overlayImagePositionLeft: "-7%",
          side: "EXTERIOR_FRONT_RIGHT_LEFT_HAND_DRIVE",
          label: "Front Right",
          area: "FRONT_RIGHT",
          subArea: "FRONT_RIGHT",
          skipBBOX: true,
          skipCount: false,
        },
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-front-left-left-hand-drive",
          overlayImage: "car_exterior/portrait/Frontside_right.svg",
          overlayImageSize: "65%",
          overlayImagePosition: "27%",
          overlayImagePositionLeft: "10%",
          side: "EXTERIOR_FRONT_LEFT_LEFT_HAND_DRIVE",
          label: "Front Left",
          area: "FRONT_LEFT",
          subArea: "FRONT_LEFT",
          skipBBOX: true,
          skipCount: false,
        },
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-front-left-corner-left-hand-drive",
          overlayImage: "car_exterior/portrait/wind_shield_front_right.svg",
          overlayImageSize: "60%",
          overlayImagePosition: "25%",
          overlayImagePositionLeft: "4%",
          side: "EXTERIOR_FRONT_LEFT_CORNER_LEFT_HAND_DRIVE",
          label: "Front left corner",
          area: "FRONT_LEFT_CORNER",
          subArea: "FRONT_LEFT_CORNER",
          skipBBOX: true,
          skipCount: false,
        },
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-rear-left-left-hand-drive",
          overlayImage: "car_exterior/portrait/Rearside_left.svg",
          overlayImageSize: "70%",
          overlayImagePosition: "23%",
          overlayImagePositionLeft: "5%",
          side: "EXTERIOR_REAR_LEFT_LEFT_HAND_DRIVE",
          label: "Rear Left",
          area: "REAR_LEFT",
          subArea: "REAR_LEFT",
          skipBBOX: true,
          skipCount: false,
        },
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-rear-left-corner-portrait-left-hand-drive",
          overlayImage: "car_exterior/Passengerrearside_rear.svg",
          overlayImageSize: "60%",
          overlayImagePosition: "25%",
          overlayImagePositionLeft: "10%",
          side: "EXTERIOR_REAR_LEFT_CORNER_PORTRAIT_LEFT_HAND_DRIVE",
          label: "Rear left corner",
          area: "REAR_LEFT_CORNER",
          subArea: "REAR_LEFT_CORNER",
          skipBBOX: true,
          skipCount: false,
        },
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-rear-right-left-hand-drive",
          overlayImage: "car_exterior/portrait/Rearside_right.svg",
          overlayImageSize: "70%",
          overlayImagePosition: "22%",
          overlayImagePositionLeft: "5%",
          side: "EXTERIOR_REAR_RIGHT_LEFT_HAND_DRIVE",
          label: "Rear right",
          area: "REAR_RIGHT",
          subArea: "REAR_RIGHT",
          skipBBOX: true,
          skipCount: false,
        },
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-rear-right-corner-left-hand-drive",
          overlayImage: "car_exterior/Driverrearside_rear.svg",
          overlayImageSize: "50%",
          overlayImagePosition: "25%",
          overlayImagePositionLeft: "3%",
          side: "EXTERIOR_REAR_RIGHT_CORNER_LEFT_HAND_DRIVE",
          label: "Rear right corner",
          area: "REAR_RIGHT_CORNER",
          subArea: "REAR_RIGHT_CORNER",
          skipBBOX: true,
          skipCount: false,
        },
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-front-right-corner-left-hand-drive",
          overlayImage: "car_exterior/portrait/wind_shield_front_left.svg",
          overlayImageSize: "60%",
          overlayImagePosition: "25%",
          overlayImagePositionLeft: "4%",
          side: "EXTERIOR_FRONT_RIGHT_CORNER_LEFT_HAND_DRIVE",
          label: "Front right corner",
          area: "FRONT_RIGHT_CORNER",
          subArea: "FRONT_RIGHT_CORNER",
          skipBBOX: true,
          skipCount: false,
        },
        //WindShield
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-front-windshield-front-left-portrait-left-hand-drive",
          overlayImage: "exterior/v3/windshield/wind_shield_front.svg",
          overlayImageSize: "100%",
          overlayImagePosition: "0%",
          overlayImagePositionLeft: "50%",
          side: "EXTERIOR_FRONT_WINDSHIELD_FRONT_LEFT_PORTRAIT_LEFT_HAND_DRIVE",
          label: "Front Wind Shield Front Left",
          area: "FRONT_WINDSHIELD_FRONT_LEFT_PORTRAIT",
          subArea: "FRONT_WINDSHIELD_FRONT_LEFT_PORTRAIT",
          skipBBOX: true,
          skipCount: false,
        },
        {
          type: "Inspection",
          sub: "pre",
          step: "exterior-front-windshield-front-right-portrait-left-hand-drive",
          overlayImage: "exterior/v3/windshield/wind_shield_front.svg",
          overlayImageSize: "100%",
          overlayImagePosition: "0%",
          overlayImagePositionLeft: "-50%",
          side: "EXTERIOR_FRONT_WINDSHIELD_FRONT_RIGHT_PORTRAIT_LEFT_HAND_DRIVE",
          label: "Front Wind Shield Front Right",
          area: "FRONT_WINDSHIELD_FRONT_RIGHT_PORTRAIT",
          subArea: "FRONT_WINDSHIELD_FRONT_RIGHT_PORTRAIT",
          skipBBOX: true,
          skipCount: false,
        },
        //Roof
    {
      type: "Inspection",
      sub: "pre",
      step: "exterior-front-roof-left-portrait-left-hand-drive",
      overlayImage: "roof/front_roof.svg",
      overlayImageSize: "35%",
      overlayImagePosition: "45%",
      overlayImagePositionLeft: "40%",
      side: "EXTERIOR_FRONT_ROOF_LEFT_PORTRAIT_LEFT_HAND_DRIVE",
      label: "Front Left Roof",
      area: "FRONT_ROOF_LEFT_PORTRAIT",
      subArea: "FRONT_ROOF_LEFT_PORTRAIT",
      skipBBOX: true,
      skipCount: false,
    },
    {
      type: "Inspection",
      sub: "pre",
      step: "exterior-front-roof-right-portrait-left-hand-drive",
      overlayImage: "roof/front_roof.svg",
      overlayImageSize: "35%",
      overlayImagePosition: "45%",
      overlayImagePositionLeft: "-40%",
      side: "EXTERIOR_FRONT_ROOF_RIGHT_PORTRAIT_LEFT_HAND_DRIVE",
      label: "Front Right Roof",
      area: "FRONT_ROOF_RIGHT_PORTRAIT",
      subArea: "FRONT_ROOF_RIGHT_PORTRAIT",
      skipBBOX: true,
      skipCount: false,
    },
  ]

}

export default exteriorPreInspectionLeftHandDrive;