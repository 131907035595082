import React, { useEffect, useState } from "react";
import OrientationAnimation from "../OrientationAnimation";
import { useStyles } from "../../Journey/styles";
import { getFromStore, setToStore } from "../../Stores/utils";
import Box from "@material-ui/core/Box";
import Logo from "../Logos";
import { useParams } from "react-router-dom";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import LockStore from "../../Stores/LockStore";
import useSubject from "../../hooks/useSubject";
import "./styles.css";

const DeviceOrientationWrapper = ({
  children,
  info = false,
  isLandscape = true,
  isLoader = false,
  setHeight
}) => {
  const classes = useStyles();
  const [showRotation, setShowRotation] = useState(false);
  const [mydevice, setDevice] = useState(null);
  const locked = useSubject(LockStore);
  const storedlock = localStorage.getItem("locked")

  let { sub } = useParams();
  const companyData = getFromStore("CompanyStore");
  // it will return that which os is the user device
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    return "unknown";
  }
  useEffect(() => {
    scrollToTop()
    // getting the os
    const device = getMobileOperatingSystem();
    setDevice(device)
    // if its 90 , it on lanscape mode else its on potrait  and setting into a state show rotate screen or not
    if(device==="iOS"){
      if (Math.abs(window.orientation) !== 90 && !isLandscape) {
        setShowRotation(false);
      } else if (Math.abs(window.orientation) === 90 && !isLandscape) {
        setShowRotation(true);
      } else if (Math.abs(window.orientation) === 90 && isLandscape) {
        setShowRotation(false);
      } else {
        setShowRotation(true);
      }
    }else if(device==="Android"){
      if (window.screen.orientation.angle === 0 && !isLandscape) {
        setShowRotation(false);
      } else if ((window.screen.orientation.angle === 90 || window.screen.orientation.angle === 270) && !isLandscape) {
        setShowRotation(true);
      } else if ((window.screen.orientation.angle === 90|| window.screen.orientation.angle === 270 )&& isLandscape) {
        setShowRotation(false);
      } else {
        setShowRotation(true);
      }
    }else{
        if (window.innerHeight > window.innerWidth && !isLandscape) {
          setShowRotation(false);
        } else if (window.innerHeight < window.innerWidth  && !isLandscape) {
          setShowRotation(true);
        } else if (window.innerHeight < window.innerWidth && isLandscape) {
          setShowRotation(false);
        } else {
          setShowRotation(true);
        }
      }

  window.addEventListener("orientationchange", (e) => {
    // if the orientation is landscape the e.target.orientatinon will be 90
    let  orientation=""
    if (device === "iOS") {
      orientation = e.target.orientation === 0 ? "potrait" : "landscape";
    } else if(device === "Android"){
      orientation = e.target.screen.orientation.angle === 0 ? "potrait" : "landscape";
    }else{
      orientation = window.innerHeight > window.innerWidth ? "potrait" : "landscape";
    }
    if (isLandscape && locked === "locked") {
      turnOffOnFullscreen("enter");
    }
    else if (orientation === "potrait" && !isLandscape) {
      setShowRotation(false);
    } else if (orientation === "landscape" && !isLandscape) {
      setShowRotation(true);
    } else if (orientation === "landscape" && isLandscape) {
      setShowRotation(()=>false);
    } else {
      setShowRotation(true);
    }
  });
  }, []);

  const scrollToTop=()=>{
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }

  const turnOffOnFullscreen = (e) => {
    if (e === "exit") {
      window.screen.orientation.unlock();
      document.exitFullscreen();
      setToStore("isFullScreen","null");
      if(setHeight){
        setHeight(getFromStore("isFullScreen"));
      }
      LockStore.next("unlocked");
    } else {
      let de = document.documentElement;
      de.requestFullscreen();
      setToStore("isFullScreen","full");
      if(setHeight){
        setHeight(getFromStore("isFullScreen"));
      }
      window.screen.orientation.lock("landscape").then(() => {
        LockStore.next("locked");
      });
    }
  };
  return (
    <>
      {locked === "locked" && (
        <div style={{ zIndex: 99999 }}>
          <FullscreenExitIcon
            onClick={() => turnOffOnFullscreen("exit")}
            className="exitFullscreen"
          />
        </div>
      )}
      {locked === "unlocked" && (
        <div style={{ zIndex: 99999 }}>
          <FullscreenIcon
            onClick={() => turnOffOnFullscreen("enter")}
            className="exitFullscreen"
          />
        </div>
      )}
      {(locked === null && storedlock) && (
        <div style={{ zIndex: 99999 }}>
          <FullscreenIcon
            onClick={() => turnOffOnFullscreen("enter")}
            className="exitFullscreen"
          />
        </div>
      )}
      {showRotation && (
        <div style={{position:"absolute", zIndex: 99999 }}>
          <OrientationAnimation device={mydevice} isPortrait={!isLandscape} />
        </div>
      )}
      <>
        {isLoader && (
          <Box
            textAlign="center"
            pt={2}
            mb={2}
            className={classes.headingHeight}
          >
            <Logo
              type={sub}
              info={info}
              imageURL={companyData.logo_file_path}
              className={classes.headingHeight}
            ></Logo>
          </Box>
        )}
        {children}
      </>
    </>
  );
};

export default DeviceOrientationWrapper;
