import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useStyles } from "./style";
import InputMask from "react-input-mask";
import { Typography } from "@material-ui/core";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <InputMask
      {...other}
      mask="999999999"
      maskChar={null}
      getinputRef={inputRef}
      onChange={onChange}
    />
  );
}

/**
 * This component is for input elements of type number only.
 * Input mask is used to handle cross browser compatibility as <input type="number"/> is not supported in safari
 * @param {*} props
 * @returns
 */

const InputNumber = (props) => {
  const classes = useStyles();

  const handleChange = (event) => {
    props.handleChange(props.field_id, props.field_type, event);
  };

  return (
    <Grid item xs={12}>
      <TextField
        id={props.field_id}
        error={props.error}
        className={classes.textField}
        value={props.field_value}
        onChange={handleChange}
        placeholder={props.field_placeholder ? props.field_placeholder : ""}
        helperText={props.error}
        inputProps={{
          style: { textAlign: "center", fontSize: "1rem", fontWeight: "700" },
        }}
        fullWidth
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
      />
      <Typography variant="body1" className={classes.label}>
        {props.field_label}
      </Typography>
      {/* <InputLabel className={classes.inputLabel}>
        {props.field_label}
      </InputLabel> */}
    </Grid>
  );
};

export default InputNumber;
