import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#EDA03A",
  },
  headingHeight: {
    [theme.breakpoints.up("xs")]: {
      height: 48,
      minHeight: 48,
    },
    [theme.breakpoints.up("sm")]: {
      height: 48,
      minHeight: 48,
    },
    [theme.breakpoints.up("md")]: {
      height: 64,
      minHeight: 64,
    },
    [theme.breakpoints.up("lg")]: {
      height: 150,
      minHeight: 150,
    },
    [theme.breakpoints.up("xl")]: {
      height: 150,
      minHeight: 150,
    },
  },
}));
