import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import styles from "./interactive.module.css";
import Logo from "../../Logos";
import { getFromStore } from "../../../Stores/utils";
import { Dialog, DialogTitle, IconButton, withStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import cs from 'classnames';


const StyledDialog = withStyles({
  root: {
    zIndex: '99999 !important',
  }
})(Dialog);
const Interactive = ({device, isPortrait}) => {

  console.log("device", device)
  const companyDetails = getFromStore("CompanyStore");
  const [show, setShow] = React.useState(true);
  const [counter, setCounter] = React.useState(0);
  const [popup, setPopup] = React.useState(false);
  const logo = (
    <Logo
      type="orientation"
      imageURL={
        companyDetails
          ? companyDetails.logo_file_path
          : "/images/Icons/Carscan_logo_white.png"
      }
      className={styles.carscanlogo}
    />
  );

  React.useEffect(() => {
    let timer;
    timer = setInterval(() => {
      if (counter < 3) {
        setCounter((prev) => prev + 1);
      }
      else {
        clearInterval(timer);
      }
    }, 3000);
    if (counter === 2 || counter === 3) {
      setTimeout(() => {
        setShow(true);
      }, 1000)
    } else {
      setShow(false)
    }
    console.log(counter);
    return () => clearInterval(timer);
  }, [counter]);

  const clickForPopup = () => {
    setPopup(!popup);
  }
  return (
    <>
      <StyledDialog open={popup}>
        <div className={cs({[styles.popup_containerpotrait]: isPortrait}, {[styles.popup_container]: !isPortrait})}>
          <div className={styles.closeButton} onClick={clickForPopup}>
            <Close />
          </div>
          <div className={styles.heading}>Not able to rotate the phone?</div>
          <div className={styles.androidscreen}>
            <img className={styles.andriodimg} src="/images/Orientation/andriodScreens.svg" alt="Mobile Screens" />
          </div>
          <div className={styles.textcontent}>Ensure auto-rotate is enabled</div>
          <div className={styles.divider} ></div>
          <div className={styles.notifications}>
            <div className={styles.notifytext} ><b>1.Notification  </b> <span className={styles.arrow}>&#62;</span> Potrait / Auto rotate</div>
            <div className={styles.notifytext} ><b>2.Settings </b><span className={styles.arrow}>&#62;</span> Display <span className={styles.arrow}>&#62;</span> Auto rotate</div>
          </div>
        </div>
      </StyledDialog>
      {counter === 0 && (
        <Box className={styles.backgroundOrientation1}>
          <div className={styles.contentWrapper1}>
            <div className={styles.reloadiv}>
              <img
                className={styles.reloadImage}
                src="/images/Orientation/reloadO.svg"
                alt="Reloading"
              />
            </div>
            {logo}
          </div>{" "}
        </Box>
      )}

      {counter === 1 && (
        <Box className={styles.backgroundOrientation2}>
          <div className={styles.contentWrapper1}>
            <div className={styles.text}>
              Please rotate <span className={styles.notbold}>the phone to {" "}
                {isPortrait ? "Portrait" : "Landscape"} Mode</span>
            </div>
            {logo}
          </div>
        </Box>
      )}

      {counter === 2 && (
        <Box className={styles.backgroundOrientation2}>
          <div className={styles.contentWrapper1}>
            {show && <img
              className={styles.phonerotate}
              src="/images/Orientation/phonerotate.svg"
              alt="Phone Rotate"
            />}
            <div className={show ? styles.text2 : styles.text}>
              Please rotate <br />
              <span className={styles.notbold}>the phone to {" "}
                {isPortrait ? "Portrait" : "Landscape"} Mode</span>
            </div>
            {logo}
            <img className={cs(styles.exclamatoryIcon, {[styles.displayNone]: isPortrait})} onClick={clickForPopup} src="/images/Orientation/Exclamationicon.png" alt="logo" />
          </div>
        </Box>
      )}

      {counter === 3 && (
        <Box className={styles.backgroundOrientation3}>
          <div className={styles.contentWrapper1}>
            {show && <img
              className={styles.phonerotate}
              src="/images/Orientation/phonerotate2.svg"
              alt="Phone Rotate"
            />}
            <div className={show ? styles.text2 : styles.text}>
              Please rotate <br />
              <span className={styles.notbold}>the phone to {" "}
                {isPortrait ? "Portrait" : "Landscape"} Mode</span>
            </div>
            {logo}
            <img className={cs(styles.exclamatoryIcon, {[styles.displayNone]: isPortrait})} onClick={clickForPopup} src="/images/Orientation/Exclamationicon.png" alt="logo" />
          </div>
        </Box>
      )}
      {isPortrait && counter > 1 && device!=="unknown" && (
        <img className={styles.exclamatoryIcon2} onClick={clickForPopup} src="/images/Orientation/Exclamationicon.png" alt="logo" />
      )}
    </>
  );
};

export default Interactive;