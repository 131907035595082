import React from "react";
import { useState, useEffect } from "react";
import Element from "./Element";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import AssessmentTypeStore$ from "../../Stores/AssessmentTypeStore";
import useSubject from "../../hooks/useSubject";
import { questionStorePublisher } from "../../Stores/QuestionStore";
import { useStyles } from "./style";
import RoundedButton from "../RoundedButton";
const Questionnaire = (props) => {
  const { nextAction } = props;
  const classes = useStyles();
  const [formElements, setFormElements] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const assessmentType = useSubject(AssessmentTypeStore$);

  useEffect(() => {
    let time = setTimeout(() => {
      if (assessmentType) {
        setFormElements(assessmentType.questions);
      } else {
        if (nextAction) {
          nextAction();
        }
      }
    }, 100);

    return () => {
      clearTimeout(time);
    };
  }, [assessmentType, nextAction]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let question_array = [];
    formElements.forEach((elem) => {
      let obj = {
        answers: elem.question_value,
        question_index: elem.question_id,
      };
      question_array.push(obj);
    });
    questionStorePublisher({ question_value: question_array });
    if (props.nextAction) {
      props.nextAction();
    }
  };

  const handleChange = (id, type, event) => {
    const newElements = [...formElements];
    newElements.forEach((element) => {
      const { data_type, question_id } = element;
      if (id === question_id) {
        if (
          data_type === "STRING" ||
          data_type === "EMAIL" ||
          data_type === "INTEGER"
        ) {
          element["question_value"] = event.target.value;
        }

        switch (type) {
          case "number": {
            let regExp = new RegExp("^\\d+$");
            let isValid =
              regExp.test(element["question_value"]) &&
              +element["question_value"] >= 0;
            if (element["question_value"] === "") {
              element["field_error_message"] = "*This is mandatory field";
              setIsFormValid(false);
              return;
            } else if (!isValid) {
              element["field_error_message"] = "Enter valid number";
              setIsFormValid(false);
              return;
            } else {
              element["field_error_message"] = null;
              break;
            }
          }
          case "text": {
            if (element["question_value"] === "") {
              element["field_error_message"] = "*This is mandatory field";
              setIsFormValid(false);
              return;
            } else {
              element["field_error_message"] = null;
              break;
            }
          }
          case "email": {
            let regExp = new RegExp(
              "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
            );
            let isValid = regExp.test(event.target.value);
            if (element["question_value"] === "") {
              element["field_error_message"] = "*This is mandatory field";
              setIsFormValid(false);
              return;
            } else if (!isValid) {
              element["field_error_message"] = "Enter valid e-mail address";
              setIsFormValid(false);
              return;
            } else {
              element["field_error_message"] = null;
              break;
            }
          }
          default:
            break;
        }
      }
    });
    let formValid = true;
    newElements.forEach((elem) => {
      if (elem["field_error_message"] !== null) {
        formValid = false;
      }
    });
    setIsFormValid(formValid);
    setFormElements(newElements);
  };

  return (
    <React.Fragment>
      <Box style={{ width: "50%", margin: "0 auto", marginTop: "4vw" }}>
        <Grid container justify="center" alignContent="center">
          <form>
            {formElements
              ? formElements.map((field, i) => (
                  <Element
                    key={i}
                    question_id={field.question_id}
                    data_type={field.data_type}
                    question={field.question}
                    place_holder_label={field.place_holder_label}
                    question_value={field.question_value}
                    option={field.option}
                    handleChange={handleChange}
                    error={field.field_error_message}
                  />
                ))
              : null}
            {/* <Button
              variant="contained"
              onClick={(e) => handleSubmit(e)}
              disabled={!isFormValid}
              className={classes.button}
              color="primary"
            >
              Submit
            </Button> */}
            <RoundedButton
              handleClick={(e) => handleSubmit(e)}
              disabled={!isFormValid}
              className={classes.button}
              label="Submit"
              color="primary"
              width="30vh"
              height="10vh"
            ></RoundedButton>
          </form>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

Questionnaire.defaultProps = {
  nextAction: () => {},
  prevAction: () => {},
};
export default Questionnaire;
