// const exteriorPreInspection = [
//   // Exterior
//   // // Front
//   {
//     type: "Inspection",
//     sub: "pre",
//     step: "exterior-front",
//     overlayImage: "exterior/FrontPanel.png",
//     overlayImageSize: "100%",
//     overlayImagePosition: "-5%",
//     overlayImagePositionLeft: "0",
//     side: "FRONT",
//     label: "Front",
//     area: "FRONT",
//     subArea: "FRONT",
//     skipBBOX: true,
//     skipCount:false
//   }, //18
//   // // Front Passenger Side
//   {
//     type: "Inspection",
//     sub: "pre",
//     step: "exterior-front-passenger-side",
//     overlayImage: "exterior/Front_PassengerSide.png",
//     overlayImageSize: "100%",
//     overlayImagePosition: "-8%",
//     overlayImagePositionLeft: "0",
//     side: "PASSENGER_SIDE",
//     label: "Front Passenger Side",
//     area: "FRONT_PASSENGER_SIDE",
//     subArea: "FRONT_PASSENGER_SIDE",
//     skipBBOX: true,
//     skipCount:false
//   },
//   // // Passenger Side front half
//   {
//     type: "Inspection",
//     sub: "pre",
//     step: "exterior-passenger-side-front-half",
//     overlayImage: "exterior/PassengerSideFrontHalf.png",
//     overlayImageSize: "90%",
//     overlayImagePosition: "0",
//     overlayImagePositionLeft: "0",
//     side: "PASSENGER_SIDE_FRONT_HALF",
//     label: "Passenger Side Front Half",
//     area: "PASSENGER_SIDE_FRONT_HALF",
//     subArea: "PASSENGER_SIDE_FRONT_HALF",
//     skipBBOX: true,
//     skipCount:false
//   },
//    // // Passenger Side rear half
//   {
//     type: "Inspection",
//     sub: "pre",
//     step: "exterior-passenger-side-rear-half",
//     overlayImage: "exterior/PassengerSideRearHalf.png",
//     overlayImageSize: "90%",
//     overlayImagePosition: "0px",
//     overlayImagePositionLeft: "0",
//     side: "PASSENGER_SIDE_REAR_HALF",
//     label: "Passenger Side Rear Half",
//     area: "PASSENGER_SIDE_REAR_HALF",
//     subArea: "PASSENGER_SIDE_REAR_HALF",
//     skipBBOX: true,
//     skipCount:false
//   },
//   // // Rear Passenger Side
//   {
//     type: "Inspection",
//     sub: "pre",
//     step: "exterior-rear-passenger-side",
//     overlayImage: "exterior/Rear_PassengerSide.png",
//     overlayImageSize: "90%",
//     overlayImagePosition: "0px",
//     overlayImagePositionLeft: "0%",
//     side: "PASSENGER_SIDE",
//     label: "Rear Passenger Side",
//     area: "REAR_PASSENGER_SIDE",
//     subArea: "REAR_PASSENGER_SIDE",
//     skipBBOX: true,
//     skipCount:false
//   },
//   // // Rear Side
//   {
//     type: "Inspection",
//     sub: "pre",
//     step: "exterior-rear",
//     overlayImage: "exterior/RearPanel.png",
//     overlayImageSize: "97%",
//     overlayImagePosition: "-5%",
//     overlayImagePositionLeft: "0",
//     side: "REAR_SIDE",
//     label: "Rear Side",
//     area: "REAR",
//     subArea: "REAR",
//     skipBBOX: true,
//     skipCount:false
//   },
//   // // Rear Driver Side
//   {
//     type: "Inspection",
//     sub: "pre",
//     step: "exterior-rear-driver-side",
//     overlayImage: "exterior/Rear_DriverSide.png",
//     overlayImageSize: "90%",
//     overlayImagePosition: "-3%",
//     overlayImagePositionLeft: "0",
//     side: "DRIVER_SIDE",
//     label: "Rear Driver Side",
//     area: "REAR_DRIVER_SIDE",
//     subArea: "REAR_DRIVER_SIDE",
//     skipBBOX: true,
//     skipCount:false
//   },
//   // // Driver Side
//   {
//     type: "Inspection",
//     sub: "pre",
//     step: "exterior-driver-side-rear-half",
//     overlayImage: "exterior/DirverSideRearHalf.png",
//     overlayImageSize: "90%",
//     overlayImagePosition: "0",
//     overlayImagePositionLeft: "0",
//     side: "DRIVER_SIDE_FRONT_HALF",
//     label: "Driver Side Rear Half",
//     area: "DRIVER_SIDE_REAR_HALF",
//     subArea: "DRIVER_SIDE_REAR_HALF",
//     skipBBOX: true,
//     skipCount:false
//   },
//   {
//     type: "Inspection",
//     sub: "pre",
//     step: "exterior-driver-side-front-half",
//     overlayImage: "exterior/DirverSideFrontHalf.png",
//     overlayImageSize: "90%",
//     overlayImagePosition: "-5px",
//     overlayImagePositionLeft: "0",
//     side: "DRIVER_SIDE_FRONT_HALF",
//     label: "Driver Side Front Half",
//     area: "DRIVER_SIDE_FRONT_HALF",
//     subArea: "DRIVER_SIDE_FRONT_HALF",
//     skipBBOX: true,
//     skipCount:false
//   },
//   // // Front Driver Side
//   {
//     type: "Inspection",
//     sub: "pre",
//     step: "exterior-front-driver-side",
//     overlayImage: "exterior/Front_DriverSide.png",
//     overlayImageSize: "90%",
//     overlayImagePosition: "0px",
//     overlayImagePositionLeft: "0%",
//     side: "FRONT_DRIVER_SIDE",
//     label: "Front Driver Side",
//     area: "FRONT_DRIVER_SIDE",
//     subArea: "FRONT_DRIVER_SIDE",
//     skipBBOX: true,
//     skipCount:false
//   }
// ];

// export default exteriorPreInspection;

const exteriorPreInspection = (journey) => {
  // if (journey === "V4") {
  //   return [
  //     // Exterior
  //     // // Front
  //     {
  //       type: "Inspection",
  //       sub: "pre",
  //       step: "exterior-front",
  //       overlayImage: "exterior/v4/FrontPanel.png",
  //       overlayImageSize: "93%",
  //       overlayImagePosition: "0%",
  //       overlayImagePositionLeft: "0",
  //       side: "FRONT",
  //       label: "Front",
  //       area: "FRONT",
  //       subArea: "FRONT",
  //       skipBBOX: true,
  //       skipCount: false,
  //     }, //18
  //     // // Front Passenger Side
  //     {
  //       type: "Inspection",
  //       sub: "pre",
  //       step: "exterior-front-passenger-side",
  //       overlayImage: "exterior/v4/Front_PassengerSide.png",
  //       overlayImageSize: "90%",
  //       overlayImagePosition: "0%",
  //       overlayImagePositionLeft: "0",
  //       side: "PASSENGER_SIDE",
  //       label: "Front Passenger Side",
  //       area: "FRONT_PASSENGER_SIDE",
  //       subArea: "FRONT_PASSENGER_SIDE",
  //       skipBBOX: true,
  //       skipCount: false,
  //     },
  //     // // Passenger Side front half
  //     {
  //       type: "Inspection",
  //       sub: "pre",
  //       step: "exterior-passenger-side-front-half",
  //       overlayImage: "exterior/v4/PassengerSideFrontHalf.png",
  //       overlayImageSize: "90%",
  //       overlayImagePosition: "0",
  //       overlayImagePositionLeft: "0",
  //       side: "PASSENGER_SIDE_FRONT_HALF",
  //       label: "Passenger Side Front Half",
  //       area: "PASSENGER_SIDE_FRONT_HALF",
  //       subArea: "PASSENGER_SIDE_FRONT_HALF",
  //       skipBBOX: true,
  //       skipCount: false,
  //     },
  //     // // Passenger Side rear half
  //     {
  //       type: "Inspection",
  //       sub: "pre",
  //       step: "exterior-passenger-side-rear-half",
  //       overlayImage: "exterior/v4/PassengerSideRearHalf.png",
  //       overlayImageSize: "90%",
  //       overlayImagePosition: "0px",
  //       overlayImagePositionLeft: "0",
  //       side: "PASSENGER_SIDE_REAR_HALF",
  //       label: "Passenger Side Rear Half",
  //       area: "PASSENGER_SIDE_REAR_HALF",
  //       subArea: "PASSENGER_SIDE_REAR_HALF",
  //       skipBBOX: true,
  //       skipCount: false,
  //     },
  //     // // Rear Passenger Side
  //     {
  //       type: "Inspection",
  //       sub: "pre",
  //       step: "exterior-rear-passenger-side",
  //       overlayImage: "exterior/v4/Rear_PassengerSide.png",
  //       overlayImageSize: "89%",
  //       overlayImagePosition: "1px",
  //       overlayImagePositionLeft: "0%",
  //       side: "PASSENGER_SIDE",
  //       label: "Rear Passenger Side",
  //       area: "REAR_PASSENGER_SIDE",
  //       subArea: "REAR_PASSENGER_SIDE",
  //       skipBBOX: true,
  //       skipCount: false,
  //     },
  //     // // Rear Side
  //     {
  //       type: "Inspection",
  //       sub: "pre",
  //       step: "exterior-rear",
  //       overlayImage: "exterior/v4/RearPanel.png",
  //       overlayImageSize: "90%",
  //       overlayImagePosition: "0%",
  //       overlayImagePositionLeft: "0",
  //       side: "REAR_SIDE",
  //       label: "Rear Side",
  //       area: "REAR",
  //       subArea: "REAR",
  //       skipBBOX: true,
  //       skipCount: false,
  //     },
  //     // // Rear Driver Side
  //     {
  //       type: "Inspection",
  //       sub: "pre",
  //       step: "exterior-rear-driver-side",
  //       overlayImage: "exterior/v4/Rear_DriverSide.png",
  //       overlayImageSize: "90%",
  //       overlayImagePosition: "0%",
  //       overlayImagePositionLeft: "0",
  //       side: "DRIVER_SIDE",
  //       label: "Rear Driver Side",
  //       area: "REAR_DRIVER_SIDE",
  //       subArea: "REAR_DRIVER_SIDE",
  //       skipBBOX: true,
  //       skipCount: false,
  //     },
  //     // // Driver Side
  //     {
  //       type: "Inspection",
  //       sub: "pre",
  //       step: "exterior-driver-side-rear-half",
  //       overlayImage: "exterior/v4/DirverSideRearHalf.png",
  //       overlayImageSize: "90%",
  //       overlayImagePosition: "0%",
  //       overlayImagePositionLeft: "0",
  //       side: "DRIVER_SIDE_FRONT_HALF",
  //       label: "Driver Side Rear Half",
  //       area: "DRIVER_SIDE_REAR_HALF",
  //       subArea: "DRIVER_SIDE_REAR_HALF",
  //       skipBBOX: true,
  //       skipCount: false,
  //     },
  //     {
  //       type: "Inspection",
  //       sub: "pre",
  //       step: "exterior-driver-side-front-half",
  //       overlayImage: "exterior/v4/DirverSideFrontHalf.png",
  //       overlayImageSize: "90%",
  //       overlayImagePosition: "0px",
  //       overlayImagePositionLeft: "0",
  //       side: "DRIVER_SIDE_FRONT_HALF",
  //       label: "Driver Side Front Half",
  //       area: "DRIVER_SIDE_FRONT_HALF",
  //       subArea: "DRIVER_SIDE_FRONT_HALF",
  //       skipBBOX: true,
  //       skipCount: false,
  //     },
  //     // // Front Driver Side
  //     {
  //       type: "Inspection",
  //       sub: "pre",
  //       step: "exterior-front-driver-side",
  //       overlayImage: "exterior/v4/Front_DriverSide.png",
  //       overlayImageSize: "90%",
  //       overlayImagePosition: "0px",
  //       overlayImagePositionLeft: "-30px",
  //       side: "FRONT_DRIVER_SIDE",
  //       label: "Front Driver Side",
  //       area: "FRONT_DRIVER_SIDE",
  //       subArea: "FRONT_DRIVER_SIDE",
  //       skipBBOX: true,
  //       skipCount: false,
  //     },
  //   ];
  // } 
  if (journey === "V3") {
    return [
      // Exterior
      // // Front
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-front",
        overlayImage: "car_exterior/Frontside.svg",
        overlayImageSize: "105%",
        overlayImagePosition: "-5%",
        overlayImagePositionLeft: "3%",
        side: "FRONT",
        label: "Front",
        area: "FRONT",
        subArea: "FRONT",
        skipBBOX: true,
        skipCount: false,
        upNext: "/images/upnext/frontnext.svg",
      }, //18
      //windshield
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-front-windshield",
        overlayImage: "exterior/v3/windshield/wind_shield_front.svg",
        overlayImageSize: "170%",
        overlayImagePosition: "-60%",
        overlayImagePositionLeft: "0",
        side: "FRONT_WINDSHIELD",
        label: "Front Wind Shield",
        area: "FRONT_WINDSHIELD",
        subArea: "FRONT_WINDSHIELD",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-front-windshield-front-right",
        overlayImage: "exterior/v3/windshield/wind_shield_front_right.svg",
        overlayImageSize: "230%",
        overlayImagePosition: "-120%",
        overlayImagePositionLeft: "25%",
        side: "FRONT_WINDSHIELD_RIGHT",
        label: "Front Wind Shield Front Right",
        area: "FRONT_WINDSHIELD_FRONT_RIGHT",
        subArea: "FRONT_WINDSHIELD_FRONT_RIGHT",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-front-windshield-front-left",
        overlayImage: "exterior/v3/windshield/wind_shield_front_left.svg",
        overlayImageSize: "230%",
        overlayImagePosition: "-120%",
        overlayImagePositionLeft: "20%",
        side: "FRONT_WINDSHIELD_LEFT",
        label: "Front Wind Shield Front Left",
        area: "FRONT_WINDSHIELD_FRONT_LEFT",
        subArea: "FRONT_WINDSHIELD_FRONT_LEFT",
        skipBBOX: true,
        skipCount: false,
      },
      // // Front Passenger Side
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-front-passenger-side",
        overlayImage: "car_exterior/Passengersidefront_front.svg",
        overlayImageSize: "95%",
        overlayImagePosition: "-0%",
        overlayImagePositionLeft: "0%",
        side: "PASSENGER_SIDE",
        label: "Front Passenger Side",
        area: "FRONT_PASSENGER_SIDE",
        subArea: "FRONT_PASSENGER_SIDE",
        skipBBOX: true,
        skipCount: false,
        upNext: "/images/upnext/frontdrivernext.svg",
      },
      // // Passenger Side front half
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-passenger-side-front-half",
        overlayImage: "car_exterior/Passengerside_front.svg",
        overlayImageSize: "100%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "0",
        side: "PASSENGER_SIDE_FRONT_HALF",
        label: "Passenger Side Front Half",
        area: "PASSENGER_SIDE_FRONT_HALF",
        subArea: "PASSENGER_SIDE_FRONT_HALF",
        skipBBOX: true,
        skipCount: false,
        upNext: "/images/upnext/frontdriverhalfnext.svg",
      },
      // // Passenger Side rear half
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-passenger-side-rear-half",
        overlayImage: "car_exterior/Passengerside_rear.svg",
        overlayImageSize: "100%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "5%",
        side: "PASSENGER_SIDE_REAR_HALF",
        label: "Passenger Side Rear Half",
        area: "PASSENGER_SIDE_REAR_HALF",
        subArea: "PASSENGER_SIDE_REAR_HALF",
        skipBBOX: true,
        skipCount: false,
        upNext: "/images/upnext/reardriverhalfnext.svg",
      },
      // // Rear Passenger Side
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-rear-passenger-side",
        overlayImage: "car_exterior/Passengerrearside_rear.svg",
        overlayImageSize: "105%",
        overlayImagePosition: "-5%",
        overlayImagePositionLeft: "5%",
        side: "PASSENGER_SIDE",
        label: "Rear Passenger Side",
        area: "REAR_PASSENGER_SIDE",
        subArea: "REAR_PASSENGER_SIDE",
        skipBBOX: true,
        skipCount: false,
        upNext: "/images/upnext/reardrivernext.svg",
      },
      // // Rear Side
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-rear",
        overlayImage: "car_exterior/Rearside.svg",
        overlayImageSize: "110%",
        overlayImagePosition: "-10%",
        overlayImagePositionLeft: "3%",
        side: "REAR_SIDE",
        label: "Rear Side",
        area: "REAR",
        subArea: "REAR",
        skipBBOX: true,
        skipCount: false,
        upNext: "/images/upnext/rearnext.svg",
      },
      // // Rear Driver Side
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-rear-driver-side",
        overlayImage: "car_exterior/Driverrearside_rear.svg",
        overlayImageSize: "105%",
        overlayImagePosition: "-5%",
        overlayImagePositionLeft: "3%",
        side: "DRIVER_SIDE",
        label: "Rear Driver Side",
        area: "REAR_DRIVER_SIDE",
        subArea: "REAR_DRIVER_SIDE",
        skipBBOX: true,
        skipCount: false,
        upNext: "/images/upnext/rearpassengernext.svg",
      },
      // // Driver Side
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-driver-side-rear-half",
        overlayImage: "car_exterior/Driverside_rear.svg",
        overlayImageSize: "100%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "5%",
        side: "DRIVER_SIDE_FRONT_HALF",
        label: "Driver Side Rear Half",
        area: "DRIVER_SIDE_REAR_HALF",
        subArea: "DRIVER_SIDE_REAR_HALF",
        skipBBOX: true,
        skipCount: false,
        upNext: "/images/upnext/rearpassengerhalfnext.svg",
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-driver-side-front-half",
        overlayImage: "car_exterior/Driverside_front.svg",
        overlayImageSize: "105%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "3%",
        side: "DRIVER_SIDE_FRONT_HALF",
        label: "Driver Side Front Half",
        area: "DRIVER_SIDE_FRONT_HALF",
        subArea: "DRIVER_SIDE_FRONT_HALF",
        skipBBOX: true,
        skipCount: false,
        upNext: "/images/upnext/frontpassengerhalfnext.svg",
      },
      // // Front Driver Side
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-front-driver-side",
        overlayImage: "car_exterior/Driverfrontside_front.svg",
        overlayImageSize: "95%",
        overlayImagePosition: "-0%",
        overlayImagePositionLeft: "0",
        side: "FRONT_DRIVER_SIDE",
        label: "Front Driver Side",
        area: "FRONT_DRIVER_SIDE",
        subArea: "FRONT_DRIVER_SIDE",
        skipBBOX: true,
        skipCount: false,
        upNext: "/images/upnext/frontpassengernext.svg",
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-front-roof",
        overlayImage: "roof/front_roof.svg",
        overlayImageSize: "80%",
        overlayImagePosition: "3%",
        overlayImagePositionLeft: "0",
        side: "FRONT_ROOF",
        label: "Front Roof",
        area: "FRONT_ROOF",
        subArea: "FRONT_ROOF",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-back-roof",
        overlayImage: "roof/back_roof.svg",
        overlayImageSize: "80%",
        overlayImagePosition: "3%",
        overlayImagePositionLeft: "0",
        side: "BACK_ROOF",
        label: "Back Roof",
        area: "BACK_ROOF",
        subArea: "BACK_ROOF",
        skipBBOX: true,
        skipCount: false,
      },

      //PORTRAIT JOURNEY OVERLAYS
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-front-right",
        overlayImage: "car_exterior/portrait/Frontside_left.svg",
        overlayImageSize: "70%",
        overlayImagePosition: "22%",
        overlayImagePositionLeft: "0%",
        side: "FRONT_RIGHT",
        label: "Front Right",
        area: "FRONT_RIGHT",
        subArea: "FRONT_RIGHT",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-front-left",
        overlayImage: "car_exterior/portrait/Frontside_right.svg",
        overlayImageSize: "70%",
        overlayImagePosition: "22%",
        overlayImagePositionLeft: "8%",
        side: "FRONT_LEFT",
        label: "Front Left",
        area: "FRONT_LEFT",
        subArea: "FRONT_LEFT",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-front-left-corner",
        overlayImage: "car_exterior/portrait/wind_shield_front_right.svg",
        overlayImageSize: "55%",
        overlayImagePosition: "30%",
        overlayImagePositionLeft: "-8%",
        side: "FRONT_LEFT_CORNER",
        label: "Front left corner",
        area: "FRONT_LEFT_CORNER",
        subArea: "FRONT_LEFT_CORNER",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-front-right-corner",
        overlayImage: "car_exterior/portrait/wind_shield_front_left.svg",
        overlayImageSize: "60%",
        overlayImagePosition: "25%",
        overlayImagePositionLeft: "4%",
        side: "FRONT_RIGHT_CORNER",
        label: "Front right corner",
        area: "FRONT_RIGHT_CORNER",
        subArea: "FRONT_RIGHT_CORNER",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-front-passenger-side-portrait",
        overlayImage: "car_exterior/portrait/passenger_side_front.svg",
        overlayImageSize: "30%",
        overlayImagePosition: "40%",
        overlayImagePositionLeft: "4%",
        side: "PASSENGER_SIDE",
        label: "Passenger side front half ",
        area: "FRONT_PASSENGER_SIDE_PORTRAIT",
        subArea: "FRONT_PASSENGER_SIDE_PORTRAIT",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-front-passenger-side-front-door",
        overlayImage: "car_exterior/portrait/passenger_side_front_door.svg",
        overlayImageSize: "45%",
        overlayImagePosition: "40%",
        overlayImagePositionLeft: "4%",
        side: "PASSENGER_SIDE_FRONT_DOOR",
        label: "Passenger Side Front Door",
        area: "FRONT_PASSENGER_SIDE_FRONT_DOOR",
        subArea: "FRONT_PASSENGER_SIDE_FRONT_DOOR",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-front-passenger-side-rear-door",
        overlayImage: "car_exterior/portrait/passenger_side_rear_door.svg",
        overlayImageSize: "50%",
        overlayImagePosition: "30%",
        overlayImagePositionLeft: "4%",
        side: "PASSENGER_SIDE_REAR_DOOR",
        label: "Passenger Side Rear Door",
        area: "FRONT_PASSENGER_SIDE_REAR_DOOR",
        subArea: "FRONT_PASSENGER_SIDE_REAR_DOOR",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-passenger-side-rear-half-portrait",
        overlayImage: "car_exterior/portrait/passenger_side_rear.svg",
        overlayImageSize: "40%",
        overlayImagePosition: "40%",
        overlayImagePositionLeft: "4%",
        side: "PASSENGER_SIDE_REAR_HALF_PORTRAIT",
        label: "Passenger Side Rear Half",
        area: "PASSENGER_SIDE_REAR_HALF_PORTRAIT",
        subArea: "PASSENGER_SIDE_REAR_HALF_PORTRAIT",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-rear-left-corner",
        overlayImage: "car_exterior/Passengerrearside_rear.svg",
        overlayImageSize: "60%",
        overlayImagePosition: "25%",
        overlayImagePositionLeft: "10%",
        side: "REAR_LEFT_CORNER",
        label: "Rear left corner",
        area: "REAR_LEFT_CORNER",
        subArea: "REAR_LEFT_CORNER",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-rear-left",
        overlayImage: "car_exterior/portrait/Rearside_left.svg",
        overlayImageSize: "70%",
        overlayImagePosition: "23%",
        overlayImagePositionLeft: "5%",
        side: "REAR_LEFT",
        label: "Rear Left",
        area: "REAR_LEFT",
        subArea: "REAR_LEFT",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-rear-right",
        overlayImage: "car_exterior/portrait/Rearside_right.svg",
        overlayImageSize: "70%",
        overlayImagePosition: "22%",
        overlayImagePositionLeft: "5%",
        side: "REAR_RIGHT",
        label: "Rear right",
        area: "REAR_RIGHT",
        subArea: "REAR_RIGHT",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-rear-right-corner",
        overlayImage: "car_exterior/Driverrearside_rear.svg",
        overlayImageSize: "60%",
        overlayImagePosition: "25%",
        overlayImagePositionLeft: "3%",
        side: "REAR_RIGHT_CORNER",
        label: "Rear right corner",
        area: "REAR_RIGHT_CORNER",
        subArea: "REAR_RIGHT_CORNER",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-driver-side-rear-half-portrait",
        overlayImage: "car_exterior/portrait/driver_side_rear_half.svg",
        overlayImageSize: "40%",
        overlayImagePosition: "40%",
        overlayImagePositionLeft: "4%",
        side: "DRIVER_SIDE_REAR_HALF_PORTRAIT",
        label: "Driver side rear half",
        area: "DRIVER_SIDE_REAR_HALF_PORTRAIT",
        subArea: "DRIVER_SIDE_REAR_HALF_PORTRAIT",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-driver-side-rear-door",
        overlayImage: "car_exterior/portrait/driver_side_rear_door.svg",
        overlayImageSize: "40%",
        overlayImagePosition: "40%",
        overlayImagePositionLeft: "4%",
        side: "DRIVER_SIDE_REAR_DOOR",
        label: "Driver side rear door",
        area: "DRIVER_SIDE_REAR_DOOR",
        subArea: "DRIVER_SIDE_REAR_DOOR",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-driver-side-front-door",
        overlayImage: "car_exterior/portrait/driver_side_front_door.svg",
        overlayImageSize: "40%",
        overlayImagePosition: "40%",
        overlayImagePositionLeft: "4%",
        side: "DRIVER_SIDE_FRONT_DOOR",
        label: "Driver side front door",
        area: "DRIVER_SIDE_FRONT_DOOR",
        subArea: "DRIVER_SIDE_FRONT_DOOR",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-driver-side-front-half-portrait",
        overlayImage: "car_exterior/portrait/driver_side_front_half.svg",
        overlayImageSize: "30%",
        overlayImagePosition: "40%",
        overlayImagePositionLeft: "4%",
        side: "DRIVER_SIDE_FRONT_HALF_PORTRAIT",
        label: "Driver side front half",
        area: "DRIVER_SIDE_FRONT_HALF_PORTRAIT",
        subArea: "DRIVER_SIDE_FRONT_HALF_PORTRAIT",
        skipBBOX: true,
        skipCount: false,
      },
      //WindShield
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-front-windshield-front-left-portrait",
        overlayImage: "exterior/v3/windshield/wind_shield_front.svg",
        overlayImageSize: "100%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "50%",
        side: "FRONT_WINDSHIELD_LEFT_PORTRAIT",
        label: "Front Wind Shield Front Left",
        area: "FRONT_WINDSHIELD_FRONT_LEFT_PORTRAIT",
        subArea: "FRONT_WINDSHIELD_FRONT_LEFT_PORTRAIT",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-front-windshield-front-right-portrait",
        overlayImage: "exterior/v3/windshield/wind_shield_front.svg",
        overlayImageSize: "100%",
        overlayImagePosition: "0%",
        overlayImagePositionLeft: "-50%",
        side: "FRONT_WINDSHIELD_RIGHT_PORTRAIT",
        label: "Front Wind Shield Front Right",
        area: "FRONT_WINDSHIELD_FRONT_RIGHT_PORTRAIT",
        subArea: "FRONT_WINDSHIELD_FRONT_RIGHT_PORTRAIT",
        skipBBOX: true,
        skipCount: false,
      },
      //Roof
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-front-roof-left-portrait",
        overlayImage: "roof/front_roof.svg",
        overlayImageSize: "35%",
        overlayImagePosition: "45%",
        overlayImagePositionLeft: "40%",
        side: "FRONT_ROOF_LEFT_PORTRAIT",
        label: "Front Left Roof",
        area: "FRONT_ROOF_LEFT_PORTRAIT",
        subArea: "FRONT_ROOF_LEFT_PORTRAIT",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-front-roof-right-portrait",
        overlayImage: "roof/front_roof.svg",
        overlayImageSize: "35%",
        overlayImagePosition: "45%",
        overlayImagePositionLeft: "-40%",
        side: "FRONT_ROOF_RIGHT_PORTRAIT",
        label: "Front Right Roof",
        area: "FRONT_ROOF_RIGHT_PORTRAIT",
        subArea: "FRONT_ROOF_RIGHT_PORTRAIT",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-back-roof-left-portrait",
        overlayImage: "roof/back_roof.svg",
        overlayImageSize: "35%",
        overlayImagePosition: "45%",
        overlayImagePositionLeft: "40%",
        side: "BACK_ROOF_LEFT_PORTRAIT",
        label: "Back Left Roof",
        area: "BACK_ROOF_LEFT_PORTRAIT",
        subArea: "BACK_ROOF_LEFT_PORTRAIT",
        skipBBOX: true,
        skipCount: false,
      },
      {
        type: "Inspection",
        sub: "pre",
        step: "exterior-back-roof-right-portrait",
        overlayImage: "roof/back_roof.svg",
        overlayImageSize: "35%",
        overlayImagePosition: "45%",
        overlayImagePositionLeft: "-40%",
        side: "BACK_ROOF_RIGHT_PORTRAIT",
        label: "Back Right Roof",
        area: "BACK_ROOF_RIGHT_PORTRAIT",
        subArea: "BACK_ROOF_RIGHT_PORTRAIT",
        skipBBOX: true,
        skipCount: false,
      },
      //3
      {
        type: "Inspection",
        sub: "pre",
        step: "car-key",
        overlayImage: "car_key.png",
        overlayImageSize: "60%",
        overlayImagePosition: "25%",
        overlayImagePositionLeft: "-8%",
        side: "CAR_KEY",
        label: "Car Key",
        area: "CAR_KEY",
        subArea: "CAR_KEY",
        skipBBOX: true,
        skipCount: false,
        upNext: "/images/upnext/Key.svg",
      },
    ];
  }
};

export default exteriorPreInspection;
