let baseURL =
  process.env.REACT_APP_CARDATA_API ||
  "https://cardata.devInternal.carscan.ai​​";

export const UploadImageToS3$ = (uploadURL, data, md5, contentType, params) => {
  console.log("IMGMD5 UPLOAD", data);
  //   var formData = new FormData();
  //   formData.append('file', data, 'someFileName.png');

  return fetch(uploadURL, {
    method: "PUT",
    body: data,
    headers: {
      "Content-MD5": md5,
      "Content-Type": contentType,
    },
  });
};
