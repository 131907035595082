import React from "react";
import "./404.css";
import { Box } from "@material-ui/core";

const Page404 = () => {
  return (
    <Box className="wrapper-404">
      <div className="c">
        <div className="_404">404</div>
        <hr className="horiz" />
        <div className="_1">THE PAGE</div>
        <div className="_2">WAS NOT FOUND</div>
      </div>
    </Box>
  );
};

export default Page404;
