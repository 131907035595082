import IconWhatsapp from "../IconWhatsapp";
import { Box } from "@material-ui/core";
import React from "react";
import { getFromStore } from "../../Stores/utils";
import { WHATSAPP_ICON_HIDE } from "../../mockconfig/configs";

const WhatsAppFunction = (props) => {
  const companyData = getFromStore("CompanyStore");
  let whatsapp_help_enabled = companyData.whatsapp_help.is_help_enabled;
  let whatsapp_number = companyData.whatsapp_help.whatsapp_help_no;



  return (
    (whatsapp_help_enabled && whatsapp_number && !WHATSAPP_ICON_HIDE.includes(companyData.otp) &&
    <Box
      data-id="whatsapp-icon"
      style={{ position: "absolute", top: props.iconshow? "6.5rem": 15, left: 15, zIndex: 350 }}
      >
      <a href={`https://wa.me/${whatsapp_number}`}>
        <IconWhatsapp
          info={props.info}
          textColor={props.textColor ? props.textColor : "#000000"}
        />
      </a>
      </Box>
    )
  );
};

export default WhatsAppFunction;
