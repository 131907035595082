import React from "react";
import styles from "./index.module.css";

const Expired = () => {
  return (
    <div className={styles.wrapper}>
      <img
        src="/images/errors/expired.svg"
        alt="expired"
        className={styles.image}
      />
      <span className={styles.header}>Link Expired</span>
      <span className={styles.text}>
        Sorry, the link validity has expired, Please contact your insurance
        company for a new one
      </span>
    </div>
  );
};

export default Expired;
