export const layerScaleX = window.innerWidth / 980;
export const layerScaleY = window.innerHeight / 580;
export const rearX = 110;
export const rearY = 200;
export const rearSideTextX = rearX - 90;
export const rearSideTextY = rearY + 630;
export const frontX = 530;
export const frontY = 320;
export const frontSideTextX = frontX - 31;
export const frontSideTextY = frontY + 510;
export const passengerSideX = 930;
export const passengerSideY = 340;
export const passengerSideTextX = passengerSideX - 70;
export const passengerSideTextY = passengerSideY + 215;
export const driverSideX = 930;
export const driverSideY = 640;
export const driverSideTextX = driverSideX - 70;
export const driverSideTextY = driverSideY + 300;
export const interiorX = 10;
export const interiorY = 350;
export const interiorPassengerTextX = interiorX;
export const interiorPassengerTextY = interiorY - 60;
export const interiorDriverTextX = interiorX;
export const interiorDriverTextY = interiorY + 552;
export const interiorPassengerDoorTextX = interiorX + 1100;
export const interiorPassengerDoorTextY = interiorY + 35 + 140;
export const interiorDriverDoorTextX = interiorX + 1020;
export const interiorDriverDoorTextY = interiorY + 500 + 30;
export const passengerSideRearTyreX = 50;
export const passengerSideRearTyreY = 500;
export const driverSideTyreX = 900;
export const driverSideTyreY = 420;
export const driverSideTyreTextX = 900;
export const driverSideTyreTextY = 780;
export const driverSideRearTyreTextY = 650;
export const passengerSideTyreTextX = 100;
export const passengerSideTyreTextY = 780;
export const passengerSideRearTyreTextY = 650;
export const scale = 0.5;
export const scaleRatio = 0.5 / scale;
export const carParts = {
  exterior: [
    { type: "FRONT_ROOF", img: "Top_top.svg", x: rearX +1  , y: rearY - 5, height: 130, width: 193 },
    { type: "BACK_ROOF", img: "Top_bottom.svg", x: rearX+20 , y: rearY + 147,height: 170, width : 160},
    { type: "REAR_BOOT", img: "Boot.png", x: rearX - 20, y: rearY + 340 },
    {
      type: "REAR_WIND_SCREEN",
      img: "rear_windshield.png",
      x: rearX + 2,
      y: rearY + 370,
    },
    {
      type: "REAR_DRIVER_SIDE_TAIL_LIGHT",
      img: "left_taillight.png",
      x: rearX - 90,
      y: rearY + 420,
    },
    {
      type: "REAR_PASSENGER_SIDE_TAIL_LIGHT",
      img: "right_taillight.png",
      x: rearX + 238,
      y: rearY + 420,
    },
    {
      type: "REAR_PASSENGER_SIDE_BUMPER",
      img: "rear_passenger_side_bumper.png",
      x: rearX - 70,
      y: rearY + 500,
    },
    {
      type: "REAR_DRIVER_SIDE_BUMPER",
      img: "rear_driver_side_bumper.png",
      x: rearX + 92,
      y: rearY + 501,
    },
    { type: "FRONT_WIND_SCREEN", img: "windshield.png", x: frontX, y: frontY }, // start front side
    { type: "FRONT_BONNET", img: "bonnet.png", x: frontX, y: frontY + 180 },
    {
      type: "FRONT_DRIVER_SIDE_HEAD_LIGHT",
      img: "left_headlight.png",
      x: frontX - 30,
      y: frontY + 272,
    },
    {
      type: "FRONT_PASSENGER_SIDE_HEAD_LIGHT",
      img: "right_headlight.png",
      x: frontX + 252,
      y: frontY + 272,
    },
    {
      type: "FRONT_DRIVER_SIDE_BUMPER",
      img: "left_bumper.png",
      x: frontX - 20,
      y: frontY + 350,
    },
    {
      type: "FRONT_PASSENGER_SIDE_BUMPER",
      img: "right_bumper.png",
      x: frontX + 132,
      y: frontY + 350,
    },
    {
      type: "PASSENGER_SIDE_FENDER",
      img: "passenger_side_fender.png",
      x: passengerSideX,
      y: passengerSideY,
    },
    {
      type: "PASSENGER_SIDE_FRONT_DOOR",
      img: "passenger_side_front_door.png",
      x: passengerSideX + 180,
      y: passengerSideY - 88,
    },
    {
      type: "GLASS_FRONT_PASSENGER_WINDOW",
      img: "passenger_side_front_window.png",
      x: passengerSideX + 226,
      y: passengerSideY - 88 + 12, // - front door + glass
    },
    {
      type: "PASSENGER_SIDE_REAR_DOOR",
      img: "passenger_side_rear_door.png",
      x: passengerSideX + 364 + 15,
      y: passengerSideY - 88, // front door
    },
    {
      type: "GLASS_REAR_PASSENGER_WINDOW",
      img: "passenger_side_rear_window.png",
      x: passengerSideX + 364 + 15 + 28, // touching the front door + gap + glass
      y: passengerSideY - 88 + 13, // front door + glass
    },
    {
      type: "PASSENGER_SIDE_QUATER",
      img: "passenger_side_quarter.png",
      x: passengerSideX + 364 + 15 + 108, // touching the front door + gap
      y: passengerSideY - 88, // front door
    },
    {
      type: "FRONT_PASSENGER_SIDE_WHEEL",
      img: "passenger_side_front_tyre.png",
      x: passengerSideX + 35 - 15, // Fit into fender - gap
      y: passengerSideY + 70 + 15, // Fit into fender + gap
    },
    {
      type: "PASSENGER_SIDE_ROCKER_PANEL",
      img: "passenger_side_rocker_panel.png",
      x: passengerSideX + 191 + 15, // Front Door + bring to center
      y: passengerSideY + 135 + 30, // Front Door + gap
    },
    {
      type: "PASSENGER_SIDE_REAR_WHEEL",
      img: "passenger_side_rear_tyre.png",
      x: passengerSideX + 530 + 15, // Fit into Quarter +  gap
      y: passengerSideY + 85 + 15, // Fit into Quarter +  gap
    },
    {
      type: "DRIVER_SIDE_QUATER",
      img: "driver_side_quarter.png",
      x: driverSideX,
      y: driverSideY,
    },
    {
      type: "DRIVER_SIDE_REAR_DOOR",
      img: "driver_side_rear_door.png",
      x: driverSideX + 76 + 15, // Touch the Quarter + gap
      y: driverSideY,
    },
    {
      type: "GLASS_REAR_DRIVER_WINDOW",
      img: "driver_side_rear_window.png",
      x: driverSideX + 76 + 15 + 30, // Touch the Quarter + gap + glass
      y: driverSideY + 15, // Touch the Quarter + glass
    },
    {
      type: "DRIVER_SIDE_FRONT_DOOR",
      img: "driver_side_front_door.png",
      x: driverSideX + 260 + 15, // Rear Door touch + gap
      y: driverSideY, // Touch the Quarter + glass
    },
    {
      type: "GLASS_FRONT_DRIVER_WINDOW",
      img: "driver_side_front_window.png",
      x: driverSideX + 260 + 15 + 18, // Rear Door touch + gap + glass
      y: driverSideY + 15, // Touch the Quarter + glass
    },
    {
      type: "DRIVER_SIDE_FENDER",
      img: "driver_side_fender.png",
      x: driverSideX + 468 + 15, // Front Door touch + gap
      y: driverSideY + 88, // Front Door Touch
    },
    {
      type: "DRIVER_SIDE_REAR_WHEEL",
      img: "driver_side_rear_tyre.png",
      x: driverSideX + 5 - 15, // Quarter touch - gap
      y: driverSideY + 170 + 15, // Touch the Quarter + gap
    },
    {
      type: "DRIVER_SIDE_ROCKER_PANEL",
      img: "driver_side_rocker_panel.png",
      x: driverSideX + 210 - 15, // front door - bring to center
      y: driverSideY + 225 + 30, // Fit into the tyre + gap
    },
    {
      type: "DRIVER_SIDE_FRONT_WHEEL",
      img: "driver_side_front_tyre.png",
      x: driverSideX + 510 + 15, // FIt into the tyre + gap
      y: driverSideY + 160 + 15, // Fit into the tyre + gap
    },
  ],

  interior: [
    {
      type: "INTERIOR_DASHBOARD",
      img: "dashboard.png",
      x: interiorX + 400 + 290 + 30 + 140,
      y: interiorY + 35,
    },
    {
      type: "INTERIOR_DASHBOARD",
      img: "dashboard_2.png",
      x: interiorX + 400 + 290 + 30 + 150 + 140,
      y: interiorY + 35,
    },
    {
      type: "INTERIOR_DRIVER_SIDE_FRONT_DOOR",
      img: "driver_side_front_door.png",
      x: interiorX + 400 + 290 + 30 + 150 + 150 + 245 + 50 + 70,
      y: interiorY + 25 + 165 + 30 + 50 + 30,
    },
    {
      type: "INTERIOR_DRIVER_SIDE_FRONT_SEAT",
      img: "driver_seat.png",
      x: interiorX + 400 + 140,
      y: interiorY + 25 + 165 + 30 + 50 + 30,
    },
    {
      type: "INTERIOR_DRIVER_SIDE_REAR_DOOR",
      img: "driver_side_rear_door.png",
      x: interiorX + 400 + 290 + 30 + 150 + 150 + 70,
      y: interiorY + 25 + 165 + 30 + 50 + 30,
    },
    {
      type: "INTERIOR_DRIVER_SIDE_REAR_SEAT",
      img: "rear_seat_driver_side.png",
      x: interiorX + 140,
      y: interiorY + 203 + 110, // rearmiddle seat + rear dirver seat.
    },
    {
      type: "INTERIOR_GEAR_AREA",
      img: "gear_box.png",
      x: interiorX + 400 + 15 + 140,
      y: interiorY + 25 + 165 + 30,
    },
    {
      type: "INTERIOR_REAR_MIDDLE_SEAT",
      img: "rear_seat_middle_side.png",
      x: interiorX + 140,
      y: interiorY + 203,
    },
    {
      type: "INTERIOR_PASSENGER_SIDE_FRONT_DOOR",
      img: "passenger_side_front_door.png",
      x: interiorX + 400 + 290 + 30 + 150 + 150 + 245 + 50 + 70,
      y: interiorY - 35,
    },
    {
      type: "INTERIOR_PASSENGER_SIDE_REAR_SEAT",
      img: "rear_seat_passenger_side.png",
      x: interiorX + 140,
      y: interiorY,
    },
    {
      type: "INTERIOR_PASSENGER_SIDE_REAR_DOOR",
      img: "passenger_side_rear_door.png",
      x: interiorX + 400 + 290 + 30 + 150 + 150 + 70,
      y: interiorY - 35,
    },
    {
      type: "INTERIOR_PASSENGER_SIDE_FRONT_SEAT",
      img: "passenger_seat.png",
      x: interiorX + 400 + 140,
      y: interiorY + 25,
    },
    {
      type: "INTERIOR_REAR_DASHBOARD",
      img: "rear_dashboard.svg",
      x: interiorX - 30,
      y: interiorY - 10,
      width: 170,
      height: 550,
    },
  ],
  tyresrims: [
    {
      type: "PASSENGER_SIDE_FENDER",
      img: "passenger_side_fender.png",
      x: passengerSideRearTyreX,
      y: passengerSideRearTyreY,
    },
    {
      type: "PASSENGER_SIDE_FRONT_DOOR",
      img: "passenger_side_front_door.png",
      x: passengerSideRearTyreX + 180,
      y: passengerSideRearTyreY - 88,
    },
    {
      type: "GLASS_FRONT_PASSENGER_WINDOW",
      img: "passenger_side_front_window.png",
      x: passengerSideRearTyreX + 226,
      y: passengerSideRearTyreY - 88 + 12, // - front door + glass
    },
    {
      type: "PASSENGER_SIDE_REAR_DOOR",
      img: "passenger_side_rear_door.png",
      x: passengerSideRearTyreX + 364 + 15,
      y: passengerSideRearTyreY - 88, // front door
    },
    {
      type: "GLASS_REAR_PASSENGER_WINDOW",
      img: "passenger_side_rear_window.png",
      x: passengerSideRearTyreX + 364 + 15 + 28, // touching the front door + gap + glass
      y: passengerSideRearTyreY - 88 + 13, // front door + glass
    },
    {
      type: "PASSENGER_SIDE_QUATER",
      img: "passenger_side_quarter.png",
      x: passengerSideRearTyreX + 364 + 15 + 108, // touching the front door + gap
      y: passengerSideRearTyreY - 88, // front door
    },
    {
      type: "FRONT_TYRE_LEFT",
      img: "passenger_side_front_tyre.png",
      x: passengerSideRearTyreX + 35 - 30, // Fit into fender - gap
      y: passengerSideRearTyreY + 70 + 15,
      width: 140,
      height: 140, // Fit into fender + gap
    },
    {
      type: "FRONT_TYRE_LEFT_RIM",
      img: "passenger_side_front_rim.png",
      x: passengerSideRearTyreX + 31, // fit into tyre - gap
      y: passengerSideRearTyreY + 111,
      width: 75,
      height: 75, // fit into tyre
    },
    {
      type: "PASSENGER_SIDE_ROCKER_PANEL",
      img: "passenger_side_rocker_panel.png",
      x: passengerSideRearTyreX + 191 + 15, // Front Door + bring to center
      y: passengerSideRearTyreY + 135 + 30, // Front Door + gap
    },
    {
      type: "REAR_TYRE_LEFT",
      img: "passenger_side_rear_tyre.png",
      x: passengerSideRearTyreX + 530 + 2, // Fit into Quarter +  gap
      y: passengerSideRearTyreY + 85 + 15,
      width: 140,
      height: 140, // Fit into Quarter +  gap
    },
    {
      type: "REAR_TYRE_LEFT_RIM",
      img: "passenger_side_rear_rim.png",
      x: passengerSideRearTyreX + 570, // Fit into tyre +  gap
      y: passengerSideRearTyreY + 128,
      width: 75,
      height: 75,
    },
    {
      type: "DRIVER_SIDE_QUATER",
      img: "driver_side_quarter.png",
      x: driverSideTyreX,
      y: driverSideTyreY,
    },
    {
      type: "DRIVER_SIDE_REAR_DOOR",
      img: "driver_side_rear_door.png",
      x: driverSideTyreX + 76 + 15, // Touch the Quarter + gap
      y: driverSideTyreY,
    },
    {
      type: "GLASS_REAR_DRIVER_WINDOW",
      img: "driver_side_rear_window.png",
      x: driverSideTyreX + 76 + 15 + 30, // Touch the Quarter + gap + glass
      y: driverSideTyreY + 15, // Touch the Quarter + glass
    },
    {
      type: "DRIVER_SIDE_FRONT_DOOR",
      img: "driver_side_front_door.png",
      x: driverSideTyreX + 260 + 15, // Rear Door touch + gap
      y: driverSideTyreY, // Touch the Quarter + glass
    },
    {
      type: "GLASS_FRONT_DRIVER_WINDOW",
      img: "driver_side_front_window.png",
      x: driverSideTyreX + 260 + 15 + 18, // Rear Door touch + gap + glass
      y: driverSideTyreY + 15, // Touch the Quarter + glass
    },
    {
      type: "DRIVER_SIDE_FENDER",
      img: "driver_side_fender.png",
      x: driverSideTyreX + 468 + 15, // Front Door touch + gap
      y: driverSideTyreY + 88, // Front Door Touch
    },
    {
      type: "REAR_TYRE_RIGHT",
      img: "driver_side_rear_tyre.png",
      x: driverSideTyreX + 5 - 20, // Quarter touch - gap
      y: driverSideTyreY + 170 + 15,
      width: 140,
      height: 140, // Touch the Quarter + gap
    },
    {
      type: "REAR_TYRE_RIGHT_RIM",
      img: "driver_side_rear_rim.png",
      x: driverSideTyreX + 12, // FIt into the tyre - gap
      y: driverSideTyreY + 212,
      width: 75,
      height: 75, // FIt into the tyre
    },

    {
      type: "DRIVER_SIDE_ROCKER_PANEL",
      img: "driver_side_rocker_panel.png",
      x: driverSideTyreX + 210 - 15, // front door - bring to center
      y: driverSideTyreY + 225 + 30, // Fit into the tyre + gap
    },
    {
      type: "FRONT_TYRE_RIGHT",
      img: "driver_side_front_tyre.png",
      x: driverSideTyreX + 510 + 8, // FIt into the tyre + gap
      y: driverSideTyreY + 160 + 15,
      width: 140,
      height: 140, // Fit into the tyre + gap
    },
    {
      type: "FRONT_TYRE_RIGHT_RIM",
      img: "driver_side_front_rim.png",
      x: driverSideX + 528, // FIt into the tyre + gap
      y: driverSideY -18,
      width: 75,
      height: 75, // Fit into the tyre
    },
  ],
};

export const exteriorClaimsCaptureSequence = [
  "FRONT_BONNET",
  "FRONT_PASSENGER_SIDE_HEAD_LIGHT",
  "FRONT_PASSENGER_SIDE_BUMPER",
  "FRONT_DRIVER_SIDE_HEAD_LIGHT",
  "FRONT_DRIVER_SIDE_BUMPER",
  "FRONT_PASSENGER_SIDE_RIM",
  "FRONT_PASSENGER_SIDE_WHEEL",
  "PASSENGER_SIDE_FENDER",
  "PASSENGER_SIDE_FRONT_WHEEL",
  "PASSENGER_SIDE_FRONT_DOOR",
  "GLASS_FRONT_PASSENGER_WINDOW",
  "PASSENGER_SIDE_REAR_DOOR",
  "GLASS_REAR_PASSENGER_WINDOW",
  "PASSENGER_SIDE_ROCKER_PANEL",
  "PASSENGER_SIDE_QUATER",
  "PASSENGER_SIDE_REAR_WHEEL",
  "PASSENGER_SIDE_REAR_RIM",
  "DRIVER_SIDE_REAR_RIM",
  "DRIVER_SIDE_REAR_WHEEL",
  "DRIVER_SIDE_QUATER",
  "DRIVER_SIDE_REAR_DOOR",
  "GLASS_REAR_DRIVER_WINDOW",
  "DRIVER_SIDE_FRONT_DOOR",
  "GLASS_FRONT_DRIVER_WINDOW",
  "DRIVER_SIDE_ROCKER_PANEL",
  "DRIVER_SIDE_FENDER",
  "DRIVER_SIDE_FRONT_WHEEL",
  "DRIVER_SIDE_FRONT_RIM",
  "FRONT_WIND_SCREEN",
  "FRONT_ROOF",
  "BACK_ROOF",
  "REAR_DRIVER_SIDE_TAIL_LIGHT",
  "REAR_DRIVER_SIDE_BUMPER",
  "REAR_PASSENGER_SIDE_TAIL_LIGHT",
  "REAR_PASSENGER_SIDE_BUMPER",
  "REAR_BOOT",
  "REAR_WIND_SCREEN",
];

export const interiorClaimsCaptureSequence = [
  "INTERIOR_ROOF",
  "INTERIOR_DASHBOARD",
  "INTERIOR_REAR_DASHBOARD",
  "INTERIOR_DRIVER_SIDE_FRONT_DOOR",
  "INTERIOR_DRIVER_SIDE_FRONT_SEAT",
  "INTERIOR_DRIVER_SIDE_REAR_DOOR",
  "INTERIOR_DRIVER_SIDE_REAR_SEAT",
  "INTERIOR_GEAR_AREA",
  "INTERIOR_REAR_MIDDLE_SEAT",
  "INTERIOR_PASSENGER_SIDE_FRONT_DOOR",
  "INTERIOR_PASSENGER_SIDE_FRONT_SEAT",
  "INTERIOR_PASSENGER_SIDE_REAR_DOOR",
  "INTERIOR_PASSENGER_SIDE_REAR_SEAT",
  "INTERIOR_STEERING",
];
export const exteriorTyreClaimsCaptureSequence = [
  "FRONT_TYRE_LEFT_WHEEL",
  "FRONT_TYRE_LEFT_DEPTH",
  "FRONT_TYRE_LEFT_RIM",
  "FRONT_TYRE_RIGHT_WHEEL",
  "FRONT_TYRE_RIGHT_DEPTH",
  "FRONT_TYRE_RIGHT_RIM",
  "REAR_TYRE_LEFT_WHEEL",
  "REAR_TYRE_LEFT_DEPTH",
  "REAR_TYRE_LEFT_RIM",
  "REAR_TYRE_RIGHT_WHEEL",
  "REAR_TYRE_RIGHT_DEPTH",
  "REAR_TYRE_RIGHT_RIM",
];
