import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    inputLabel: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        fontSize: theme.spacing(2), 
        fontWeight: theme.typography.fontWeightBold, 
        color: theme.palette.text.primary,
        textAlign:"center"
    },
    textField:{
        fontSize: theme.spacing(2),
    },
    label:{
        textAlign:"center",
        paddingBottom: theme.spacing(1),
    }
  }));