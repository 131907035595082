const exteriorClaimInspection = [
  //   "reference data",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-bonnet",
    overlayImage: "front_side/bonnet.png", // FrontSide
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "FRONT_BONNET",
    label: "Front Bonnet",
    area: "FRONT",
    subArea: "BONNET",
  },
  // "frontrightheadlight",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-passenger-side-head-light",
    overlayImage: "front_side/Headlight_PassengerSide.png", // Passengersidefront_front
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "FRONT_PASSENGER_SIDE_HEAD_LIGHT",
    label: "Front Passenger Side Head Light",
    area: "FRONT",
    subArea: "PASSENGER_SIDE_HEAD_LIGHT",
  },
  // "frontrightbumper",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-passenger-side-bumper",
    overlayImage: "front_side/Bumper_PassengerSide.png", //
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "FRONT_PASSENGER_SIDE_BUMPER",
    label: "Front Passenger Side Bumper",
    area: "FRONT",
    subArea: "PASSENGER_SIDE_BUMPER",
  },
  // "frontleftheadlight",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-driver-side-head-light",
    overlayImage: "front_side/Headlight_DriverSide.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "0%",
    side: "FRONT_DRIVER_SIDE_HEAD_LIGHT",
    label: "Front Driver Side Head Light",
    area: "FRONT",
    subArea: "DRIVER_SIDE_HEAD_LIGHT",
  },
  // "frontleftbumper",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-driver-side-bumper",
    overlayImage: "front_side/Bumper_DriverSide.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "FRONT_DRIVER_SIDE_BUMPER",
    label: "Front Driver Side Bumper",
    area: "FRONT",
    subArea: "DRIVER_SIDE_BUMPER",
  },
  // "passengersidefrontrim",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-passenger-side-front-rim",
    overlayImage: "wheels/PassengerSide_FrontRim.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "PASSENGER_SIDE_FRONT_RIM",
    label: "Passenger Side Front Rim",
    area: "TYRE",
    subArea: "PASSENGER_SIDE_FRONT_RIM",
  },
  // "passengersidefronttyre",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-passenger-side-wheel",
    overlayImage: "passenger_side/Front-Wheel.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "FRONT_PASSENGER_SIDE_WHEEL",
    label: "Passenger Side Front Wheel",
    area: "PASSENGER_SIDE",
    subArea: "FRONT_WHEEL",
  },
  // "passengersidefender",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-passenger-side-fender",
    overlayImage: "passenger_side/Fender.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "PASSENGER_SIDE_FENDER",
    label: "Passenger Side Fender",
    area: "PASSENGER_SIDE",
    subArea: "FENDER",
  },
  // "passengersidefrontdoor",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-passenger-side-front-door",
    overlayImage: "passenger_side/FrontDoor.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "PASSENGER_SIDE_FRONT_DOOR",
    label: "Passenger Side Front Door",
    area: "PASSENGER_SIDE",
    subArea: "FRONT_DOOR",
  },
  // "passengersidefrontwindow",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-glass-front-passenger-window",
    overlayImage: "glass_and_wheels/FrontWindow_PassengerSide.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "GLASS_FRONT_PASSENGER_WINDOW",
    label: "Glass Front Passenger Window",
    area: "PASSENGER_SIDE",
    subArea: "FRONT_WINDOWPANEL",
  },
  // "passengersidereardoor",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-passenger-side-rear-door",
    overlayImage: "passenger_side/RearDoor.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "PASSENGER_SIDE_REAR_DOOR",
    label: "Passenger Side Rear Door",
    area: "PASSENGER_SIDE",
    subArea: "REAR_DOOR",
  },
  // "passengersiderearwindow"
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-glass-rear-passenger-window",
    overlayImage: "glass_and_wheels/RearWindow_PassengerSide.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "GLASS_REAR_PASSENGER_WINDOW",
    label: "Glass Rear Passenger Window",
    area: "PASSENGER_SIDE",
    subArea: "REAR_WINDOWPANEL",
  },
  // "passengersiderockerpanel",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-passenger-side-rocker-panel",
    overlayImage: "passenger_side/RocketPanel.png",
    overlayImageSize: "96%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "PASSENGER_SIDE_ROCKER_PANEL",
    label: "Passenger Side Rocker Panel",
    area: "PASSENGER_SIDE",
    subArea: "ROCKER_PANEL",
  },
  // "passengersidequarter",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-passenger-side-quater",
    overlayImage: "passenger_side/Quarter.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "PASSENGER_SIDE_QUATER",
    label: "Passenger Side Quarter",
    area: "PASSENGER_SIDE",
    subArea: "QUATER",
  },
  // "passengersidereartyre",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-passenger-side-rear-wheel",
    overlayImage: "passenger_side/RearWheel.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "PASSENGER_SIDE_REAR_WHEEL",
    label: "Passenger Side Rear WHEEL",
    area: "PASSENGER_SIDE",
    subArea: "REAR_WHEEL",
  },
  // "passengersiderearrim",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-passenger-side-rear-rim",
    overlayImage: "wheels/PassengerSide_RearRim.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "PASSENGER_SIDE_REAR_RIM",
    label: "Passenger Side Rear Rim",
    area: "TYRE",
    subArea: "PASSENGER_SIDE_REAR_RIM",
  },
  // "driversiderearrim",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-driver-side-rear-rim",
    overlayImage: "wheels/DriverSide_RearRim.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "DRIVER_SIDE_REAR_RIM",
    label: "Driver Side Rear Rim",
    area: "TYRE",
    subArea: "DRIVER_SIDE_REAR_RIM",
  },
  // "driversidereartyre",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-driver-side-rear-wheel",
    overlayImage: "driver_side/RearWheel.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "DRIVER_SIDE_REAR_WHEEL",
    label: "Driver Side Rear Wheel",
    area: "DRIVER_SIDE",
    subArea: "REAR_WHEEL",
  },
  // "driversidequarter",
  //TODO:
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-driver-side-quater",
    overlayImage: "driver_side/Quarter.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "DRIVER_SIDE_QUATER",
    label: "Driver Side Quarter",
    area: "DRIVER_SIDE",
    subArea: "QUATER",
  },
  // "driversidereardoor",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-driver-side-rear-door",
    overlayImage: "driver_side/RearDoor.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "DRIVER_SIDE_REAR_DOOR",
    label: "Driver Side Rear Door",
    area: "DRIVER_SIDE",
    subArea: "REAR_DOOR",
  },
  // "driversiderearwindow",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-glass-rear-driver-window",
    overlayImage: "glass_and_wheels/RearWindow_DriverSide.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "GLASS_REAR_DRIVER_WINDOW",
    label: "Glass Rear Driver Window",
    area: "DRIVER_SIDE",
    subArea: "REAR_WINDOWPANEL",
  },
  // "driversidefrontdoor",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-driver-side-front-door",
    overlayImage: "driver_side/FrontDoor.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "DRIVER_SIDE_FRONT_DOOR",
    label: "Driver Side Front Door",
    area: "DRIVER_SIDE",
    subArea: "FRONT_DOOR",
  },
  // "driversidefrontwindow",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-glass-front-driver-window",
    overlayImage: "glass_and_wheels/FrontWindow_DriverSide.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "GLASS_FRONT_DRIVER_WINDOW",
    label: "Glass Front Driver Window",
    area: "DRIVER_SIDE",
    subArea: "FRONT_WINDOWPANEL",
  },
  // "driversiderockerpanel",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-driver-side-rocker-panel",
    overlayImage: "driver_side/RockerPanel.png",
    overlayImageSize: "96%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "DRIVER_SIDE_ROCKER_PANEL",
    label: "Driver Side Rocker Panel",
    area: "DRIVER_SIDE",
    subArea: "ROCKER_PANEL",
  },
  // "driversidefender",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-driver-side-fender",
    overlayImage: "driver_side/Fender.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "DRIVER_SIDE_FENDER",
    label: "Driver Side Fender",
    area: "DRIVER_SIDE",
    subArea: "FENDER",
  },
  // "driversidefronttyre",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-driver-side-front-wheel",
    overlayImage: "driver_side/FrontWheel.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "8%",
    side: "DRIVER_SIDE_FRONT_WHEEL",
    label: "Driver Side Front WHEEL",
    area: "DRIVER_SIDE",
    subArea: "FRONT_WHEEL",
  },
  // "driversidefrontrim",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-driver-side-front-rim",
    overlayImage: "wheels/DriverSide_FrontRim.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "8%",
    side: "DRIVER_SIDE_FRONT_RIM",
    label: "Driver Side Front Rim",
    area: "TYRE",
    subArea: "DRIVER_SIDE_FRONT_RIM",
  },
  // "windshield",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-wind-screen",
    overlayImage: "glass_and_wheels/FrontWindshield.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "FRONT_WIND_SCREEN",
    label: "Front Wind Screen",
    area: "FRONT",
    subArea: "WIND_SCREEN",
  },
  // "top",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-roof",
    overlayImage: "roof/front_roof.svg",
    overlayImageSize: "80%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "FRONT_ROOF",
    label: "Front Roof",
    area: "FRONT_ROOF",
    subArea: "FRONT_ROOF",
  },
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-back-roof",
    overlayImage: "roof/back_roof.svg",
    overlayImageSize: "80%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "5%",
    side: "BACK_ROOF",
    label: "Back Roof",
    area: "BACK_ROOF",
    subArea: "BACK_ROOF",
  },
  // "rearrightheadlight",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-passenger-side-tail-light",
    overlayImage: "rear_side/TailLight_PassengerSide.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "REAR_PASSENGER_SIDE_TAIL_LIGHT",
    label: "Rear Passenger Side Tail Light",
    area: "REAR",
    subArea: "PASSENGER_SIDE_TAIL_LIGHT",
  },
  // "rearrightbumper",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-passenger-side-bumper",
    overlayImage: "rear_side/RearBumper_PassengerSide.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "REAR_PASSENGER_SIDE_BUMPER",
    label: "Rear Passenger Side Bumper",
    area: "REAR",
    subArea: "PASSENGER_SIDE_BUMPER",
  },
  // "rearleftheadlight",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-driver-side-tail-light",
    overlayImage: "rear_side/TailLight_DriverSide.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "REAR_DRIVER_SIDE_TAIL_LIGHT",
    label: "Rear Driver Side Tail Light",
    area: "REAR",
    subArea: "DRIVER_SIDE_TAIL_LIGHT",
  },
  // "rearleftbumper",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-driver-side-bumper",
    overlayImage: "rear_side/RearBumper_DriverSide.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "REAR_DRIVER_SIDE_BUMPER",
    label: "Rear Driver Side Bumper",
    area: "REAR",
    subArea: "DRIVER_SIDE_BUMPER",
  },
  // "boot",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-boot",
    overlayImage: "rear_side/Boot.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "REAR_BOOT",
    label: "Rear Boot",
    area: "REAR",
    subArea: "BOOT",
  },
  // "rearscreen"
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-wind-screen",
    overlayImage: "glass_and_wheels/BackWindshield.png",
    overlayImageSize: "92%",
    overlayImagePosition: "0%",
    overlayImagePositionLeft: "2%",
    side: "REAR_WIND_SCREEN",
    label: "Rear Screen",
    area: "REAR",
    subArea: "WIND_SCREEN",
  },

  //Portrait mode
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-bonnet-left",
    overlayImage: "front_side/bonnet.png", // FrontSide
    overlayImageSize: "80%",
    overlayImagePosition: "5%",
    overlayImagePositionLeft: "50%",
    side: "FRONT_BONNET_LEFT",
    label: "Front Bonnet Left",
    area: "FRONT",
    subArea: "BONNET_LEFT",
  },
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-bonnet-right",
    overlayImage: "front_side/bonnet.png", // FrontSide
    overlayImageSize: "80%",
    overlayImagePosition: "5%",
    overlayImagePositionLeft: "-50%",
    side: "FRONT_BONNET_RIGHT",
    label: "Front Bonnet Right",
    area: "FRONT",
    subArea: "BONNET_RIGHT",
  },

  // "windshield",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-wind-screen-left",
    overlayImage: "glass_and_wheels/FrontWindshield.png",
    overlayImageSize: "50%",
    overlayImagePosition: "25%",
    overlayImagePositionLeft: "35%",
    side: "FRONT_WIND_SCREEN_LEFT",
    label: "Front Wind Screen Left",
    area: "FRONT",
    subArea: "WIND_SCREEN_LEFT",
  },

  // "windshield",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-wind-screen-right",
    overlayImage: "glass_and_wheels/FrontWindshield.png",
    overlayImageSize: "50%",
    overlayImagePosition: "25%",
    overlayImagePositionLeft: "-35%",
    side: "FRONT_WIND_SCREEN_RIGHT",
    label: "Front Wind Screen Right",
    area: "FRONT",
    subArea: "WIND_SCREEN_RIGHT",
  },

  // Passenger side head light
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-passenger-side-head-light-portrait",
    overlayImage: "front_side/Headlight_PassengerSide.png", // Passengersidefront_front
    overlayImageSize: "45%",
    overlayImagePosition: "30%",
    overlayImagePositionLeft: "7%",
    side: "FRONT_PASSENGER_SIDE_HEAD_LIGHT_PORTRAIT",
    label: "Front Passenger Side Head Light",
    area: "FRONT",
    subArea: "PASSENGER_SIDE_HEAD_LIGHT",
  },

  // "frontleftbumper",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-bumper-left",
    overlayImage: "front_side/Bumper_DriverSide.png",
    overlayImageSize: "60%",
    overlayImagePosition: "20%",
    overlayImagePositionLeft: "-10%",
    side: "FRONT_BUMPER_LEFT",
    label: "Front Driver Side Bumper",
    area: "FRONT",
    subArea: "DRIVER_SIDE_BUMPER",
  },
  // "frontrightbumper",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-bumper-right",
    overlayImage: "front_side/Bumper_PassengerSide.png", //
    overlayImageSize: "60%",
    overlayImagePosition: "25%",
    overlayImagePositionLeft: "16%",
    side: "FRONT_BUMPER_RIGHT",
    label: "Front Passenger Side Bumper",
    area: "FRONT",
    subArea: "PASSENGER_SIDE_BUMPER",
  },

  // "rearleftbumper",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-bumper-right",
    overlayImage: "rear_side/RearBumper_DriverSide.png",
    overlayImageSize: "60%",
    overlayImagePosition: "25%",
    overlayImagePositionLeft: "15%",
    side: "REAR_BUMPER_RIGHT",
    label: "Rear Driver Side Bumper",
    area: "REAR",
    subArea: "DRIVER_SIDE_BUMPER",
  },

  // "rearleftbumper",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-bumper-left",
    overlayImage: "rear_side/RearBumper_PassengerSide.png",
    overlayImageSize: "60%",
    overlayImagePosition: "25%",
    overlayImagePositionLeft: "-5%",
    side: "REAR_BUMPER_LEFT",
    label: "Rear Passenger Side Bumper",
    area: "REAR",
    subArea: "PASSENGER_SIDE_BUMPER",
  },

  // "frontleftheadlight",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-driver-side-head-light-portrait",
    overlayImage: "front_side/Headlight_DriverSide.png",
    overlayImageSize: "46%",
    overlayImagePosition: "30%",
    overlayImagePositionLeft: "4%",
    side: "FRONT_DRIVER_SIDE_HEAD_LIGHT_PORTRAIT",
    label: "Front Driver Side Head Light",
    area: "FRONT",
    subArea: "DRIVER_SIDE_HEAD_LIGHT",
  },

  // "passengersidefrontrim",
  // {
  //   type: "Inspection",
  //   sub: "claim",
  //   step: "exterior-passenger-side-front-rim-portrait",
  //   overlayImage: "wheels/PassengerSide_FrontRim.png",
  //   overlayImageSize: "92%",
  //   overlayImagePosition: "0%",
  //   overlayImagePositionLeft: "5%",
  //   side: "PASSENGER_SIDE_FRONT_RIM_PORTRAIT",
  //   label: "Passenger Side Front Rim",
  //   area: "TYRE",
  //   subArea: "PASSENGER_SIDE_FRONT_RIM",
  // },

  // "passengersidefronttyre",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-front-passenger-side-wheel-portrait",
    overlayImage: "passenger_side/Front-Wheel.png",
    overlayImageSize: "58%",
    overlayImagePosition: "25%",
    overlayImagePositionLeft: "-40%",
    side: "FRONT_PASSENGER_SIDE_WHEEL_PORTRAIT",
    label: "Passenger Side Front Wheel",
    area: "PASSENGER_SIDE",
    subArea: "FRONT_WHEEL",
  },

  // "passengersidefender",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-passenger-side-fender-portrait",
    overlayImage: "passenger_side/Fender.png",
    overlayImageSize: "50%",
    overlayImagePosition: "25%",
    overlayImagePositionLeft: "-6%",
    side: "PASSENGER_SIDE_FENDER_PORTRAIT",
    label: "Passenger Side Fender",
    area: "PASSENGER_SIDE",
    subArea: "FENDER",
  },

  // "passengersidefrontdoor",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-passenger-side-front-door-portrait",
    overlayImage: "passenger_side/FrontDoor.png",
    overlayImageSize: "45%",
    overlayImagePosition: "30%",
    overlayImagePositionLeft: "1%",
    side: "PASSENGER_SIDE_FRONT_DOOR_PORTRAIT",
    label: "Passenger Side Front Door",
    area: "PASSENGER_SIDE",
    subArea: "FRONT_DOOR",
  },

  // "passengersidefrontwindow",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-glass-front-passenger-window-portrait",
    overlayImage: "glass_and_wheels/FrontWindow_PassengerSide.png",
    overlayImageSize: "35%",
    overlayImagePosition: "30%",
    overlayImagePositionLeft: "7%",
    side: "GLASS_FRONT_PASSENGER_WINDOW_PORTRAIT",
    label: "Glass Front Passenger Window",
    area: "PASSENGER_SIDE",
    subArea: "FRONT_WINDOWPANEL",
  },

  // "passengersidereardoor",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-passenger-side-rear-door-portrait",
    overlayImage: "passenger_side/RearDoor.png",
    overlayImageSize: "65%",
    overlayImagePosition: "15%",
    overlayImagePositionLeft: "6%",
    side: "PASSENGER_SIDE_REAR_DOOR_PORTRAIT",
    label: "Passenger Side Rear Door",
    area: "PASSENGER_SIDE",
    subArea: "REAR_DOOR",
  },

  // "passengersiderearwindow"
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-glass-rear-passenger-window-portrait",
    overlayImage: "glass_and_wheels/RearWindow_PassengerSide.png",
    overlayImageSize: "28%",
    overlayImagePosition: "40%",
    overlayImagePositionLeft: "3%",
    side: "GLASS_REAR_PASSENGER_WINDOW_PORTRAIT",
    label: "Glass Rear Passenger Window",
    area: "PASSENGER_SIDE",
    subArea: "REAR_WINDOWPANEL",
  },

  // "passengersiderockerpanel",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-passenger-side-rocker-panel-portrait",
    overlayImage: "passenger_side/RocketPanel.png",
    overlayImageSize: "45%",
    overlayImagePosition: "30%",
    overlayImagePositionLeft: "2%",
    side: "PASSENGER_SIDE_ROCKER_PANEL_PORTRAIT",
    label: "Passenger Side Rocker Panel",
    area: "PASSENGER_SIDE",
    subArea: "ROCKER_PANEL",
  },

  // "passengersidequarter",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-passenger-side-quater-portrait",
    overlayImage: "passenger_side/Quarter.png",
    overlayImageSize: "45%",
    overlayImagePosition: "25%",
    overlayImagePositionLeft: "3%",
    side: "PASSENGER_SIDE_QUATER_PORTRAIT",
    label: "Passenger Side Quarter",
    area: "PASSENGER_SIDE",
    subArea: "QUATER",
  },

  // "passengersidereartyre",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-passenger-side-rear-wheel-portrait",
    overlayImage: "passenger_side/RearWheel.png",
    overlayImageSize: "60%",
    overlayImagePosition: "25%",
    overlayImagePositionLeft: "42%",
    side: "PASSENGER_SIDE_REAR_WHEEL_PORTRAIT",
    label: "Passenger Side Rear WHEEL",
    area: "PASSENGER_SIDE",
    subArea: "REAR_WHEEL",
  },

  // "driversidereartyre",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-driver-side-rear-wheel-portrait",
    overlayImage: "driver_side/RearWheel.png",
    overlayImageSize: "65%",
    overlayImagePosition: "25%",
    overlayImagePositionLeft: "-35%",
    side: "DRIVER_SIDE_REAR_WHEEL_PORTRAIT",
    label: "Driver Side Rear Wheel",
    area: "DRIVER_SIDE",
    subArea: "REAR_WHEEL",
  },

  // "driversidereardoor",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-driver-side-rear-door-portrait",
    overlayImage: "driver_side/RearDoor.png",
    overlayImageSize: "65%",
    overlayImagePosition: "15%",
    overlayImagePositionLeft: "2%",
    side: "DRIVER_SIDE_REAR_DOOR_PORTRAIT",
    label: "Driver Side Rear Door",
    area: "DRIVER_SIDE",
    subArea: "REAR_DOOR",
  },
  // "driversiderearwindow",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-glass-rear-driver-window-portrait",
    overlayImage: "glass_and_wheels/RearWindow_DriverSide.png",
    overlayImageSize: "28%",
    overlayImagePosition: "35%",
    overlayImagePositionLeft: "4%",
    side: "GLASS_REAR_DRIVER_WINDOW_PORTRAIT",
    label: "Glass Rear Driver Window",
    area: "DRIVER_SIDE",
    subArea: "REAR_WINDOWPANEL",
  },

  // "driversidefrontdoor",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-driver-side-front-door-portrait",
    overlayImage: "driver_side/FrontDoor.png",
    overlayImageSize: "45%",
    overlayImagePosition: "30%",
    overlayImagePositionLeft: "8%",
    side: "DRIVER_SIDE_FRONT_DOOR_PORTRAIT",
    label: "Driver Side Front Door",
    area: "DRIVER_SIDE",
    subArea: "FRONT_DOOR",
  },
  // "driversidefrontwindow",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-glass-front-driver-window-portrait",
    overlayImage: "glass_and_wheels/FrontWindow_DriverSide.png",
    overlayImageSize: "35%",
    overlayImagePosition: "30%",
    overlayImagePositionLeft: "1%",
    side: "GLASS_FRONT_DRIVER_WINDOW_PORTRAIT",
    label: "Glass Front Driver Window",
    area: "DRIVER_SIDE",
    subArea: "FRONT_WINDOWPANEL",
  },

  // "driversiderockerpanel",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-driver-side-rocker-panel-portrait",
    overlayImage: "driver_side/RockerPanel.png",
    overlayImageSize: "45%",
    overlayImagePosition: "25%",
    overlayImagePositionLeft: "8%",
    side: "DRIVER_SIDE_ROCKER_PANEL_PORTRAIT",
    label: "Driver Side Rocker Panel",
    area: "DRIVER_SIDE",
    subArea: "ROCKER_PANEL",
  },
  // "driversidefender",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-driver-side-fender-portrait",
    overlayImage: "driver_side/Fender.png",
    overlayImageSize: "50%",
    overlayImagePosition: "20%",
    overlayImagePositionLeft: "6%",
    side: "DRIVER_SIDE_FENDER_PORTRAIT",
    label: "Driver Side Fender",
    area: "DRIVER_SIDE",
    subArea: "FENDER",
  },
  // "driversidefronttyre",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-driver-side-front-wheel-portrait",
    overlayImage: "driver_side/FrontWheel.png",
    overlayImageSize: "60%",
    overlayImagePosition: "20%",
    overlayImagePositionLeft: "51%",
    side: "DRIVER_SIDE_FRONT_WHEEL_PORTRAIT",
    label: "Driver Side Front WHEEL",
    area: "DRIVER_SIDE",
    subArea: "FRONT_WHEEL",
  },

  // "rearrightheadlight",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-passenger-side-tail-light-portrait",
    overlayImage: "rear_side/TailLight_PassengerSide.png",
    overlayImageSize: "35%",
    overlayImagePosition: "30%",
    overlayImagePositionLeft: "5%",
    side: "REAR_PASSENGER_SIDE_TAIL_LIGHT_PORTRAIT",
    label: "Rear Passenger Side Tail Light",
    area: "REAR",
    subArea: "PASSENGER_SIDE_TAIL_LIGHT",
  },

  // "rearleftheadlight",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-driver-side-tail-light-portrait",
    overlayImage: "rear_side/TailLight_DriverSide.png",
    overlayImageSize: "35%",
    overlayImagePosition: "30%",
    overlayImagePositionLeft: "5%",
    side: "REAR_DRIVER_SIDE_TAIL_LIGHT_PORTRAIT",
    label: "Rear Driver Side Tail Light",
    area: "REAR",
    subArea: "DRIVER_SIDE_TAIL_LIGHT",
  },

  // "boot",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-boot-left",
    overlayImage: "rear_side/Boot.png",
    overlayImageSize: "70%",
    overlayImagePosition: "10%",
    overlayImagePositionLeft: "35%",
    side: "REAR_BOOT_LEFT",
    label: "Rear Boot Left",
    area: "REAR",
    subArea: "BOOT_LEFT",
  },
  // "boot",
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-boot-right",
    overlayImage: "rear_side/Boot.png",
    overlayImageSize: "70%",
    overlayImagePosition: "10%",
    overlayImagePositionLeft: "-35%",
    side: "REAR_BOOT_RIGHT",
    label: "Rear Boot Right",
    area: "REAR",
    subArea: "BOOT_RIGHT",
  },

  // "rearscreen"
  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-wind-screen-left",
    overlayImage: "glass_and_wheels/BackWindshield.png",
    overlayImageSize: "50%",
    overlayImagePosition: "25%",
    overlayImagePositionLeft: "50%",
    side: "REAR_WIND_SCREEN_LEFT",
    label: "Rear Screen Left",
    area: "REAR",
    subArea: "WIND_SCREEN_LEFT",
  },

  {
    type: "Inspection",
    sub: "claim",
    step: "exterior-rear-wind-screen-right",
    overlayImage: "glass_and_wheels/BackWindshield.png",
    overlayImageSize: "50%",
    overlayImagePosition: "25%",
    overlayImagePositionLeft: "-40%",
    side: "REAR_WIND_SCREEN_RIGHT",
    label: "Rear Screen Right",
    area: "REAR",
    subArea: "WIND_SCREEN_RIGHT",
  },
];

export default exteriorClaimInspection;
