import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  infoOneText,
  infoTwoText,
  infoThreeText,
  infoFourText,
} from "./ftueHelper";
import styles from "./index.module.css";
import DeviceOrientation, { Orientation } from "react-screen-orientation";
import OrientationAnimation from "../OrientationAnimation";
import Box from "@material-ui/core/Box";
import CompanyStore from "../../Stores/CompanyStore";
import { getFromStore } from "../../Stores/utils";
import DeviceOrientationWrapper from "../DeviceOrientationWrapper";
import cs from "classnames";
import Logo from "../Logos";
import { useStyles } from "../../Journey/styles";
import LockStore from "../../Stores/LockStore";
import { SKIP_FTUE, SKIP_TERMS_CONDITIONS } from "../../mockconfig/configs";

const ftueAssetsArraySequence = [
  { srcName: "infoOne.svg", text: infoOneText() },
  { srcName: "infoTwo.svg", text: infoTwoText() },
  { srcName: "infoThree.svg", text: infoThreeText() },
  { srcName: "infoFour.svg", text: infoFourText() },
];

const FTUE = () => {
  const history = useHistory();
  const [counter, setCounter] = useState(0);
  const companyDetails = getFromStore("CompanyStore");
  const isPortrait = companyDetails.is_gcc_journey;
  let { primary, secondary } = companyDetails.themes;
  const logoClasses = useStyles();
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  window.scrollTo(0, 1);
  const ftueAssetsArraySequence = [
    { srcName: "infoOne.svg", text: infoOneText(isPortrait) },
    { srcName: "infoTwo.svg", text: infoTwoText(isPortrait) },
    { srcName: "infoThree.svg", text: infoThreeText(isPortrait) },
    { srcName: "infoFour.svg", text: infoFourText(isPortrait) },
  ];

  useEffect(() => {
    if (
      SKIP_FTUE.includes(companyDetails?.otp) &&
      SKIP_TERMS_CONDITIONS.includes(companyDetails?.otp)
    ) {
      history.push("/journey/Onboard/inspectiontype");
    }
  }, []);

  const nextInfo = () => {
    setCounter((prevState) => {
      if (prevState === ftueAssetsArraySequence.length - 1) {
        if (/android/i.test(userAgent) && !isPortrait) {
          let de = document.documentElement;
          de.requestFullscreen();

          window.screen.orientation.lock("landscape").then(() => {
            localStorage.setItem("locked", true);
            LockStore.next("locked");
          });
        }

        history.push("/journey/Onboard/termsofuse");
        return;
      }
      return prevState + 1;
    });
  };

  return (
    <DeviceOrientationWrapper isLandscape={!isPortrait}>
      <img
        style={{ display: "none" }}
        src={companyDetails.logo_file_path}
        alt="company logo"
      />
      {/*-----------------------------------------*/}
      <div className={styles.ftueWrapper}>
        <Box
          textAlign="center"
          pt={0}
          mb={0}
          className={logoClasses.headingHeight}
        >
          <Logo
            type="FTUE"
            imageURL={companyDetails.logo_file_path}
            className={styles.ftueLogo}
          ></Logo>
        </Box>
        {ftueAssetsArraySequence.map((item, i) => {
          return (
            counter === i && (
              <>
                <img
                  className={styles.ftueAsset}
                  alt="srcname"
                  src={`/images/ftue/${item.srcName}`}
                ></img>
                {item.text}
              </>
            )
          );
        })}
        <div style={{ marginTop: -15 }}>
          {ftueAssetsArraySequence.map((item, i) => {
            return (
              <button
                key={i}
                onClick={() => setCounter(i)}
                className={
                  counter === i
                    ? styles.ftueDotButtonChange
                    : styles.ftueDotButton
                }
              ></button>
            );
          })}
        </div>

        <button
          className={cs(styles.ftueNextButton, {
            [styles.ftueNextButtonPortrait]: isPortrait,
          })}
          onClick={nextInfo}
          style={{
            background: `#${primary}`,
            color: `#fff`,
            borderColor: `#${primary}`,
          }}
        >
          NEXT
        </button>
      </div>
    </DeviceOrientationWrapper>
  );
};

export default FTUE;
