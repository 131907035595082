const exteriorTaxiPreInspection = [
    {
        type: "Inspection",
        sub: "pre",
        step: "exterior-taxi-front",
        overlayImage: "taxi/Front_SA_Taxi_1.svg",
        overlayImageSize: "90vw",
        overlayImagePosition: "40%",
        overlayImagePositionLeft: "3%",
        side: "FRONT",
        label: "Front",
        area: "FRONT",
        subArea: "FRONT_TAXI",
        skipBBOX: true,
        skipCount: false,
    },
    {
        type: "Inspection",
        sub: "pre",
        step: "exterior-taxi-front-passenger-corner",
        overlayImage: "taxi/Front_Passenger_SA_Taxi_12.svg",
        overlayImageSize: "90vw",
        overlayImagePosition: "40%",
        overlayImagePositionLeft: "3%",
        side: "FRONT_PASSENGER_CORNER",
        label: "Front Passenger Corner",
        area: "PASSENGER_SIDE",
        subArea: "FRONT_PASSENGER_CORNER",
        skipBBOX: true,
        skipCount: false,
    },
    {
        type: "Inspection",
        sub: "pre",
        step: "exterior-taxi-passenger-side-front",
        overlayImage: "taxi/Passenger_Side_SA_Taxi_11.svg",
        overlayImageSize: "70vw",
        overlayImagePosition: "40%",
        overlayImagePositionLeft: "3%",
        side: "PASSENGER_SIDE_FRONT",
        label: "Passenger Side Front",
        area: "PASSENGER_SIDE",
        subArea: "PASSENGER_SIDE_FRONT",
        skipBBOX: true,
        skipCount: false,
    },
    {
        type: "Inspection",
        sub: "pre",
        step: "exterior-taxi-passenger-side-mid",
        overlayImage: "taxi/Passenger_Mid_section_SA_Taxi_10.svg",
        overlayImageSize: "65vw",
        overlayImagePosition: "40%",
        overlayImagePositionLeft: "3%",
        side: "PASSENGER_SIDE_MID",
        label: "Passenger Side Mid",
        area: "PASSENGER_SIDE",
        subArea: "PASSENGER_SIDE_MID",
        skipBBOX: true,
        skipCount: false,
    },
    {
        type: "Inspection",
        sub: "pre",
        step: "exterior-taxi-passenger-side-rear",
        overlayImage: "taxi/Passenger_Back_section_SA_Taxi_9.svg",
        overlayImageSize: "80vw",
        overlayImagePosition: "40%",
        overlayImagePositionLeft: "3%",
        side: "PASSENGER_SIDE_REAR",
        label: "Passenger Side Rear",
        area: "PASSENGER_SIDE",
        subArea: "PASSENGER_SIDE_REAR",
        skipBBOX: true,
        skipCount: false,
    },
    {
        type: "Inspection",
        sub: "pre",
        step: "exterior-taxi-rear-passenger-corner",
        overlayImage: "taxi/Passenger_Back_SA_Taxi_8.svg",
        overlayImageSize: "90vw",
        overlayImagePosition: "40%",
        overlayImagePositionLeft: "3%",
        side: "REAR_PASSENGER_CORNER",
        label: "Rear Passenger Corner",
        area: "PASSENGER_SIDE",
        subArea: "REAR_PASSENGER_CORNER",
        skipBBOX: true,
        skipCount: false,
    },
    {
        type: "Inspection",
        sub: "pre",
        step: "exterior-taxi-rear",
        overlayImage: "taxi/Back_SA_Taxi_7.svg",
        overlayImageSize: "95vw",
        overlayImagePosition: "40%",
        overlayImagePositionLeft: "3%",
        side: "REAR",
        label: "Rear",
        area: "REAR",
        subArea: "REAR_TAXI",
        skipBBOX: true,
        skipCount: false,
    },
    {
        type: "Inspection",
        sub: "pre",
        step: "exterior-taxi-rear-driver-corner",
        overlayImage: "taxi/Driver_Back_SA_Taxi_6.svg",
        overlayImageSize: "90vw",
        overlayImagePosition: "40%",
        overlayImagePositionLeft: "3%",
        side: "REAR_DRIVER_CORNER",
        label: "Rear Driver Corner",
        area: "DRIVER_SIDE",
        subArea: "REAR_DRIVER_CORNER",
        skipBBOX: true,
        skipCount: false,
    },
    {
        type: "Inspection",
        sub: "pre",
        step: "exterior-taxi-driver-side-rear",
        overlayImage: "taxi/Driver_Back_Section_SA_Taxi_5.svg",
        overlayImageSize: "80vw",
        overlayImagePosition: "40%",
        overlayImagePositionLeft: "3%",
        side: "DRIVER_SIDE_REAR",
        label: "Driver Side Rear",
        area: "DRIVER_SIDE",
        subArea: "DRIVER_SIDE_REAR",
        skipBBOX: true,
        skipCount: false,
    },
    {
        type: "Inspection",
        sub: "pre",
        step: "exterior-taxi-driver-side-mid",
        overlayImage: "taxi/Driver_Mid_Section_SA_Taxi_4.svg",
        overlayImageSize: "65vw",
        overlayImagePosition: "40%",
        overlayImagePositionLeft: "3%",
        side: "DRIVER_SIDE_MID",
        label: "Driver Side Mid",
        area: "DRIVER_SIDE",
        subArea: "DRIVER_SIDE_MID",
        skipBBOX: true,
        skipCount: false,
    },

    {
        type: "Inspection",
        sub: "pre",
        step: "exterior-taxi-driver-side-front",
        overlayImage: "taxi/Driver_Side_SA_Taxi_3.svg",
        overlayImageSize: "70vw",
        overlayImagePosition: "40%",
        overlayImagePositionLeft: "3%",
        side: "DRIVER_SIDE_FRONT",
        label: "Driver Side Front",
        area: "DRIVER_SIDE",
        subArea: "DRIVER_SIDE_FRONT",
        skipBBOX: true,
        skipCount: false,
    },
    {
        type: "Inspection",
        sub: "pre",
        step: "exterior-taxi-front-driver-corner",
        overlayImage: "taxi/Driver_Front_SA_Taxi_2.svg",
        overlayImageSize: "90vw",
        overlayImagePosition: "40%",
        overlayImagePositionLeft: "3%",
        side: "FRONT_DRIVER_CORNER",
        label: "Front Driver Corner",
        area: "DRIVER_SIDE",
        subArea: "FRONT_DRIVER_CORNER",
        skipBBOX: true,
        skipCount: false,
    },
    {
        type: "Inspection",
        sub: "pre",
        step: "exterior-taxi-pdp-scan-taxi",
        overlayImage: "taxi/PDP_Disc_SA_Taxi_14.svg",
        overlayImageSize: "80vw",
        overlayImagePosition: "40%",
        overlayImagePositionLeft: "3%",
        side: "PDP_SCAN_TAXI",
        label: "PDP Scan",
        area: "PDP_SCAN",
        subArea: "PDP_SCAN",
        skipBBOX: true,
        skipCount: false,
    },
    {
        type: "Inspection",
        sub: "pre",
        step: "exterior-taxi-emergency-exit",
        overlayImage: "taxi/Emergency_Exit_SA_Taxi_13.svg",
        overlayImageSize: "90vw",
        overlayImagePosition: "40%",
        overlayImagePositionLeft: "3%",
        side: "EMERGENCY_EXIT",
        label: "Emergency Exit",
        area: "EMERGENCY_EXIT",
        subArea: "EMERGENCY_EXIT",
        skipBBOX: true,
        skipCount: false,
    }
]

export default exteriorTaxiPreInspection;