import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Container,
  IconButton,
  Button,
  Grid,
  Item,
  CssBaseline,
  MuiThemeProvider,
} from "@material-ui/core";
import UploadItem from "../UploadItem";
import { Link, useHistory } from "react-router-dom";
import { useStyles } from "../../Journey/styles";
import Logo from "../Logos";
import { getFromStore, setToStore } from "../../Stores/utils";
import RoundedButton from "../RoundedButton";
import CSAItheme from "../../Theme";
import {
  VIEVA_PREINSPECTION,
  PRE_INSPECTION,
  TELESURE_CLAIM_OVERLAYS,
} from "./config";
import DeleteForever from "@material-ui/icons/DeleteForever";
import CancelIcon from "@material-ui/icons/HighlightOff";
import SelectIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorMessage from "../ErrorMessage";
import WaitingStatus from "../../Stores/WaitingStatus";
import useSubject from "../../hooks/useSubject";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { UpdateImageFileMetaDataArea$ } from "../../APIConfig/UpdateImageFileMetaDataArea";
import { MANDATORY_FOUR_SIDES_INTERNAL_UPLOAD } from "../../mockconfig/configs";
const STAGES = ["UPLOAD", "SELECT"];

const MANDATORY_FOR_TELESURE = [
  "LICENSE",
  "ODOMETER",
  "FRONT_SIDE",
  "REAR_SIDE",
  "PASSENGER_SIDE",
  "DRIVER_SIDE",
];
const MANDATORY_FOR_GENERAL = ["LICENSE", "ODOMETER"];
var MANDATORY_SELECTED = new Array(MANDATORY_FOR_TELESURE.length).fill(false);

export default function UploadScreen() {
  const companyData = getFromStore("CompanyStore");
  const AssessmentType = getFromStore("AssessmentType");
  const history = useHistory();
  const [imagesSelected, setSelectedImages] = React.useState([]);
  const [stage, setStage] = React.useState(STAGES[0]);
  const [imagesUploaded, setImagesUploaded] = React.useState([]);

  let OVERLAYS =
    AssessmentType?.type === "CLAIM_SUBMISSION"
      ? TELESURE_CLAIM_OVERLAYS
      : PRE_INSPECTION;
  if (companyData?.otp === "2575") {
    OVERLAYS = TELESURE_CLAIM_OVERLAYS;
  }
  if (companyData?.otp === "1547") {
    OVERLAYS = VIEVA_PREINSPECTION;
  }
  const whiteLabel = companyData ? companyData.themes : null;

  const [nextStatus, setNextStatus] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [isTelesureError, setIsTelesureError] = React.useState(true);
  const [isMinimunUploadError, setIsMinimumUploadError] = React.useState(true);
  const [metadataCount, setMetadataCount] = useState();
  const [waiting, setWaiting] = useState(false);
  const goToUploadScreen = () => {
    setStage(STAGES[0]);
  };
  const uploadimgdata = async (imgdata, i, len) => {
    UpdateImageFileMetaDataArea$(
      imgdata.otp,
      imgdata.assessment_id,
      "b04d837c-3539-430e-b9ae-159dcbe1e96b",
      imgdata.id,
      imgdata.geoLocation,
      {
        height: imgdata.size.height,
        width: imgdata.size.width,
      },
      {
        height: imgdata.size.height,
        width: imgdata.size.width,
      },
      imgdata.tags,
      true
    ).then((resp) => {
      if (resp.status > 200 && resp.status < 300) {
      } else {
        setErrors(["Unkown error"]);
        return;
      }
      if (i === len - 1) {
        setTimeout(submit, 5000);
        // history.push("journey/Offboard/Submitdata");
      }
    });
  };
  const submit = () => {
    setWaiting(false);
    history.push("journey/Offboard/Submitdata");
  };
  const Loader = () => {
    const classes = useStyles();
    return (
      <Box zIndex={2000} style={{ zIndex: 2000 }}>
        <Backdrop
          className={classes.backdrop}
          open={waiting}
          zIndex={2000}
          style={{ zIndex: 2000 }}
        >
          <CircularProgress
            color="inherit"
            thickness={4}
            size={60}
            zIndex={2000}
            style={{ zIndex: 2000 }}
          />
        </Backdrop>
      </Box>
    );
  };
  const errorState = () => {
    // if (nextStatus === false) return;
    if (
      MANDATORY_FOUR_SIDES_INTERNAL_UPLOAD.includes(companyData.otp) &&
      isTelesureError
    ) {
      setErrors([
        "Items in first row and atleast one car part image is mandatory!",
      ]);
      return;
    }
    if (companyData?.otp !== "2575" && companyData?.otp !== "1547") {
      if (isMinimunUploadError) {
        setErrors(["License, Odometer and at least one Car image is required"]);
        return;
      }
    }
    if (companyData?.otp === "1547") {
      setErrors(["Please upload all the images."]);
      return;
    }
  };
  const goToNextScreen = () => {
    setWaiting(true);
    setMetadataCount(imagesUploaded.length);
    imagesUploaded.forEach((img, i) => {
      uploadimgdata(img.data, i, imagesUploaded.length);
    });

    if (MANDATORY_FOUR_SIDES_INTERNAL_UPLOAD.includes(companyData.otp)) {
      let claimStore = [];
      let i;
      for (i = 0; i < OVERLAYS.length; i++) {
        let imageDetails = getFromStore(OVERLAYS[i].side);
        if (
          imageDetails &&
          !imageDetails.deleted &&
          !MANDATORY_FOR_TELESURE.includes(OVERLAYS[i].side)
        ) {
          claimStore.push(OVERLAYS[i]);
        }
      }
      setToStore("ClaimsStore", claimStore);
    } else if (
      AssessmentType.type === "CLAIM_SUBMISSION" &&
      companyData?.otp !== "2575"
    ) {
      let claimStore = [];
      let i;
      for (i = 0; i < OVERLAYS.length; i++) {
        let imageDetails = getFromStore(OVERLAYS[i].side);
        if (
          imageDetails &&
          !imageDetails.deleted &&
          !MANDATORY_FOR_GENERAL.includes(OVERLAYS[i].side)
        ) {
          claimStore.push(OVERLAYS[i]);
        }
      }
      setToStore("ClaimsStore", claimStore);
    }
  };

  const toggleSelect = (area) => {
    const tempSelected = [...imagesSelected];
    if (tempSelected.includes(area)) {
      let index = tempSelected.findIndex((item) => item === area);
      if (index !== -1) {
        tempSelected.splice(index, 1);
      }
    } else {
      tempSelected.push(area);
    }
    setSelectedImages(tempSelected);
  };

  const deleteSelected = () => {
    const deletedIds = imagesSelected
      .filter((area) => {
        const item = getFromStore(area);
        if (item) {
          item.deleted = true;
          setToStore(area, item);
          return true;
        }
        return false;
      })
      .map((area) => getFromStore(area).id);
    setImagesUploaded(
      imagesUploaded.filter((i) => !deletedIds.includes(i.data.id))
    );
    checkStatusOfUpload();
  };

  //   React.useEffect(() => {
  //     checkStatusOfUpload();
  //   }, []);

  const checkStatusOfUpload = () => {
    if (MANDATORY_FOUR_SIDES_INTERNAL_UPLOAD.includes(companyData.otp)) {
      const licensee_details = getFromStore("LICENSE");
      if (licensee_details && !licensee_details.deleted) {
        let i = 0;
        let count = 0;
        for (i = 0; i < OVERLAYS.length; i++) {
          let imageDetails = getFromStore(OVERLAYS[i].side);
          if (
            imageDetails &&
            !imageDetails.deleted &&
            !MANDATORY_FOR_TELESURE.includes(OVERLAYS[i].side)
          ) {
            count++;
          }
          if (
            imageDetails &&
            !imageDetails.deleted &&
            MANDATORY_FOR_TELESURE.includes(OVERLAYS[i].side)
          ) {
            MANDATORY_SELECTED[
              MANDATORY_FOR_TELESURE.indexOf(OVERLAYS[i].side)
            ] = true;
          }
        }

        // checking if one things except mandatory items and checking if all the mandatory items have been uploaded
        if (count >= 1 && !MANDATORY_SELECTED.includes(false)) {
          setIsTelesureError(false);
          setNextStatus(true);
          return;
        } else {
          setIsTelesureError(true);
          setNextStatus(false);
          return;
        }
      } else {
        setIsTelesureError(true);
        setNextStatus(false);
        return;
      }
    } else if (companyData.otp === "1547") {
      let status = false;
      let i = 0;
      for (i = 0; i < OVERLAYS.length; i++) {
        let imageDetails = getFromStore(OVERLAYS[i].side);
        if (imageDetails) {
          if (imageDetails.deleted) {
            status = false;
            break;
          }
        } else {
          status = false;
          break;
        }
      }
      if (i === OVERLAYS.length) {
        status = true;
      }
      setNextStatus(status);
      return;
    } else {
      const licensee_details = getFromStore("LICENSE");
      if (licensee_details && !licensee_details.deleted) {
        let i = 0;
        let count = 0;
        var selected = new Array(MANDATORY_FOR_GENERAL.length).fill(false);
        for (i = 0; i < OVERLAYS.length; i++) {
          let imageDetails = getFromStore(OVERLAYS[i].side);
          if (
            imageDetails &&
            !imageDetails.deleted &&
            !MANDATORY_FOR_GENERAL.includes(OVERLAYS[i].side)
          ) {
            count++;
          }
          if (
            imageDetails &&
            !imageDetails.deleted &&
            MANDATORY_FOR_GENERAL.includes(OVERLAYS[i].side)
          ) {
            selected[MANDATORY_FOR_GENERAL.indexOf(OVERLAYS[i].side)] = true;
          }
        }

        // checking if one things except mandatory items and checking if all the mandatory items have been uploaded
        if (count >= 1 && !selected.includes(false)) {
          setIsMinimumUploadError(false);
          setNextStatus(true);
        } else {
          setIsMinimumUploadError(true);
          setNextStatus(false);
        }
      } else {
        setIsMinimumUploadError(true);
        setNextStatus(false);
      }
    }
  };

  const MainComponent = (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <CssBaseline />
      {errors.length !== 0 && (
        <ErrorMessage
          errors={errors}
          onClick={() => setErrors([])}
        ></ErrorMessage>
      )}
      <Box
        style={{
          textAlign: "center",
          marginTop: "15px",
          marginBottom: "10px",
        }}
      >
        {stage === STAGES[0] && (
          <Button
            variant="outlined"
            style={{
              position: "absolute",
              left: 45,
              textTransform: "none",
            }}
            onClick={() => {
              // setSelectedImages(AREAS);
              setStage(STAGES[1]);
            }}
          >
            <SelectIcon
              style={{
                color: "green",
                fontSize: "16",
                marginTop: "-2",
                marginRight: "5",
              }}
            />
            <Typography variant="body" className="text">
              Select
            </Typography>
          </Button>
        )}
        {stage === STAGES[1] && (
          <Button
            variant="outlined"
            style={{
              position: "absolute",
              left: 45,
              textTransform: "none",
            }}
            onClick={() => {
              setSelectedImages([]);
              setStage(STAGES[0]);
            }}
          >
            <CancelIcon
              style={{
                color: "black",
                fontSize: "16",
                marginTop: "-2",
                marginRight: "5",
              }}
            />
            <Typography variant="body" className="text">
              Cancel
            </Typography>
          </Button>
        )}
        {stage === STAGES[1] && (
          <Button
            variant="outlined"
            style={{ position: "absolute", right: 45, textTransform: "none" }}
            onClick={() => {
              setSelectedImages([]);
              setStage(STAGES[0]);
              deleteSelected();
            }}
          >
            <DeleteForever
              style={{
                color: "black",
                fontSize: "16",
                marginTop: "-2",
                marginRight: "5",
              }}
            />
            <Typography variant="body" className="text">
              Delete
            </Typography>
          </Button>
        )}
        <Typography variant="h6" className="text">
          {"Upload Images"}
        </Typography>
      </Box>
      <Box
        height={"55vh"}
        width={"90%"}
        style={{ overflowY: "auto", overflowX: "hidden" }}
      >
        <Grid container spacing={1}>
          {OVERLAYS.map((side, i) => {
            return (
              <Grid key={i} item xs={2}>
                <UploadItem
                  imagesSelected={imagesSelected}
                  toggleSelect={toggleSelect}
                  setSelectedImages
                  stage={stage}
                  title={side.label}
                  area={side.area}
                  subArea={side.subArea}
                  img={side.overlayImage}
                  updateStatus={checkStatusOfUpload}
                  side={side.side}
                  setErrors={setErrors}
                  imagesUploaded={imagesUploaded}
                  setImagesUploaded={setImagesUploaded}
                  setWaiting={setWaiting}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Box>
        {(stage === STAGES[0] || stage === STAGES[1]) && (
          <RoundedButton
            label={"Next"}
            onClick={nextStatus ? goToNextScreen : errorState}
            size="medium"
            width="30vw"
            fontWeight="800"
          ></RoundedButton>
        )}
      </Box>
    </Box>
  );

  return (
    <>
      {Loader()}
      {whiteLabel ? (
        <MuiThemeProvider theme={CSAItheme(whiteLabel)}>
          {MainComponent}
        </MuiThemeProvider>
      ) : (
        <MuiThemeProvider>{MainComponent}</MuiThemeProvider>
      )}
    </>
  );
}
