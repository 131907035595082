import { Box } from "@material-ui/core";
import React, { useMemo } from "react";
import { common } from "../../mockconfig/quote";
import { preRenderImageArray } from "../../utils/helpers";
import { carParts } from "../Journey/Onboard/Damageparts/carparts";

const getFTUEandUpNextAssets = () => {
  const images = [];
  preRenderImageArray().forEach((imgSrc) => {
    images.push(<img alt="overlaytwo" src={`${imgSrc}`}></img>);
  });

  return images;
};

const getOverlayImages = () => {
  const images = [];
  common().forEach((imgSrc) => {
    if (imgSrc.overlayImage) {
      images.push(
        <img
          alt="overlaytwo"
          src={`/images/overlays/${imgSrc.overlayImage}`}
        ></img>
      );
    }
  });
  common("V4").forEach((imgSrc) => {
    if (imgSrc.overlayImage) {
      images.push(
        <img
          alt="overlay"
          src={`/images/overlays/${imgSrc.overlayImage}`}
        ></img>
      );
    }
  });
  return images;
};

const getCarpartImages = () => {
  const images = [];
  //   /images/carparts/${props.damagePartSection}/${props.img}
  carParts.exterior.forEach((imgSrc) => {
    if (imgSrc.img) {
      images.push(
        <img
          alt="exterior"
          src={`/images/carparts/exterior/${imgSrc.img}`}
        ></img>
      );
    }
  });
  carParts.interior.forEach((imgSrc) => {
    if (imgSrc.img) {
      images.push(
        <img
          alt="interior"
          src={`/images/carparts/interior/${imgSrc.img}`}
        ></img>
      );
    }
  });
  return images;
};

const PreFetch = () => {
  console.log(common);
  return (
    <React.Fragment>
      <Box style={{ display: "none" }}>
        {getOverlayImages()}
        {getCarpartImages()} {getFTUEandUpNextAssets()}
        {/* {common.forEach(imgSrc => {
              return <img src={`/images/overlays/${imgSrc.overlayImage}`}></img>
          })} */}
      </Box>
    </React.Fragment>
  );
};

export default React.memo(PreFetch);
