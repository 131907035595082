import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
const BREAKPOINTS = {
  xs: 0,
  sm: 530, //landscape mode for iphone 5 and nokia lumina 520 is around 530 width
  md: 720,
  lg: 960,
  xl: 1920,
};

const breakpointsFull = {
  breakpoints: createBreakpoints({
    values: BREAKPOINTS,
  }),
};

const CSAItheme = (tokens) => {
  console.log("TOKENS", tokens);
  const theme = createMuiTheme(
    {
      // spacing: "1vw",
      spacing: (factor) => {
        if (window.innerHeight <= 280) {
          return factor * 6;
        }
        if (window.innerHeight <= 360) {
          return factor * 8;
        }
        if (window.innerHeight <= 440) {
          return factor * 10;
        }
        return factor * 10;

        // if (window.innerHeight < window.innerWidth) {
        //   return Math.ceil(factor * window.innerHeight * 0.02);
        // } else {
        //   return Math.ceil(factor * window.innerWidth * 0.02);
        // }
        // if(window.innerWidth >= 1024) {
        //   return factor * 8 * 2.5
        // }
        // if(window.innerWidth >= 844 && window.innerWidth < 1024) {
        //   return factor * 8 * 1.5
        // }
        // if(window.innerWidth < 844) {
        //   return factor * 8
        // }
      },
      typography: {
        fontFamily: [
          "Avenir",
          "Robot",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        // fontSize: 14,
        // htmlFontSize: window.innerWidth * .02,
        // breakpoints: {
        //   values: {
        //     xs: 0,
        //     sm: 600,
        //     md: 720,
        //     lg: 960,
        //     xl: 1920,
        //   },
        // },
        body1: {
          "@media only screen and (min-width: 0px) and (max-width: 600px) and (orientation: landscape)":
          {
            fontSize: `.75rem`,
          },
          "@media only screen and (min-width: 600px) and (max-width: 800px) and (orientation: landscape)":
          {
            fontSize: `.875rem`,
          },
          "@media only screen and (min-width: 800px) and (max-width: 960px) and (orientation: landscape)":
          {
            fontSize: `1rem`,
          },
          "@media only screen and (min-width: 960px) and (max-width: 1024px) and (orientation: landscape)":
          {
            fontSize: `1.5rem`,
          },
          "@media only screen (min-width: 1024px) and (orientation: landscape)":
          {
            fontSize: `2.5rem`,
          },
        },
        body2: {
          "@media only screen and (min-width: 0px) and (max-width: 600px) and (orientation: landscape)":
          {
            fontSize: `0.5625rem`,
          },
          "@media only screen and (min-width: 600px) and (max-width: 800px) and (orientation: landscape)":
          {
            fontSize: `.75rem`,
          },
          "@media only screen and (min-width: 800px) and (max-width: 960px) and (orientation: landscape)":
          {
            fontSize: `.875rem`,
          },
          "@media only screen and (min-width: 960px) and (max-width: 1024px) and (orientation: landscape)":
          {
            fontSize: `1rem`,
          },
          "@media only screen (min-width: 1024px) and (orientation: landscape)":
          {
            fontSize: `2rem`,
          },
        },
        h6: {
          "@media only screen and (min-width: 0px) and (max-width: 600px) and (orientation: landscape)":
          {
            fontSize: `1rem`,
          },
          "@media only screen and (min-width: 600px) and (max-width: 800px) and (orientation: landscape)":
          {
            fontSize: `1.125rem`,
          },
          "@media only screen and (min-width: 800px) and (max-width: 960px) and (orientation: landscape)":
          {
            fontSize: `1.25rem`,
          },
          "@media only screen and (min-width: 960px) and (max-width: 1024px) and (orientation: landscape)":
          {
            fontSize: `2.25rem`,
          },
          "@media only screen (min-width: 1024px) and (orientation: landscape)":
          {
            fontSize: `3.5rem`,
          },
        },
        caption: {
          "@media only screen and (min-width: 0px) and (max-width: 600px) and (orientation: landscape)":
          {
            fontSize: `0.4375rem`,
          },
          "@media only screen and (min-width: 600px) and (max-width: 800px) and (orientation: landscape)":
          {
            fontSize: `0.625rem`,
          },
          "@media only screen and (min-width: 800px) and (max-width: 960px) and (orientation: landscape)":
          {
            fontSize: `0.75rem`,
          },
          "@media only screen and (min-width: 960px) and (max-width: 1024px) and (orientation: landscape)":
          {
            fontSize: `0.875rem`,
          },
          "@media only screen (min-width: 1024px) and (orientation: landscape)":
          {
            fontSize: `1.5rem`,
          },
        },
      },
      palette: {
        primary: {
          main: `#${tokens.primary || "EDA03A"}`,
          contrastText: `#${tokens.primaryText || "FFFFFF"}`,
        },
        secondary: {
          main: "#7EF424",
        },
      },
      overrides: {
        MUI: {
          selected: {
            backgroundColor: "#F00",
          },
        },
        MuiCardMedia: {
          img: {
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
          },
        },
        MuiToggleButtonGroup: {
          root: {
            width: "80%",
            padding: "5px"
          },
        },
        MuiToggleButton: {
          root: {
            // backgroundColor: "rgb(255,255,255,.3)",
            // backgroundColor: "rgb(0,0,0,.1)",
            border: "none",
            color: "#fff",
            padding: "4px",
            textTransform: "none",
            width: "50%",
            lineHeight: "1",
            "&:hover": {
              // backgroundColor: "rgb(255,255,255,0.5)",
              backgroundColor: "rgb(0,0,0,.3)",
              color: "#fff",
            },
            fontWeight: 700,
            "&$selected": {
              backgroundColor: "#7EF424",
              borderRadius: "8px",
              color: "#000",
              "&:hover": {
                backgroundColor: "#7EF424",
                color: "#000",
              },
            },
          },
        },
        MuiDialogTitle: {
          root: {
            padding: 0,
          },
        },
        MuiOutlinedInput: {
          inputMarginDense: {
            paddingTop: ".5vw",
            paddingBottom: ".5vw",
          },
        },
        MuiButton: {
          containedPrimary: {
            color: "#FFFFFF",
            fontSize: "1rem",
            fontWeight: 700,
          },
          textSecondary: {
            color: "#FFF",
          },
        },
      },
    },
    breakpointsFull
  );
  return responsiveFontSizes(theme);
};

export default CSAItheme;
