import React, { useState } from "react";
import styles from "./index.module.css";
import ErrorMessage from "../ErrorMessage";
import { useHistory } from "react-router-dom";

const NoNetwork = () => {
  const [errors, setErrors] = useState([]);
  const history = useHistory();
  const nextInfo = () => {
    if (navigator && navigator.onLine) history.goBack();
    else {
      setErrors(["Please turn on your internet to proceed."]);
    }
  };

  return (
    <div className={styles.wrapper}>
      <ErrorMessage
        errors={errors}
        onClick={() => setErrors([])}
      ></ErrorMessage>
      <img
        src="/images/errors/noNetwork.svg"
        alt="nonetwork"
        className={styles.image}
        style={{
          width:'28vh',
          height:'28vh'

        }}
      />
      <span className={styles.header}>No Internet Detected</span>
      <span className={styles.text}>Your assessment data is saved.</span>
      <button className={styles.ftueNextButton} onClick={nextInfo}>
        Go Back
      </button>
    </div>
  );
};

export default NoNetwork;
