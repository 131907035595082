import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Logo from "../../Logos";
import styles from "./noninteractive.module.css";
import { getFromStore } from "../../../Stores/utils";

const Noninteractive = (props) => {
    const companyDetails = getFromStore("CompanyStore");

    return (
        <Box className={styles.backgroundOrientation}>
            <Box className={styles.headerWrapper}>
                <Logo
                    type="orientation"
                    imageURL={
                        companyDetails
                            ? companyDetails.logo_file_path
                            : "/images/Icons/Carscan_logo_white.png"
                    }
                    className={styles.companyLogo}
                />
            </Box>
            <Box className={styles.contentWrapper}>
                <Typography variant="h5" className={styles.fontType}>
                    Please change the orientation to{" "}
                    {props.isPortrait ? "portrait" : "landscape"}
                </Typography>
                <img
                    alt="orientation"
                    src="/images/orientation.svg"
                    className={styles.orientationIcon}
                />
                <Typography variant="h5" className={styles.fontType}>
                    {`Tip:\nPlease ensure that the auto-rotate feature is enabled in phone settings`}
                </Typography>
            </Box>

        </Box>
    );
};

export default Noninteractive;
