const exteriorVideoInspection = (journey) => {
  return [
    {
      type: "Inspection",
      sub: "videoinspection",
      step: "exterior-front-passenger-side",
      overlayImage: "exterior/v3/Front_PassengerSide.png",
      overlayImageSize: "61%",
      overlayImagePosition: "27%",
      overlayImagePositionLeft: "-5px",
      side: "FRONT_PASSENGER_SIDE",
      label: "Passenger Side Front Half",
      area: "PASSENGER_SIDE_FRONT_HALF",
      subArea: "PASSENGER_SIDE_FRONT_HALF",
      skipBBOX: true,
      skipCount: false,
      videoLabel: "Front_Passenger_Side",
      name: "Passenger Side Front Half",
      videoOutlineOverlay: "video_inspection/front_passenger.png",
      startPoint: "front left head light",
      endPoint: "mid passenger side",
    },
    // // Passenger Side
    {
      type: "Inspection",
      sub: "videoinspection",
      step: "exterior-passenger-side",
      overlayImage: "exterior/v3/PassengerSidePanel.png",
      overlayImageSize: "93%",
      overlayImagePosition: "8%",
      overlayImagePositionLeft: "4%",
      side: "REAR_PASSENGER_SIDE",
      label: "Passenger Side Rear Half",
      area: "PASSENGER_SIDE_REAR_HALF",
      subArea: "PASSENGER_SIDE_REAR_HALF",
      skipBBOX: true,
      skipCount: false,
      videoLabel: "Rear_Passenger_Side",
      name: "Passenger Side Rear Half",
      videoOutlineOverlay: "video_inspection/rear_passenger.png",
      startPoint: "passenger side",
      endPoint: "left tail light",
    },
    // // Rear Passenger Side
    {
      type: "Inspection",
      sub: "videoinspection",
      step: "exterior-rear-passenger-side",
      overlayImage: "exterior/v3/Rear_PassengerSide.png",
      overlayImageSize: "63%",
      overlayImagePosition: "25%",
      overlayImagePositionLeft: "4%",
      side: "REAR_SIDE",
      label: "Rear Side",
      area: "REAR",
      subArea: "REAR",
      skipBBOX: true,
      skipCount: false,
      videoLabel: "Rear_Side",
      name: "Rear Side",
      videoOutlineOverlay: "video_inspection/rear.png",
      startPoint: "left tail light",
      endPoint: "right tail light",
    },

    {
      type: "Inspection",
      sub: "videoinspection",
      step: "exterior-rear-driver-side",
      overlayImage: "exterior/v3/Rear_DriverSide.png",
      overlayImageSize: "63%",
      overlayImagePosition: "25%",
      overlayImagePositionLeft: "4%",
      side: "REAR_DRIVER_SIDE",
      label: "Driver Side Rear Half",
      area: "DRIVER_SIDE_REAR_HALF",
      subArea: "DRIVER_SIDE_REAR_HALF",
      skipBBOX: true,
      skipCount: false,
      videoLabel: "Rear_Driver_Side",
      name: "Driver Side Rear Half",
      videoOutlineOverlay: "video_inspection/rear_driver.png",
      startPoint: "right tail light",
      endPoint: "mid driver side",
    },
    // // Driver Side
    {
      type: "Inspection",
      sub: "videoinspection",
      step: "exterior-driver-side",
      overlayImage: "exterior/v3/DriverSidePanel.png",
      overlayImageSize: "93%",
      overlayImagePosition: "8%",
      overlayImagePositionLeft: "4%",
      side: "FRONT_DRIVER_SIDE",
      label: "Driver Side Front Half",
      area: "DRIVER_SIDE_FRONT_HALF",
      subArea: "DRIVER_SIDE_FRONT_HALF",
      skipBBOX: true,
      skipCount: false,
      videoLabel: "Front_Driver_Side",
      name: "Driver Side Front Half",
      videoOutlineOverlay: "video_inspection/front_driver.png",
      startPoint: "driver side",
      endPoint: "right head light",
    },
    // // Front Driver Side
    {
      type: "Inspection",
      sub: "videoinspection",
      step: "exterior-front-driver-side",
      overlayImage: "exterior/v3/Front_DriverSide.png",
      overlayImageSize: "61%",
      overlayImagePosition: "27%",
      overlayImagePositionLeft: "5%",
      side: "FRONT_SIDE",
      label: "Front Side",
      area: "FRONT",
      subArea: "FRONT",
      skipBBOX: true,
      skipCount: false,
      videoLabel: "Front_Side",
      name: "Front Side",
      videoOutlineOverlay: "video_inspection/front.png",
      startPoint: "right head light",
      endPoint: "left head light",
    },
  ];
};

export default exteriorVideoInspection;
