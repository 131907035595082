import React from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

const RoundedButton = (props) => {
  const handleClick = () => {
    props.onClick();
  };
  console.log("propsdesign", props);
  // const width = props.width || "10vw";
  return (
    <Button
      variant={props.variant}
      color={props.color}
      fullWidth={props.fullWidth}
      size={props.size}

      style={{
        margin : props.margin,
        position: props.position,
        bottom:props.bottom,
        right:props.right,
        width: props.width,
        borderRadius: "4px",
        height: props.height,
        textTransform: "none",
        opacity: props.disabled ? ".26" : "1"

      }}
      onClick={handleClick}
      // onMouseDown={handleClick}
      // onTouchStart={handleClick}
      // disabled={props.disabled}
      disableElevation
    >
      <Box
        component="span"
        fontWeight={props.fontWeight}
        fontSize={props.fontSize}
      >
        {props.label}
      </Box>
    </Button>
  );
};

RoundedButton.defaultProps = {
  label: "",
  color: "primary",
  onClick: () => {},
  fullWidth: false,
  size: "medium",
  variant: "contained",
  disabled: false,
  width: "10vw",
  height: "40px",
  fontWeight: 500,
  // margin : "auto",
  // position: "auto",
  // bottom:"auto",
  // right:"auto"
};

export default RoundedButton;
