const exteriorSUVInspection = () => [
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-suv-front-half-driver-side",
    overlayImage: "SUV/land_cruiser_front_Driver Side.svg",
    overlayImageSize: "250%",
    overlayImagePosition: "-60%",
    overlayImagePositionLeft: "25%",
    side: "SUV_FRONT_HALF_DRIVER_SIDE",
    label: "Front Half (Driver Side)",
    area: "SUV_FRONT_HALF_DRIVER_SIDE",
    subArea: "SUV_FRONT_HALF_DRIVER_SIDE",
    skipBBOX: true,
    skipCount: false,
  }, //18

  //windshield
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-windshield",
    overlayImage: "exterior/v3/windshield/wind_shield_front.svg",
    overlayImageSize: "170%",
    overlayImagePosition: "-60%",
    overlayImagePositionLeft: "0",
    side: "FRONT_WINDSHIELD",
    label: "Front Wind Shield",
    area: "FRONT_WINDSHIELD",
    subArea: "FRONT_WINDSHIELD",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-windshield-front-right",
    overlayImage: "exterior/v3/windshield/wind_shield_front_right.svg",
    overlayImageSize: "230%",
    overlayImagePosition: "-120%",
    overlayImagePositionLeft: "25%",
    side: "FRONT_WINDSHIELD_RIGHT",
    label: "Front Wind Shield Front Right",
    area: "FRONT_WINDSHIELD_FRONT_RIGHT",
    subArea: "FRONT_WINDSHIELD_FRONT_RIGHT",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-windshield-front-left",
    overlayImage: "exterior/v3/windshield/wind_shield_front_left.svg",
    overlayImageSize: "230%",
    overlayImagePosition: "-120%",
    overlayImagePositionLeft: "20%",
    side: "FRONT_WINDSHIELD_LEFT",
    label: "Front Wind Shield Front Left",
    area: "FRONT_WINDSHIELD_FRONT_LEFT",
    subArea: "FRONT_WINDSHIELD_FRONT_LEFT",
    skipBBOX: true,
    skipCount: false,
  },
  // // Front Passenger Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-suv-front-half-passenger-side",
    overlayImage: "SUV/land_cruiser_front_Passenger Side.svg",
    overlayImageSize: "250%",
    overlayImagePosition: "-60%",
    overlayImagePositionLeft: "-15%",
    side: "SUV_FRONT_HALF_PASSENGER_SIDE",
    label: "Front Half (Passenger Side)",
    area: "SUV_FRONT_HALF_PASSENGER_SIDE",
    subArea: "SUV_FRONT_HALF_PASSENGER_SIDE",
    skipBBOX: true,
    skipCount: false,
  },
  // Passenger Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-suv-front-passenger-side",
    overlayImage: "SUV/land_cruiser_Passenger Side_Front_ Front left.svg",
    overlayImageSize: "250%",
    overlayImagePosition: "-60%",
    overlayImagePositionLeft: "15%",
    side: "SUV_FRONT_PASSENGER_SIDE",
    label: "Passenger Side",
    area: "SUV_FRONT_PASSENGER_SIDE",
    subArea: "SUV_FRONT_PASSENGER_SIDE",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-suv-passenger-side-front-half",
    overlayImage: "SUV/land_cruiser_Passenger Side_Front.svg",
    overlayImageSize: "250%",
    overlayImagePosition: "-60%",
    overlayImagePositionLeft: "15%",
    side: "SUV_PASSENGER_SIDE_FRONT_HALF",
    label: "Passenger Side Front Half",
    area: "SUV_PASSENGER_SIDE_FRONT_HALF",
    subArea: "SUV_PASSENGER_SIDE_FRONT_HALF",
    skipBBOX: true,
    skipCount: false,
  },
  // // Passenger Side front half
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-suv-passenger-side-front-fender",
    overlayImage: "SUV/land_cruiser_Passenger Side_Front Middle.svg",
    overlayImageSize: "300%",
    overlayImagePosition: "-90%",
    overlayImagePositionLeft: "15%",
    side: "SUV_PASSENGER_SIDE_FRONT_FENDER",
    label: "Passenger Side Front Fender",
    area: "SUV_PASSENGER_SIDE_FRONT_FENDER",
    subArea: "SUV_PASSENGER_SIDE_FRONT_FENDER",
    skipBBOX: true,
    skipCount: false,
  },
  // // Passenger Side rear half
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-suv-passenger-side-rear-fender",
    overlayImage: "SUV/land_cruiser_Passenger Side_Rear Middle.svg",
    overlayImageSize: "300%",
    overlayImagePosition: "-90%",
    overlayImagePositionLeft: "-15%",
    side: "SUV_PASSENGER_SIDE_REAR_FENDER",
    label: "Passenger Side Rear Fender",
    area: "SUV_PASSENGER_SIDE_REAR_FENDER",
    subArea: "SUV_PASSENGER_SIDE_REAR_FENDER",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-suv-passenger-side-rear-half",
    overlayImage: "SUV/land_cruiser_Passenger Side_Back.svg",
    overlayImageSize: "300%",
    overlayImagePosition: "-90%",
    overlayImagePositionLeft: "-15%",
    side: "SUV_PASSENGER_SIDE_REAR_HALF",
    label: "Passenger Side Rear Half",
    area: "SUV_PASSENGER_SIDE_REAR_HALF",
    subArea: "SUV_PASSENGER_SIDE_REAR_HALF",
    skipBBOX: true,
    skipCount: false,
  },
  // // Rear Passenger Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-suv-rear-passenger-side",
    overlayImage: "SUV/land_cruiser_Passenger Side_Back left.svg",
    overlayImageSize: "250%",
    overlayImagePosition: "-70%",
    overlayImagePositionLeft: "-30%",
    side: "SUV_REAR_PASSENGER_SIDE",
    label: "Rear Passenger Side",
    area: "SUV_REAR_PASSENGER_SIDE",
    subArea: "SUV_REAR_PASSENGER_SIDE",
    skipBBOX: true,
    skipCount: false,
  },
  // // Rear Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-suv-rear-half-passenger-side",
    overlayImage: "SUV/land_cruiser_rear_Driver Side.svg",
    overlayImageSize: "300%",
    overlayImagePosition: "-90%",
    overlayImagePositionLeft: "15%",
    side: "SUV_REAR_HALF_PASSENGER_SIDE",
    label: "Rear Half (Passenger Side)",
    area: "SUV_REAR_HALF_PASSENGER_SIDE",
    subArea: "SUV_REAR_HALF_PASSENGER_SIDE",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-suv-rear-half-driver-side",
    overlayImage: "SUV/land_cruiser_rear_Passenger Side.svg",
    overlayImageSize: "300%",
    overlayImagePosition: "-90%",
    overlayImagePositionLeft: "0%",
    side: "SUV_REAR_HALF_DRIVER_SIDE",
    label: "Rear Half (Driver Side)",
    area: "SUV_REAR_HALF_DRIVER_SIDE",
    subArea: "SUV_REAR_HALF_DRIVER_SIDE",
    skipBBOX: true,
    skipCount: false,
  },
  // // Rear Driver Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-suv-rear-driver-side",
    overlayImage: "SUV/land_cruiser_Driver Side_Rear_Back Right.svg",
    overlayImageSize: "250%",
    overlayImagePosition: "-60%",
    overlayImagePositionLeft: "25%",
    side: "SUV_REAR_DRIVER_SIDE",
    label: "Rear Driver Side",
    area: "SUV_REAR_DRIVER_SIDE",
    subArea: "SUV_REAR_DRIVER_SIDE",
    skipBBOX: true,
    skipCount: false,
  },
  // // Driver Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-suv-driver-side-rear-half",
    overlayImage: "SUV/land_cruiser_Driver Side_Back.svg",
    overlayImageSize: "300%",
    overlayImagePosition: "-90%",
    overlayImagePositionLeft: "60%",
    side: "SUV_DRIVER_SIDE_REAR_HALF",
    label: "Driver Side Rear Half",
    area: "SUV_DRIVER_SIDE_REAR_HALF",
    subArea: "SUV_DRIVER_SIDE_REAR_HALF",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-suv-driver-side-rear-fender",
    overlayImage: "SUV/land_cruiser_Driver Side_Rear Middle.svg",
    overlayImageSize: "300%",
    overlayImagePosition: "-90%",
    overlayImagePositionLeft: "25%",
    side: "SUV_DRIVER_SIDE_REAR_FENDER",
    label: "Driver Side Rear Fender",
    area: "SUV_DRIVER_SIDE_REAR_FENDER",
    subArea: "SUV_DRIVER_SIDE_REAR_FENDER",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-suv-driver-side-front-fender",
    overlayImage: "SUV/land_cruiser_Driver Side_Front Middle.svg",
    overlayImageSize: "300%",
    overlayImagePosition: "-90%",
    overlayImagePositionLeft: "-15%",
    side: "SUV_DRIVER_SIDE_FRONT_FENDER",
    label: "Driver Side Front Fender",
    area: "SUV_DRIVER_SIDE_FRONT_FENDER",
    subArea: "SUV_DRIVER_SIDE_FRONT_FENDER",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-suv-driver-side-front-half",
    overlayImage: "SUV/land_cruiser_Driver Side_Front.svg",
    overlayImageSize: "300%",
    overlayImagePosition: "-90%",
    overlayImagePositionLeft: "-15%",
    side: "SUV_DRIVER_SIDE_FRONT_HALF",
    label: "Driver Side Front Half",
    area: "SUV_DRIVER_SIDE_FRONT_HALF",
    subArea: "SUV_DRIVER_SIDE_FRONT_HALF",
    skipBBOX: true,
    skipCount: false,
  },
  // // Front Driver Side
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-suv-front-driver-side",
    overlayImage: "SUV/land_cruiser_Driver side_Front_ Front Right.svg",
    overlayImageSize: "250%",
    overlayImagePosition: "-70%",
    overlayImagePositionLeft: "-15%",
    side: "SUV_FRONT_DRIVER_SIDE",
    label: "Front Driver Side",
    area: "SUV_FRONT_DRIVER_SIDE",
    subArea: "SUV_FRONT_DRIVER_SIDE",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-front-roof",
    overlayImage: "roof/front_roof.svg",
    overlayImageSize: "80%",
    overlayImagePosition: "3%",
    overlayImagePositionLeft: "0",
    side: "FRONT_ROOF",
    label: "Front Roof",
    area: "FRONT_ROOF",
    subArea: "FRONT_ROOF",
    skipBBOX: true,
    skipCount: false,
  },
  {
    type: "Inspection",
    sub: "pre",
    step: "exterior-back-roof",
    overlayImage: "roof/back_roof.svg",
    overlayImageSize: "80%",
    overlayImagePosition: "3%",
    overlayImagePositionLeft: "0",
    side: "BACK_ROOF",
    label: "Back Roof",
    area: "BACK_ROOF",
    subArea: "BACK_ROOF",
    skipBBOX: true,
    skipCount: false,
  },
];

export default exteriorSUVInspection;
