import React from "react";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { Grid, Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useSubject from "../../../../hooks/useSubject";
import LinearProgress from "@material-ui/core/LinearProgress";
import CompanyStore from "../../../../Stores/CompanyStore";
import AssessmentTypeStore$ from "../../../../Stores/AssessmentTypeStore";
import { useStyles } from "../../../../Journey/styles";
import { useState, useEffect } from "react";
import RoundedButton from "../../../RoundedButton";
import { Element } from "../../Offboard/Questionnaire/Element";
import { questionStorePublisher } from "../../../../Stores/QuestionStore";
import QuestionStore$ from "../../../../Stores/QuestionStore";
import { getFromStore } from "../../../../Stores/utils";
import ErrorMessage from "../../../ErrorMessage";
import { Helmet } from "react-helmet";
import Logo from "../../../Logos";
import DeviceOrientationWrapper from "../../../DeviceOrientationWrapper";
import {
  SKIP_EMAIL_REPORT_QUESTION,
  SKIP_EMAILS,
} from "../../../../mockconfig/configs";

const Emailreport = (props) => {
  const { nextAction } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const companyDetails = getFromStore("CompanyStore");
  const [emailRequired, setEmailRequired] = useState(false);
  const [emailRequiredAndValid, setEmailRequiredAndValid] = useState(false);
  const [errors, setErrors] = useState([]);
  const [formElements, setFormElements] = useState(null);
  const assessmentType = useSubject(AssessmentTypeStore$);
  const [ctrackExtra, setCtrackExtra] = useState(false);
  let questions = useSubject(QuestionStore$);
  const isPortrait = companyDetails.is_gcc_journey;
  if (questions == null) {
    questions = getFromStore("QuestionStore");
  }

  useEffect(() => {
    let time = setTimeout(() => {
      if (assessmentType && assessmentType.questions.length !== 0) {
        let newFormElements = [];
        assessmentType.questions.forEach((question) => {
          if (
            question.panel === "report-email" ||
            question.panel === "report-email-extra"
          ) {
            newFormElements.push(question);
          }
        });
        setFormElements(newFormElements);
        if (companyDetails?.otp === "8610") {
          setEmailRequired(true);
          setEmailRequiredAndValid(true);
        }
      } else {
        if (nextAction) {
          nextAction();
        }
      }
    }, 100);

    return () => {
      clearTimeout(time);
    };
  }, [assessmentType, nextAction]);

  useEffect(() => {
    if (companyDetails?.predefined_admin_email) {
      let new_question_value = [];
      let emailId = companyDetails.predefined_admin_email;
      localStorage.setItem("EmailRecieve", true);
      const obj = [
        {
          answers: ["Yes"],
          question_index: "1",
        },
        {
          answers: [emailId],
          question_index: "2",
        },
      ];
      if (questions) {
        new_question_value = [...obj, ...questions?.question_value];
      } else {
        new_question_value = [...obj];
      }
      questionStorePublisher({ question_value: new_question_value });
      if (props.nextAction) {
        props.nextAction();
      }
    }
  }, []);

  const handleCtrackToggle = (event) => {
    if (event.target.innerText === "No") {
      const newElements = [...formElements];
      newElements.forEach((element) => {
        if (element.data_type === "EMAIL") {
          element["question_value"] = undefined;
        }
      });
      setFormElements(newElements);
    }
    setCtrackExtra((prevState) => {
      return !prevState;
    });

    // setEmailRequiredAndValid(true);
  };
  const handleToggle = (event) => {
    if (event.target.innerText === "No") {
      const newElements = [...formElements];
      newElements.forEach((element) => {
        if (element.data_type === "EMAIL") {
          element["question_value"] = undefined;
        }
      });
      setFormElements(newElements);
    }
    setEmailRequired((prevState) => {
      return !prevState;
    });

    setEmailRequiredAndValid(true);
  };

  const handleChange = (id, type, event) => {
    const newElements = [...formElements];
    newElements.forEach((element) => {
      const { data_type, question_id } = element;
      if (id === question_id) {
        if (
          data_type === "STRING" ||
          data_type === "EMAIL" ||
          data_type === "INTEGER"
        ) {
          element["question_value"] = event.target.value;
        }

        switch (type) {
          case "email": {
            const regExp = new RegExp("[a-z0-9]+@[a-z-]+\\.[a-z]{2,3}");
            const isValid = regExp.test(event.target.value);
            if (element["question_value"] === "") {
              element["field_error_message"] = "*This is mandatory field";
              setEmailRequiredAndValid(true);
              return;
            } else if (!isValid) {
              element["field_error_message"] = "Enter valid e-mail address";
              setEmailRequiredAndValid(true);
              return;
            } else {
              element["field_error_message"] = null;
              setEmailRequiredAndValid(false);
              break;
            }
          }
          default:
            break;
        }
      }
    });
    setFormElements(newElements);
  };

  const handleSubmit = () => {
    // event.preventDefault();
    if (emailRequired && emailRequiredAndValid) {
      setErrors(["Please enter valid email address"]);
      return;
    }
    let question_array = [];
    formElements.forEach((elem) => {
      let obj = {};
      if (elem.type === "CHECKBOX" && elem.data_type === "STRING") {
        if (emailRequired) {
          obj.answers = ["Yes"];
          localStorage.setItem("EmailRecieve", true);
        } else {
          obj.answers = ["No"];
        }
      } else {
        obj.answers = [elem.question_value];
      }
      obj.question_index = elem.question_id;
      if (
        companyDetails.otp === "2278" &&
        elem.panel === "report-email-extra" &&
        elem.type === "CHECKBOX"
      ) {
        if (ctrackExtra) {
          obj.answers = ["Yes"];
        } else {
          obj.answers = ["No"];
        }
      }
      question_array.push(obj);
    });
    let new_question_value = [];
    if (questions) {
      new_question_value = [...question_array, ...questions?.question_value];
    } else {
      new_question_value = [...question_array];
    }
    questionStorePublisher({ question_value: new_question_value });
    if (props.nextAction) {
      props.nextAction();
    }
  };

  return (
    <DeviceOrientationWrapper isLandscape={!isPortrait}>
      <Helmet>
        <title>Email Report</title>
      </Helmet>
      {!companyDetails && (
        <Box style={{ overflowY: "auto" }}>
          <LinearProgress />
          <Typography>{t("Loading...")}</Typography>
        </Box>
      )}
      {companyDetails && (
        <Box
          style={{
            overflow: "scroll",
            height: "73vh",
            marginTop: isPortrait ? 30 : 0,
          }}
          className="scrollerEmail"
        >
          <Box
            textAlign="center"
            pt={2}
            mb={2}
            className={classes.headingHeight}
          >
            <Logo
              // type={type}
              imageURL={companyDetails.logo_file_path}
              className={classes.headingHeight}
            ></Logo>
          </Box>
          <Box style={{ display: "block", margin: "5px auto" }}>
            {formElements
              ? formElements.map((field, i) =>
                  field.type === "CHECKBOX" && field.data_type === "STRING" ? (
                    <Grid
                      key={field.question_id}
                      item
                      xs={12}
                      className={classes.headerText}
                    >
                      <Typography variant="body1" className="text">
                        {t(field.question)}
                      </Typography>
                      {field.panel === "report-email-extra"
                        ? field.options && (
                            <Box
                              style={{
                                border: "1px solid",
                                width: "80%",
                                borderRadius: "6px",
                                margin: "1px auto",
                                marginTop: "5px",
                                padding: "0px",
                              }}
                            >
                              <ToggleButtonGroup
                                color="secondary"
                                value={ctrackExtra}
                                exclusive={true}
                                style={{ width: "100%" }}
                                onChange={handleCtrackToggle}
                              >
                                <ToggleButton
                                  color="secondary"
                                  value={true}
                                  style={{
                                    height: "35px",
                                    color: "black",
                                    fontSize: "16px",
                                  }}
                                >
                                  {t("Yes")}
                                </ToggleButton>
                                <ToggleButton
                                  value={false}
                                  style={{
                                    height: "35px",
                                    color: "black",
                                    fontSize: "16px",
                                  }}
                                >
                                  {t("No")}
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Box>
                          )
                        : field.options && (
                            <Box
                              style={{
                                border: "1px solid",
                                width: "80%",
                                borderRadius: "6px",
                                margin: "1px auto",
                                marginTop: "5px",
                                padding: "0px",
                              }}
                            >
                              <ToggleButtonGroup
                                disabled={companyDetails?.otp === "8610"}
                                color="secondary"
                                value={emailRequired}
                                exclusive={true}
                                style={{ width: "100%" }}
                                onChange={handleToggle}
                              >
                                <ToggleButton
                                  disabled={companyDetails?.otp === "8610"}
                                  color="secondary"
                                  value={true}
                                  style={{
                                    height: "35px",
                                    color: "black",
                                    fontSize: "16px",
                                  }}
                                >
                                  {t("Yes")}
                                </ToggleButton>
                                <ToggleButton
                                  disabled={companyDetails?.otp === "8610"}
                                  value={false}
                                  style={{
                                    height: "35px",
                                    color: "black",
                                    fontSize: "16px",
                                  }}
                                >
                                  {t("No")}
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Box>
                          )}
                    </Grid>
                  ) : (
                    <Grid container key={field.question_id}>
                      {field.panel === "report-email-extra" ? (
                        <Grid item xs={12}>
                          {ctrackExtra && (
                            <Box
                              style={{
                                width: "95%",
                                paddingTop: "10px",
                                paddingLeft: "10px",
                              }}
                            >
                              <Element
                                question_id={field.question_id}
                                data_type={field.data_type}
                                question={field.question}
                                place_holder_label={field.place_holder_label}
                                question_value={field.question_value}
                                option={field.option}
                                handleChange={handleChange}
                                error={field.field_error_message}
                              />
                            </Box>
                          )}
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          {emailRequired && (
                            <Box
                              style={{
                                width: "95%",
                                paddingTop: "10px",
                                paddingLeft: "10px",
                              }}
                            >
                              <Element
                                question_id={field.question_id}
                                data_type={field.data_type}
                                question={field.question}
                                place_holder_label={field.place_holder_label}
                                question_value={field.question_value}
                                option={field.option}
                                handleChange={handleChange}
                                error={field.field_error_message}
                              />
                            </Box>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  )
                )
              : null}
            <Grid
              item
              xs={12}
              style={{ paddingTop: !emailRequired ? "70px" : "8px" }}
            >
              <Box className="middleCenter">
                <RoundedButton
                  label={t("Next")}
                  disabled={emailRequired && emailRequiredAndValid}
                  size="medium"
                  width="30vw"
                  onClick={handleSubmit}
                  fontWeight="800"
                ></RoundedButton>
              </Box>
            </Grid>
            {errors.length !== 0 && (
              <ErrorMessage
                errors={errors}
                onClick={() => setErrors([])}
              ></ErrorMessage>
            )}
          </Box>
        </Box>
      )}
    </DeviceOrientationWrapper>
  );
};

Emailreport.defaultProps = {
  nextAction: () => {},
  prevAction: () => {},
};
export default Emailreport;
