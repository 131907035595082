const interior = (type) => {
  return [
    // INSPECTION INTERIOR
    // "INTERIOR_ROOF",
    {
      type: "Inspection",
      sub: type,
      step: "interior-roof",
      overlayImage: "interior/Dashboard.png",
      overlayImageSize: "90%",
      overlayImagePosition: "-2px",
      overlayImagePositionLeft: "2%",
      side: "INTERIOR_ROOF",
      label: "Roof",
      area: "INTERIOR",
      subArea: "INTERIOR_ROOF",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    // "INTERIOR_DASHBOARD",
    {
      type: "Inspection",
      sub: type,
      step: "interior-dashboard",
      overlayImage: "interior/DashboardFull.png",
      overlayImageSize: "90%",
      overlayImagePosition: "0%",
      overlayImagePositionLeft: "2%",
      side: "INTERIOR_DASHBOARD",
      label: "Dashboard",
      area: "INTERIOR",
      subArea: "DASHBOARD",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    // "INTERIOR_REAR_DASHBOARD",
    {
      type: "Inspection",
      sub: type,
      step: "interior-rear-dashboard",
      overlayImage: "interior/Reardashboard.png",
      overlayImageSize: "90%",
      overlayImagePosition: "0%",
      overlayImagePositionLeft: "2%",
      side: "INTERIOR_REAR_DASHBOARD",
      label: "Rear Dashboard",
      area: "INTERIOR",
      subArea: "REAR_DASHBOARD",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    // "INTERIOR_DRIVER_SIDE_FRONT_DOOR",
    {
      type: "Inspection",
      sub: type,
      step: "interior-driver-side-front-door",
      overlayImage: "interior/FrontDoor_DriverSide.png",
      overlayImageSize: "95%",
      overlayImagePosition: "0%",
      overlayImagePositionLeft: "2%",
      side: "INTERIOR_DRIVER_SIDE_FRONT_DOOR",
      label: "Driver Side Front Door",
      area: "INTERIOR",
      subArea: "DRIVER_SIDE_FRONT_DOOR",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    // "INTERIOR_DRIVER_SIDE_FRONT_SEAT",
    {
      type: "Inspection",
      sub: type,
      step: "interior-driver-side-front-seat",
      overlayImage: "interior/FrontSeat_DriverSide.png",
      overlayImageSize: "92%",
      overlayImagePosition: "0%",
      overlayImagePositionLeft: "2%",
      side: "INTERIOR_DRIVER_SIDE_FRONT_SEAT",
      label: "Driver side Front Seat",
      area: "INTERIOR",
      subArea: "DRIVER_SIDE_FRONT_SEAT",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    // "INTERIOR_DRIVER_SIDE_REAR_DOOR",
    {
      type: "Inspection",
      sub: type,
      step: "interior-driver-side-rear-door",
      overlayImage: "interior/RearDoor_DriverSide.png",
      overlayImageSize: "94%",
      overlayImagePosition: "0%",
      overlayImagePositionLeft: "2%",
      side: "INTERIOR_DRIVER_SIDE_REAR_DOOR",
      label: "Driver Side Rear Door",
      area: "INTERIOR",
      subArea: "DRIVER_SIDE_REAR_DOOR",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    // "INTERIOR_DRIVER_SIDE_REAR_SEAT",
    {
      type: "Inspection",
      sub: type,
      step: "interior-driver-side-rear-seat",
      overlayImage: "interior/RearSeat_DriverSide.png",
      overlayImageSize: "90%",
      overlayImagePosition: "0%",
      overlayImagePositionLeft: "2%",
      side: "INTERIOR_DRIVER_SIDE_REAR_SEAT",
      label: "Driver Side Rear Seat",
      area: "INTERIOR",
      subArea: "DRIVER_SIDE_REAR_SEAT",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    // "INTERIOR_GEAR_AREA",
    {
      type: "Inspection",
      sub: type,
      step: "interior-gear-area",
      overlayImage: "interior/GearArea.png",
      overlayImageSize: "92%",
      overlayImagePosition: "0%",
      overlayImagePositionLeft: "2%",
      side: "INTERIOR_GEAR_AREA",
      label: "Gear Area",
      area: "INTERIOR",
      subArea: "GEAR_AREA",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    // "INTERIOR_REAR_MIDDLE_SEAT",
    {
      type: "Inspection",
      sub: type,
      step: "interior-rear-middle-seat",
      overlayImage: "interior/MiddleSeat.png",
      overlayImageSize: "92%",
      overlayImagePosition: "0%",
      overlayImagePositionLeft: "2%",
      side: "INTERIOR_REAR_MIDDLE_SEAT",
      label: "Rear Middle Seat",
      area: "INTERIOR",
      subArea: "REAR_MIDDLE_SEAT",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    // "INTERIOR_PASSENGER_SIDE_FRONT_DOOR",
    {
      type: "Inspection",
      sub: type,
      step: "interior-passenger-side-front-door",
      overlayImage: "interior/FrontDoor_PassengerSide.png",
      overlayImageSize: "95%",
      overlayImagePosition: "0%",
      overlayImagePositionLeft: "2%",
      side: "INTERIOR_PASSENGER_SIDE_FRONT_DOOR",
      label: "Passenger Side Front Door",
      area: "INTERIOR",
      subArea: "PASSENGER_SIDE_FRONT_DOOR",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    // "INTERIOR_PASSENGER_SIDE_FRONT_SEAT",
    {
      type: "Inspection",
      sub: type,
      step: "interior-passenger-side-front-seat",
      overlayImage: "interior/FrontSeat_PassengerSide.png",
      overlayImageSize: "90%",
      overlayImagePosition: "0%",
      overlayImagePositionLeft: "2%",
      side: "INTERIOR_PASSENGER_SIDE_FRONT_SEAT",
      label: "Passenger Side Front Seat",
      area: "INTERIOR",
      subArea: "PASSENGER_SIDE_FRONT_SEAT",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    // "INTERIOR_PASSENGER_SIDE_REAR_DOOR",
    {
      type: "Inspection",
      sub: type,
      step: "interior-passenger-side-rear-door",
      overlayImage: "interior/RearDoor_PassengerSide.png",
      overlayImageSize: "94%",
      overlayImagePosition: "0%",
      overlayImagePositionLeft: "2%",
      side: "INTERIOR_PASSENGER_SIDE_REAR_DOOR",
      label: "Passenger Side Rear Door",
      area: "INTERIOR",
      subArea: "PASSENGER_SIDE_REAR_DOOR",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    // "INTERIOR_PASSENGER_SIDE_REAR_SEAT",
    {
      type: "Inspection",
      sub: type,
      step: "interior-passenger-side-rear-seat",
      overlayImage: "interior/RearSeat_PassengerSide.png",
      overlayImageSize: "90%",
      overlayImagePosition: "0%",
      overlayImagePositionLeft: "2%",
      side: "INTERIOR_PASSENGER_SIDE_REAR_SEAT",
      label: "Passenger Side Rear Seat",
      area: "INTERIOR",
      subArea: "PASSENGER_SIDE_REAR_SEAT",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    // "INTERIOR_STEERING",
    {
      type: "Inspection",
      sub: type,
      step: "interior-steering",
      overlayImage: "interior/Steering.png",
      overlayImageSize: "92%",
      overlayImagePosition: "0%",
      overlayImagePositionLeft: "2%",
      side: "INTERIOR_STEERING",
      label: "Steering",
      area: "INTERIOR",
      subArea: "STEERING",
      skipBBOX: type === "pre",
      skipCount: false,
    },

    //Portrait Joureny
    {
      type: "Inspection",
      sub: type,
      step: "interior-steering-wheel",
      overlayImage: "interior/portrait/steering-wheel.svg",
      overlayImageSize: "50%",
      overlayImagePosition: "30%",
      overlayImagePositionLeft: "5%",
      side: "INTERIOR_STEERING_WHEEL",
      label: "Steering wheel",
      area: "INTERIOR",
      subArea: "STEERING_WHEEL",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    {
      type: "Inspection",
      sub: type,
      step: "interior-glove-box",
      overlayImage: "interior/portrait/glove-box.svg",
      overlayImageSize: "55%",
      overlayImagePosition: "30%",
      overlayImagePositionLeft: "5%",
      side: "INTERIOR_GLOVE_BOX",
      label: "Glove box",
      area: "INTERIOR",
      subArea: "GLOVE_BOX",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    {
      type: "Inspection",
      sub: type,
      step: "interior-gear-stick",
      overlayImage: "interior/portrait/gear-stick.svg",
      overlayImageSize: "80%",
      overlayImagePosition: "15%",
      overlayImagePositionLeft: "5%",
      side: "INTERIOR_GEAR_STICK",
      label: "Gear stick",
      area: "INTERIOR",
      subArea: "GEAR_STICK",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    {
      type: "Inspection",
      sub: type,
      step: "interior-driver-seat",
      overlayImage: "interior/portrait/seat.svg",
      overlayImageSize: "80%",
      overlayImagePosition: "15%",
      overlayImagePositionLeft: "4%",
      side: "INTERIOR_DRIVER_SEAT",
      label: "Driver seat",
      area: "INTERIOR",
      subArea: "DRIVER_SEAT",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    {
      type: "Inspection",
      sub: type,
      step: "interior-driver-side-front-door-portrait",
      overlayImage: "interior/FrontDoor_DriverSide.png",
      overlayImageSize: "60%",
      overlayImagePosition: "20%",
      overlayImagePositionLeft: "-5%",
      side: "INTERIOR_DRIVER_SIDE_FRONT_DOOR_PORTRAIT",
      label: "Driver side front door",
      area: "INTERIOR",
      subArea: "DRIVER_SIDE_FRONT_DOOR_PORTRAIT",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    {
      type: "Inspection",
      sub: type,
      step: "interior-driver-side-rear-door-portrait",
      overlayImage: "interior/portrait/interior_door.svg",
      overlayImageSize: "55%",
      overlayImagePosition: "20%",
      overlayImagePositionLeft: "4%",
      side: "INTERIOR_DRIVER_SIDE_REAR_DOOR_PORTRAIT",
      label: "Driver side rear door",
      area: "INTERIOR",
      subArea: "DRIVER_SIDE_REAR_DOOR_PORTRAIT",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    {
      type: "Inspection",
      sub: type,
      step: "interior-rear-right-seat",
      overlayImage: "interior/RearSeat_DriverSide.png",
      overlayImageSize: "60%",
      overlayImagePosition: "20%",
      overlayImagePositionLeft: "45%",
      side: "INTERIOR_REAR_RIGHT_SEAT",
      label: "Rear right seat",
      area: "INTERIOR",
      subArea: "REAR_RIGHT_SEAT",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    {
      type: "Inspection",
      sub: type,
      step: "interior-back-trunk",
      overlayImage: "interior/portrait/trunk.svg",
      overlayImageSize: "40%",
      overlayImagePosition: "30%",
      overlayImagePositionLeft: "4%",
      side: "INTERIOR_BACK_TRUNK",
      label: "Back trunk",
      area: "INTERIOR",
      subArea: "INTERIOR_BACK_TRUNK",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    {
      type: "Inspection",
      sub: type,
      step: "interior-passenger-side-rear-door-portrait",
      overlayImage: "interior/RearDoor_PassengerSide.png",
      overlayImageSize: "50%",
      overlayImagePosition: "25%",
      overlayImagePositionLeft: "5%",
      side: "INTERIOR_PASSENGER_SIDE_REAR_DOOR_PORTRAIT",
      label: "Passenger side rear door",
      area: "INTERIOR",
      subArea: "PASSENGER_SIDE_REAR_DOOR_PORTRAIT",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    {
      type: "Inspection",
      sub: type,
      step: "interior-rear-left-seat",
      overlayImage: "interior/RearSeat_PassengerSide.png",
      overlayImageSize: "60%",
      overlayImagePosition: "25%",
      overlayImagePositionLeft: "-40%",
      side: "INTERIOR_REAR_LEFT_SEAT",
      label: "Rear left seat",
      area: "INTERIOR",
      subArea: "REAR_LEFT_SEAT",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    {
      type: "Inspection",
      sub: type,
      step: "interior-passenger-side-front-door-portrait",
      overlayImage: "interior/FrontDoor_PassengerSide.png",
      overlayImageSize: "60%",
      overlayImagePosition: "25%",
      overlayImagePositionLeft: "4%",
      side: "INTERIOR_PASSENGER_SIDE_FRONT_DOOR_PORTRAIT",
      label: "Passenger side front door",
      area: "INTERIOR",
      subArea: "PASSENGER_SIDE_FRONT_DOOR_PORTRAIT",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    {
      type: "Inspection",
      sub: type,
      step: "interior-front-passenger-seat",
      overlayImage: "interior/FrontSeat_PassengerSide.png",
      overlayImageSize: "60%",
      overlayImagePosition: "25%",
      overlayImagePositionLeft: "0%",
      side: "INTERIOR_FRONT_PASSENGER_SEAT",
      label: "Front passenger seat",
      area: "INTERIOR",
      subArea: "FRONT_PASSENGERT_SEAT",
      skipBBOX: type === "pre",
      skipCount: false,
    },

    //Portrait mode claims
    // "INTERIOR_DASHBOARD",
    {
      type: "Inspection",
      sub: type,
      step: "interior-dashboard-left",
      overlayImage: "interior/DashboardFull.png",
      overlayImageSize: "80%",
      overlayImagePosition: "15%",
      overlayImagePositionLeft: "50%",
      side: "INTERIOR_DASHBOARD_LEFT",
      label: "Dashboard Left",
      area: "INTERIOR",
      subArea: "DASHBOARD_LEFT",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    {
      type: "Inspection",
      sub: type,
      step: "interior-dashboard-right",
      overlayImage: "interior/DashboardFull.png",
      overlayImageSize: "80%",
      overlayImagePosition: "15%",
      overlayImagePositionLeft: "-45%",
      side: "INTERIOR_DASHBOARD_RIGHT",
      label: "Dashboard Right",
      area: "INTERIOR",
      subArea: "DASHBOARD_RIGHT",
      skipBBOX: type === "pre",
      skipCount: false,
    },

    // "INTERIOR_REAR_DASHBOARD",
    {
      type: "Inspection",
      sub: type,
      step: "interior-rear-dashboard-left",
      overlayImage: "interior/Reardashboard.png",
      overlayImageSize: "50%",
      overlayImagePosition: "30%",
      overlayImagePositionLeft: "40%",
      side: "INTERIOR_REAR_DASHBOARD_LEFT",
      label: "Rear Dashboard Left",
      area: "INTERIOR",
      subArea: "REAR_DASHBOARD_LEFT",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    {
      type: "Inspection",
      sub: type,
      step: "interior-rear-dashboard-right",
      overlayImage: "interior/Reardashboard.png",
      overlayImageSize: "50%",
      overlayImagePosition: "30%",
      overlayImagePositionLeft: "-20%",
      side: "INTERIOR_REAR_DASHBOARD_RIGHT",
      label: "Rear Dashboard Right",
      area: "INTERIOR",
      subArea: "REAR_DASHBOARD_RIGHT",
      skipBBOX: type === "pre",
      skipCount: false,
    },

    // "INTERIOR_REAR_MIDDLE_SEAT",
    {
      type: "Inspection",
      sub: type,
      step: "interior-rear-middle-seat-portrait",
      overlayImage: "interior/MiddleSeat.png",
      overlayImageSize: "92%",
      overlayImagePosition: "0%",
      overlayImagePositionLeft: "-40%",
      side: "INTERIOR_REAR_MIDDLE_SEAT_PORTRAIT",
      label: "Rear Middle Seat",
      area: "INTERIOR",
      subArea: "REAR_MIDDLE_SEAT",
      skipBBOX: type === "pre",
      skipCount: false,
    },
    //3
    {
      type: "Inspection",
      sub: type,
      step: "car-key",
      overlayImage: "car_key.png",
      overlayImageSize: "60%",
      overlayImagePosition: "25%",
      overlayImagePositionLeft: "-8%",
      side: "CAR_KEY",
      label: "Car Key",
      area: "CAR_KEY",
      subArea: "CAR_KEY",
      skipBBOX: true,
      skipCount: false,
      upNext: "/images/upnext/Key.svg",
    },
  ];
};

export default interior;
