import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    button:{
        display:"block",
        marginTop:theme.spacing(1),
        width: theme.spacing(15),
        borderRadius:theme.spacing(0.5),
        height: theme.spacing(7),
        textTransform: "none",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        fontSize: theme.spacing(2), 
    }
  }));