import axios from "axios";

let baseURL =
  process.env.REACT_APP_CORE_API || "https://core.devinternal.carscan.ai";

export const getOverlaysList$ = (id) => {
  return axios.get(`${baseURL}/coreservice/public/${id}/get-overlays_keys`);
};

let baseURL2 =
  process.env.REACT_APP_CARDATA_API || "https://cardata.qaone.carscan.ai";

  export const countryCode$ = async () => {
    try {
      const response = await axios.get(`${baseURL2}/cardata/companies/get-list-of-country-codes`);
      return response.data; // Return the response data
    } catch (error) {
      console.error(error);
    }
  };