let baseURL =
  process.env.REACT_APP_CARDATA_API ||
  "https://cardata.devInternal.carscan.ai​​";

export const RegeneratePresignedUrl$ = (
  otp,
  assessmentId,
  deviceId,
  fileExtension,
  md5,
  imageRequestId
) =>
  fetch(
    `${baseURL}/cardata/external/companies/${otp}/assessments/${assessmentId}/files/${imageRequestId}/regenerate`,
    {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        "device-id": deviceId,
      },
      body: JSON.stringify({
        fileExtension: fileExtension,
        md5s: md5,
      }),
    }
  );
